import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationEditorComponent } from './translation-editor.component';

@Injectable()
export class TranslationEditorInjector {

    /**
     * Component reference to current editor component
     */
    private activeEditorRef: ComponentRef<TranslationEditorComponent> | null;

    /**
     * Emits when activeEditorRef is being destroyed
     */
    private readonly activeRefDestroyed$: Subject<null> = new Subject();

    /**
     * Instance of our renderer2
     */
    private renderer: Renderer2;

    constructor(
        private appRef: ApplicationRef,
        private injector: Injector,
        private rendererFactory: RendererFactory2,
        private factoryResolver: ComponentFactoryResolver,
        @Optional() @Inject(DOCUMENT) private document?: Document,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    /**
     * Injects an instance of the editor into the main
     * application element
     */
    injectEditor(minimized = false) {
        if (this.activeEditorRef) {
            return;
        }
        // Create a new instance of our component
        this.activeEditorRef = this.factoryResolver
            .resolveComponentFactory(TranslationEditorComponent)
            .create(this.injector);

        // Add editor's view to application view tree
        this.appRef.attachView(this.activeEditorRef.hostView);

        // Grab native element from component and add it to our body
        this.renderer.appendChild(
            this.document.body,
            this.activeEditorRef.location.nativeElement
        );

        // Add class
        this.document.body.classList.add('novo-translation--opened');

        // Listen and handle close changes
        this.activeEditorRef.instance.closeEvent.pipe(
            takeUntil(this.activeRefDestroyed$),
        ).subscribe(_ => {
            this.destroyEditor();
        });

        // Minimze if requested
        this.activeEditorRef.instance.minimized$.next(minimized);

        // Force a change detection run on injection
        this.activeEditorRef.changeDetectorRef.detectChanges();
    }

    /**
     * Removes the current editor instance
     */
    destroyEditor() {
        if (this.activeEditorRef) {
            this.activeEditorRef.destroy();
            this.activeRefDestroyed$.next(null);
            this.activeEditorRef = null;

            this.document.body.classList.remove('novo-translation--opened');
        }
    }

}
