import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConsoleErrorLoggerService } from './console-error-logger.service';
import { ErrorLoggerService, ERROR_LOGGER_TOKEN } from './error-logger.service';

@NgModule({
})
export class ErrorLoggingModule {

    static forRoot(config: { logger?: new (...args: any[]) => ErrorLoggerService } = {}): ModuleWithProviders<ErrorLoggingModule> {
        return {
            ngModule: ErrorLoggingModule,
            providers: [
                {
                    provide: ErrorLoggerService,
                    useClass: config.logger || ConsoleErrorLoggerService
                },
                { provide: ERROR_LOGGER_TOKEN, useClass: config.logger || ConsoleErrorLoggerService }
            ]
        };
    }
}
