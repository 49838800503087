<div id="footer">
    <div id="footer--wrapper">
        <div id="footer--content">
            <div style="margin-top: 56px;">
                <!--div class="selector" *ngIf="localeService.country$ | async as currentCountry">
                    <fa-icon class="icon" [icon]="['fas', 'location-arrow']"></fa-icon>
                    <select aria-label="Location" (change)="setCountry($event)">
                        <option *ngFor="let country of localeService.countries" [value]="country.iso2"
                            [selected]="currentCountry.iso2 == country.iso2">
                            {{ country.name }}
                        </option>
                    </select>
                </div-->

                <!--ng-container *ngIf="showLanguageSelector$ | async">
                    <div class="selector" *ngIf="localeService.language$ | async as currentLanguage">
                        <fa-icon class="icon" [icon]="['fas', 'comment']"></fa-icon>
                        <select aria-label="Language" (change)="setLanguage($event)">
                            <option *ngFor="let language of (languages$ | async)" [value]="language.code"
                                [selected]="currentLanguage.code == language.code">
                                {{ language.name }}
                            </option>
                        </select>
                    </div>
                </ng-container-->


            </div>

            <div style="margin: 40px 40px 22px 40px;">

                <div style="text-align: right; margin-right: 18px; font-size: 14px;"
                    novoTranslate="COMPONENTS.FOOTER.NOVO_PLAYER">
                </div>

                <div class="player-links">

                    <ng-template #googlePlayLink>
                        <!-- Google Play link -->
                        <a target="_blank" rel="noopener" aria-label="Download the Novo Play app on Google Play"
                            href='https://play.google.com/store/apps/details?id=com.novolanguage.novoplayer&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img alt='Get it on Google Play' style="width: 156px; margin-top: 4px;"
                                src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                        </a>
                    </ng-template>

                    <ng-template #appStoreLink>
                        <!-- App Store link -->
                        <a target="_blank" rel="noopener"
                            aria-label="Download the Novo Play app in the Apple iTunes Store"
                            href="https://itunes.apple.com/us/app/novo-play/id1260504406?mt=8"
                            style="margin: 16px 10px;  display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/assets/shared/badges/nl-nl/appstore-lrg.svg) no-repeat;width:135px; min-width: 135px; height:40px;background-size:contain;">
                        </a>
                    </ng-template>

                    <ng-template #webLink>
                        <!-- Web version link -->
                        <a href="https://platform.novo-learning.com/player"
                            aria-label="Start the Novo Play app directly in your browser" rel="noopener" color="primary"
                            target="_blank" id="web-player">
                            <i class="material-icons" style="margin-right: 4px;">launch</i>
                            <span
                                style="letter-spacing: 1px; font-variant: all-small-caps; font-weight: bold;">browser</span>
                        </a>
                    </ng-template>

                    <ng-container [ngSwitch]="os">
                        <ng-container *ngSwitchCase="'ios'">
                            <ng-container *ngTemplateOutlet="appStoreLink"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'android'">
                            <ng-container *ngTemplateOutlet="googlePlayLink"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <ng-container *ngTemplateOutlet="appStoreLink"></ng-container>
                            <ng-container *ngTemplateOutlet="googlePlayLink"></ng-container>
                            <ng-container *ngTemplateOutlet="webLink"></ng-container>
                        </ng-container>
                    </ng-container>

                </div>
            </div>
            <div id="footer--links">
                <a class="footer--link" [routerLink]="['/support']" novoTranslate="PAGE_LINKS.SUPPORT"></a>
                <a class="footer--link" [routerLink]="['/contact']" novoTranslate="PAGE_LINKS.CONTACT"></a>
                <a class="footer--link" [routerLink]="['/terms']" novoTranslate="PAGE_LINKS.TERMS"></a>
                <a class="footer--link" [routerLink]="['/privacy']" novoTranslate="PAGE_LINKS.PRIVACY"></a>
                <div novoTranslate="COMPONENTS.FOOTER.RIGHTS_RESERVED" [novoTranslateParams]="{year: currentYear}">
                </div>
            </div>
        </div>
    </div>