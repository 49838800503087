import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ParseAPIModule } from '@novo/platform-common/services/api/parse-api.module';
import { ParseAPIService } from '@novo/platform-common/services/api/parse-api.service';
import { ParseConfig } from '@novo/platform-common/services/api/parse-request';
import { AuthModule } from '@novo/platform-common/services/auth/auth.module';
import { LocaleModule, LocaleService } from '@novo/platform-common/services/locale';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { AccountsService } from './accounts/accounts.service';
import { ClientProvisionalDataService, ProvisionalDataService } from './provisional-data';

const PARSE_CONFIG: ParseConfig = {
    appId: environment.novoApi.appId,
    serverUrl: environment.novoApi.endpoint,
    pollDelay: 2000
};

@NgModule({
    providers: [
        ParseAPIService,
        LocaleService,
        AccountsService,
        CookieService,
        {
            provide: ProvisionalDataService,
            useClass: ClientProvisionalDataService
        }
    ],
    imports: [
        HttpClientModule,
        ParseAPIModule.forRoot(PARSE_CONFIG),
        AuthModule.forRoot(),
        LocaleModule.forRoot({
            defaultCountry: 'NL',
            defaultLanguage: 'en',
        }),
    ],
    exports: [
        TranslateModule
    ]
})
export class ServicesModule {

}
