import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { SvgUtil } from '../utils/svg-util';

@Component({
    selector: 'app-hero',
    template: `
        <svg class="hero-svg" *ngIf="bgImageUrl" preserveAspectRatio="xMidYMid slice" #svg viewBox="0 0 1440 636">
            <clipPath id="myClip">
                <path d="M1462.77 -187.253C1048.43 -126.844 -123.55 -369.021 -68 12C-12.4499 393.021 -386.904 676.171 27.4413 615.762C222.825 587.277 486.636 538.706 739.776 543.842C1023.46 549.591 1292.63 575.32 1475.99 476.899C1822.96 290.657 1877.12 -247.664 1462.77 -187.256L1462.77 -187.253Z"/>
            </clipPath>
            <image clip-path="url(#myClip)" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" [attr.xlink:href]="bgImageUrl" />
            <rect clip-path="url(#myClip)" width="100%" height="100%" fill="url(#gradient)" />
            <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop stop-opacity="0"/>
                    <stop offset="1" stop-opacity="0.63"/>
                </linearGradient>
            </defs>

        </svg>
    `,
    styles: [`
        :host {
            position: relative;
            display: block;
        }

        .hero-svg {
            min-height: 420px;
            width: 100%;
        }
  `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent implements AfterViewInit {
    @Input() bgImageUrl: string;
    @ViewChildren('svg') svgs: QueryList<ElementRef>;

    constructor(
        private svgUtil: SvgUtil,
    ) { }

    ngAfterViewInit() {
        this.svgs.forEach(svg => this.svgUtil.fix(svg.nativeElement));
    }
}
