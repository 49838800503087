import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SsrUtilsModule, WINDOW } from '../../util/ssr/ssr-utils.module';
import { CANCEL_REQUEST_OBSERVABLE } from '../api/parse-api.fns';
import { ParseConfig } from '../api/parse-request';
import { AuthenticationMemoryStorage } from './auth-storage/auth.memory-storage.service';
import { AuthenticationStorage } from './auth-storage/auth.storage.service';
import { cancelRequestFactory, sessionTokenServiceFactory, updateUserInformation } from './auth.fns';
import { AuthService } from './auth.service';
import { ParseAuthenticationInterceptor } from './parse-auth.interceptor';
import { SessionTokenService } from './session-token/session-token.service';

/**
 * The authentication module uses by default a memory storage for storing authentication state.
 * A redux based store can be used by calling forRoot with AuthenticationReduxStorage as the storage option.
 */
@NgModule({
    imports: [
        RouterModule,
        SsrUtilsModule
    ],
    providers: [
        {
            provide: SessionTokenService,
            useFactory: sessionTokenServiceFactory,
            deps: [CookieService, DOCUMENT, WINDOW]
        },
        CookieService,
        AuthService,
        {
            provide: APP_INITIALIZER,
            useFactory: updateUserInformation,
            deps: [AuthService],
            multi: true
        },
        { provide: CANCEL_REQUEST_OBSERVABLE, useFactory: cancelRequestFactory, deps: [AuthenticationStorage] },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ParseAuthenticationInterceptor,
            multi: true,
            deps: [ParseConfig, AuthenticationStorage]
        }
    ],
})
export class AuthModule {

    static forRoot(config: { storage?: new (...args: any[]) => AuthenticationStorage } = {}): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: AuthenticationStorage,
                    useClass: config.storage || AuthenticationMemoryStorage
                }
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('AuthModule is already loaded. Make sure to import the module only once in the root of your app.');
        }
    }
}
