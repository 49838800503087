<div class="container">
    <div class="toc">
        <ul *ngFor="let section of sections">
            <a [routerLink]="[]" [fragment]="section.label" class="nav-section-link">
                {{section.title}}
            </a>
            <li *ngFor="let sub of section.subsections" class="nav-subsection-link">
                <a [routerLink]="[]" [fragment]="sub.label">
                    {{sub.title}}
                </a>
            </li>
        </ul>
    </div>
    <div class="content">
        <markdown [data]="mdData" [sanitizeHtml]="false"></markdown>
    </div>
</div>