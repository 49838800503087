import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Environment } from '@novo/website/services';
import { AccountsService } from '@novo/website/services/accounts/accounts.service';
import { Observable, of } from 'rxjs';
import { SvgUtil } from '../../shared/utils/svg-util';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss', '../../shared/styles/hero.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingPageComponent implements AfterViewInit {

    @ViewChildren('svg') svgs: QueryList<ElementRef>;

    // Determine which Wistia video should be loaded
    readonly localizedMedia$: Observable<{ heroImage: string }> = of({
        heroImage: 'assets/hermie-3.png'
    });

    constructor(
        private svgUtil: SvgUtil,
        public accounts: AccountsService,
        public environment: Environment
    ) { }

    ngAfterViewInit() {
        // Wrapping this in a timeout apparently fixes a bug where
        // sometimes the clip path was not applied in Safari
        setTimeout(() => {
            this.svgs.forEach(svg => this.svgUtil.fix(svg.nativeElement));
        });
    }

    downloadReport() {
        window.open('assets/pdf/research-report.pdf');
    }

}
