import { MemoizedSelector, Store } from '@ngrx/store';
import { Dictionary } from '@novo/platform-common/models';
import { filterNull } from '@novo/platform-common/util/operators';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoggingDataMiddleware } from './logging-data-middleware';

/**
 * Normalizes provideData to support both promises and 'sync' return statements.
 *
 * @param middleware
 */
export const normalizeMiddlewareData = async (middleware: LoggingDataMiddleware[]): Promise<Array<Dictionary<any>>> => {
    return Promise.all(middleware.map(m => {
        const res = m.provideData();
        return (res instanceof Promise) ? res : Promise.resolve(res);
    }));
};


/**
 * Shortcut to select from given store, take first value and filter any null
 *
 * @param selectorFn
 */
export const storeSelect = <
    T extends Store<any>,
    S extends T extends Store<infer U> ? U : any
>(storeFn: T) => {
    return <
        F extends MemoizedSelector<S, any> | ((state: S) => any),
        K extends F extends MemoizedSelector<any, infer U> ? U : F extends ((state: S) => infer U) ? U : never
    >(selectorFn: F): Observable<NonNullable<K>> => {
        return storeFn.select(selectorFn).pipe(
            take(1),
            filterNull(),
        )
    };
};