import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimatedLogoComponent } from './animated-logo.component';

@NgModule({
    declarations: [
        AnimatedLogoComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AnimatedLogoComponent
    ]
})
export class AnimatedLogoModule {
}
