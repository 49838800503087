import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { TranslateDirective, TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { NovoEditableButtonComponent } from './editable-button/editable-button.component';
import { NovoTranslationService } from './novo-translation.service';

@Directive({
    selector: '[novoTranslate]',
})
export class NovoTranslateDirective extends TranslateDirective implements OnInit, OnDestroy {

    private readonly destroyed$: Subject<null> = new Subject<null>();

    private editableBtnRef: ComponentRef<NovoEditableButtonComponent> | null = null;

    get nativeElm(): HTMLElement {
        return this.elm.nativeElement;
    }

    constructor(
        private ngxTranslate: TranslateService,
        private elm: ElementRef,
        private cRef: ChangeDetectorRef,
        private factoryResolver: ComponentFactoryResolver,
        private viewRef: ViewContainerRef,
        private renderer: Renderer2,
        private novoTranslation: NovoTranslationService
    ) {
        super(ngxTranslate, elm, cRef);
    }

    // Alias novoTranslate to TranslateDirective#translate
    @Input()
    set novoTranslate(value: string) {
        this.translate = value;
    }

    // Alias novoTranslateParams to TranslateDirective#translateParams
    @Input()
    set novoTranslateParams(value: any) {
        this.translateParams = value;
    }

    ngOnInit() {
        combineLatest(this.novoTranslation.showIndicators$, this.novoTranslation.enabled$).pipe(
            map(([showIndicators, enabled]) => showIndicators && enabled),
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
        ).subscribe((enabled) => {
            if (enabled) {
                this.addEditableIndicator();
            } else {
                this.removeEditableIndicator();
            }
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    /**
     * Create a new instance of the EditableButtonComponent, insert
     * is as an child to the current element and set the current
     * element's position to "relative"
     */
    addEditableIndicator() {
        if (this.editableBtnRef !== null) {
            return;
        }

        this.editableBtnRef = this.factoryResolver
            .resolveComponentFactory(NovoEditableButtonComponent)
            .create(this.viewRef.injector);

        // Set translation key
        this.editableBtnRef.instance.translationKey = this.key;

        this.renderer.appendChild(this.nativeElm, this.editableBtnRef.location.nativeElement);
        this.nativeElm.style.position = 'relative';
    }

    removeEditableIndicator() {
        if (this.editableBtnRef === null) {
            return;
        }

        // Remove actual HTML element
        const element: HTMLElement = this.editableBtnRef.location.nativeElement;
        if (element.parentElement) {
            element.parentElement.removeChild(element);
        }

        // Destroy and clear reference
        this.editableBtnRef.destroy();
        this.editableBtnRef = null;
    }

}
