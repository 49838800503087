import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginButtonModule } from '@novo/platform-common/components/login-button';
import { NovoTranslationModule } from '@novo/platform-translations';
import { NgxMdModule } from 'ngx-md';
import { MaterialModule } from '../material.module';
import { ComponentsModule } from '../shared/components/components.module';
import { SharedModule } from '../shared/shared.module';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PartnerCardComponent } from './landing-page/partner-card.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordResetComponent } from './password-reset-page/password-reset-page.component';
import { SignupPageComponent } from './signup-page/signup-page.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxMdModule,
        ComponentsModule,
        SharedModule,
        NovoTranslationModule,
        LoginButtonModule
    ],
    declarations: [
        ContactPageComponent,
        LandingPageComponent,
        PartnerCardComponent,
        SignupPageComponent,
        PageNotFoundComponent,
        PasswordResetComponent
    ],
    providers: [],
})
export class PagesModule { }
