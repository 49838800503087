import { Inject, Injectable, Optional } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { languageMap, LanguageMap } from '@novo/platform-common/data';
import { Language, LanguageCode } from '@novo/platform-common/models';
import { ParseRequest } from '@novo/platform-common/services/api/parse-request';
import { WINDOW } from '@novo/platform-common/util/ssr/ssr-utils.module';
import * as Bowser from 'bowser';
import { Request } from 'express';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { countries } from './countries.constant';
import { Country, CountryMap, LocalConfig } from './locale.interfaces';

const LOCALE_COOKIE_KEY = 'PrefLocale';
const LANGUAGE_COOKIE_KEY = 'PrefLanguage';

@Injectable()
export class LocaleService {

    readonly languages: Language[] = [
        languageMap['en'],
        languageMap['id'],
        languageMap['nl']
    ];

    readonly countries = countries.sort((a: { name: string }, b: { name: string }) => {
        return a.name < b.name ? -1 : 1;
    });

    readonly country$ = new BehaviorSubject<Country | null>(null);

    get language$(): Observable<Language> {
        return this.translate.onLangChange.pipe(
            distinctUntilKeyChanged('lang'),
            map(e => e.lang),
            startWith(this.translate.currentLang),
            map(lang => this.languages.find(l => l.code === lang)),
            filter((lang): lang is Language => lang !== undefined),
            shareReplay(1)
        );
    }

    /**
     * Initialize a country map using the country ISO2 codes as key
     */
    readonly countryMap: CountryMap = this.countries.reduce((acc: CountryMap, cur: Country) => ({
        ...acc,
        [cur.iso2]: cur
    }), {} as CountryMap);

    /**
     * Initialize a language map using a language's code as the key
     */
    readonly languageMap: Partial<LanguageMap> = this.languages.reduce((acc: Partial<LanguageMap>, cur: Language) => ({
        ...acc,
        [cur.code]: cur
    }), {});

    private config: LocalConfig;

    constructor(
        private api: ParseRequest,
        private cookie: CookieService,
        private translate: TranslateService,
        @Optional() @Inject(Meta) private metaService: Meta | undefined,
        @Optional() @Inject(WINDOW) private window: Window | undefined,    // available when rendering on client
        @Optional() @Inject(REQUEST) private request: Request | undefined // available when rendering on server
    ) { }

    /**
     * Observable which will initialize the local country and
     * language subjects based on the user's IP
     */
    initialize(config: LocalConfig): Observable<string> {
        this.config = config;
        const lastLocale = this._grabLocaleFromCookie();
        const lastLanguage = this._grabLanguageFromCookie();

        // Setup our default language
        this.translate.setDefaultLang(config.defaultLanguage);

        // Initialize all available languages in ngx-translate
        const availLangCodes: LanguageCode[] = this.languages.map(l => l.code);
        this.translate.addLangs(availLangCodes);

        // Grab IP or last known country and setup country and language
        return (lastLocale ? of(lastLocale) : this._guessCountryFromIP()).pipe(
            catchError(_ => {
                const code = this._guessCountryFromNavigator() || config.defaultCountry;
                this.setCountry(code);
                return of(code);
            }),
            switchMap(code => {
                // Fall back to navigator country or defaultcountry
                code = (code == null) ? this._guessCountryFromNavigator() : code;
                code = (code == null) ? config.defaultCountry : code;

                this.setCountry(code);

                // Set language to user's cookie, browser language if we support it or use the default language
                const userLanguage: LanguageCode = (lastLanguage || this.translate.getBrowserLang()) as LanguageCode;
                const appLanguage: LanguageCode = availLangCodes.includes(userLanguage) ? userLanguage : config.defaultLanguage;
                return this.setLanguage(appLanguage);
            })
        );
    }

    /**
     * Returns previously saved country from local cookie
     */
    _grabLocaleFromCookie(): string | undefined {
        return this._getCookie(LOCALE_COOKIE_KEY);
    }

    /**
     * Returns previously saved language from cookie
     */
    _grabLanguageFromCookie(): string | undefined {
        return this._getCookie(LANGUAGE_COOKIE_KEY);
    }

    /**
     * Retrieve a cookie and make sure 'null' and 'undefined'
     * are normalized to null/undefined.
     *
     * @param key
     */
    _getCookie(key: string): string | undefined {
        const value = this.cookie.get(key);
        return value == null || ['null', 'undefined'].includes(value) ? undefined : value;
    }

    /**
     * Saves the given locale
     */
    setLocaleCookie(locale: string) {
        const userAgent = this.window?.navigator.userAgent || this.request?.get('user-agent');
        if (userAgent) {
            const sameSite = Bowser.getParser(userAgent).isBrowser('safari', true) ? undefined : 'Strict';
            this.cookie.set(LOCALE_COOKIE_KEY, locale, 7, '/', '.novo-learning.com', true, sameSite);
        }
    }

    /**
     * Saves the given language as cookie
     * @param language
     */
    setLanguageCookie(language: string) {
        const userAgent = this.window?.navigator.userAgent || this.request?.get('user-agent');
        if (userAgent) {
            const sameSite = Bowser.getParser(userAgent).isBrowser('safari', true) ? undefined : 'Strict';
            this.cookie.set(LANGUAGE_COOKIE_KEY, language, 7, '/', '.novo-learning.com', true, sameSite);
        }
    }

    /**
     * Guess country from user's IP.
     */
    _guessCountryFromIP(): Observable<string | null> {
        const ip: string | null = (this.request) ? this.request.ip : null;
        return this.api.runCloudFunction$<{}, string>('getCountry', { ip });
    }

    /**
     * Guesses the country from the navigator's language property.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
     */
    _guessCountryFromNavigator(): string | null {
        if (!this.window) {
            return null;
        }
        const lang = this.window.navigator.language;
        const splitted = lang.split('-', 2);

        // The 'language' format doesn't always contain a 'second' part
        // return early if the country/region part doesn't exist.
        if (splitted.length < 2) {
            return null;
        }

        // Check if the region/country part is an actual country code.
        const code = splitted[1].toUpperCase();

        return (Object.keys(this.countryMap).indexOf(code) > -1) ? code : null;
    }

    setCountry(code: string) {
        const country: Country = this.countryMap[code] || this.countryMap[this.config.defaultCountry];
        this.country$.next(country);
        this.setLocaleCookie(code);
    }

    setLanguage(code: string): Observable<any> {
        const language: Language = this.languageMap[code] || this.languageMap[this.config.defaultLanguage];
        this.setLanguageCookie(language.code);
        if (this.metaService) {
            this.metaService.updateTag({ 'http-equiv': 'language', content: code.toUpperCase() }, 'http-equiv=\'language\'');
        }
        return this.translate.use(language.code);
    }

    getDefaultLanguage(): string {
        return this.translate.defaultLang;
    }
}
