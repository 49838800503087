import { Injectable } from '@angular/core';
import { Dictionary } from '@novo/platform-common/models';

/**
 * Create an abstract class which will also be used as InjectionToken
 */
@Injectable()
export abstract class LoggingDataMiddleware {
    abstract provideData(): Promise<Dictionary<any>> | Dictionary<any>;
}
