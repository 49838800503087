import { ModuleWithProviders, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { CANCEL_REQUEST_OBSERVABLE } from './parse-api.fns';
import { ParseAPIService } from './parse-api.service';
import { ParseConfig, ParseRequest } from './parse-request';

/**
 * To cancel requests to Parse override CANCEL_REQUEST_OBSERVABLE with an Observable and
 * let this Observable emits a value when a cancel is required (for instance when the user logs out).
 */
@NgModule({
    providers: [
        ParseAPIService,
        ParseRequest,
        { provide: CANCEL_REQUEST_OBSERVABLE, useValue: new Subject<boolean>() }
    ],
})
export class ParseAPIModule {
    static forRoot(parseConfig: ParseConfig): ModuleWithProviders<ParseAPIModule> {
        return {
            ngModule: ParseAPIModule,
            providers: [{ provide: ParseConfig, useValue: parseConfig }]
        };
    }
}
