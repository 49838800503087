import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { sortBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Translation } from '../novo-translation.interface';
import { NovoTranslationService } from '../novo-translation.service';

@Component({
    selector: 'novo-translation-editor',
    templateUrl: './translation-editor.component.html',
    styleUrls: ['./translation-editor.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationEditorComponent implements OnInit, OnDestroy {

    @Output()
    readonly closeEvent: EventEmitter<any> = new EventEmitter();

    /**
     * Minimized appearance state holder
     */
    readonly minimized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Selected filter for translation list display
     */
    readonly selectedFilter$ = new BehaviorSubject<string | null>(null);

    readonly searchTerm: FormControl = new FormControl();

    /**
     * Emits when component is destroyed
     */
    private readonly destroyed$: Subject<null> = new Subject();

    /**
     * Available languages
     */
    readonly languages$ = this.store.availLanguages$;

    /**
     * Selected language
     */
    readonly foreignLanguage$ = this.store.activeLanguage$;

    /**
     * Selected default language, which we'll consider 'local'
     */
    readonly localLanguage$ = this.store.defaultLanguage$;

    /**
     * Combination of missing and active translations, representing all
     * translations.
     */
    readonly translations$: Observable<Translation[]> = combineLatest(
        this.store.activeTranslations$,
        this.store.missingTranslations$,
    ).pipe(
        map(([active, missing]) => [...active, ...missing.filter(m => !active.find(a => a.key === m.key))]),
        map(translations => sortBy(translations, t => t.key)),
        takeUntil(this.destroyed$)
    );

    /**
     * List of filtered translations based on current selected filter
     */
    readonly filteredTranslations$: Observable<Translation[]> = combineLatest(
        this.selectedFilter$,
        this.translations$,
        this.searchTerm.valueChanges.pipe(startWith(null))
    ).pipe(
        map(([selected, translations, searchTerm]) => {
            let filtered = translations;
            switch (selected) {
                case 'missing':
                    filtered = translations.filter(t => t.value === null);
                    break;
                case 'translated':
                    filtered = translations.filter(t => t.value !== null);
                    break;
            }
            if (searchTerm == null || searchTerm === '') {
                return filtered;
            } else {
                return filtered.filter(t =>
                    t.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (t.value !== null && t.value.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
        }),
    );

    /**
     * Total number of translations
     */
    readonly translationsCount$ = this.translations$.pipe(
        map(a => a.length),
    );

    /**
     * Total number of missing translations
     */
    readonly missingTranslationsCount$ = this.translations$.pipe(
        map(a => a.filter(t => t.value === null)),
        map(a => a.length),
    );

    /**
     * Total number of translated translations
     */
    readonly translatedTranslationsCount$ = combineLatest(this.translationsCount$, this.missingTranslationsCount$).pipe(
        map(([total, missing]) => total - missing),
    );

    /**
     * Returns the "local" translation value
     */
    readonly localTranslation$: Observable<string> = combineLatest(
        this.store.selectedTranslationKey$,
        this.store.defaultTranslations$,
    ).pipe(
        map(([key, translations]) => {
            const found = translations.find(t => t.key === key);
            return (found && found.value) ? found.value : key;
        }),
        takeUntil(this.destroyed$)
    );

    /**
     * Returns the "foreign" translation value
     */
    readonly foreignTranslation$: Observable<string> = combineLatest(
        this.store.selectedTranslationKey$,
        this.translations$,
    ).pipe(
        map(([key, translations]) => {
            const found = translations.find(t => t.key === key);
            return (found && found.value) ? found.value : key;
        }),
        takeUntil(this.destroyed$)
    );

    /**
     * Holds translation form fields
     */
    readonly form: FormGroup = new FormGroup({
        local: new FormControl(null),
        foreign: new FormControl(null),
    });

    readonly showIndicatorsControl = new FormControl(this.store.showIndicators$.value);

    constructor(
        private store: NovoTranslationService,
    ) { }

    ngOnInit() {
        this.foreignTranslation$.subscribe(trans => {
            this.form.controls.foreign.setValue(trans, { emitEvent: false });
        });
        this.localTranslation$.subscribe(trans => {
            this.form.controls.local.setValue(trans, { emitEvent: false });
        });

        // Setup valuechange listener on form control change
        this.form.controls.foreign.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(500),
            withLatestFrom(this.store.selectedTranslationKey$, this.foreignLanguage$),
            filter(([value]) => value.trim().length > 0),
            switchMap(([value, key, language]) => {
                return this.store.updateTranslation(language, key, value);
            }),
            takeUntil(this.destroyed$)
        ).subscribe();

        this.showIndicatorsControl.valueChanges.subscribe(enabled => {
            this.store.showIndicators$.next(enabled);
        });

        // Listen for key changes and maximize the editor when a new key is selected
        this.store.selectedTranslationKey$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
        ).subscribe(_ => {
            if (this.minimized$.value) {
                this.minimized$.next(false);
            }
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    /**
     * Select a language to use
     *
     * @param lang
     */
    selectLanguage(lang: string) {
        this.store.selectLanguage(lang);
    }

    /**
     * Selects a translation to show in the editor
     *
     * @param key
     */
    selectTranslation(key: string) {
        this.store.selectedTranslationKey$.next(key);
    }

    /**
     * Toggle the editors minimized state
     */
    minimizeToggle() {
        this.minimized$.next(!this.minimized$.value);
    }

    /**
     * Close and destroy the editor
     */
    close() {
        this.closeEvent.next(true);
    }

    /**
     * Save a translation
     */
    save() {
        combineLatest(
            this.foreignLanguage$,
            this.store.selectedTranslationKey$
        ).pipe(
            take(1),
            switchMap(([language, key]) => {
                const value = this.form.value.foreign;
                return this.store.updateTranslation(language, key, value);
            }),
        ).subscribe();
    }

    /**
     * Select a filter for translations
     *
     * @param selected
     */
    selectFilter(selected: string | null) {
        this.minimized$.next(false);
        this.selectedFilter$.next(selected);
    }
}
