import { flattenDeep } from 'lodash-es';

/**
 * Nested object where value can either be 'string'
 * or another object.
 */
interface RecursiveObject {
    [key: string]: RecursiveObject | string;
}

interface KeyValueObject {
    key: string;
    value: string;
}

/**
 * Flattens a multi-demensional object into key->value
 * pairs where the key represents the object's path.
 */
export const flattenObject = (obj: RecursiveObject, path?: string): KeyValueObject[] => {
    const mapOfObjects = Object.keys(obj).map(key => {
        const value = obj[key];
        return (typeof value === 'string') ? { key: path ? [path, key].join('.') : key, value } : flattenObject(value, key);
    });

    return flattenDeep<KeyValueObject>(mapOfObjects);
};
