import { LoginProviderId } from '.';

export class SocialUser {
    provider: LoginProviderId;
    id: string;
    email: string;
    name: string;
    photoUrl: string;
    firstName: string;
    lastName: string;
    accessToken: string;

    idToken?: string; // Reference https://developers.google.com/identity/sign-in/web/backend-auth

    authorizationCode?: string; // Reference https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
}
