import { createSelector } from '@ngrx/store';
import { clone } from 'lodash-es';
import { Features, Group, User, UserFlag } from '../models';
import { Permission, PermissionMap } from '../models/roles';
import * as AuthActions from './auth-actions';
import { AuthAction } from './auth-actions';
import * as GroupActions from './group-actions';
import { GroupAction } from './group-actions';
import { selectGroupState } from './group-reducer';
import { AppState, Authentication, GroupState } from './state';

const noPermissions = { group: {}, course: {}, activity: {}, class: {}, system: {} };
const defaultAuthState: Authentication = { user: undefined, sessionToken: undefined, permissions: noPermissions };

export function authenticationReducer(auth: Authentication = defaultAuthState, action: AuthAction | GroupAction): Authentication {
    switch (action.type) {
        case AuthActions.SET_AUTHENTICATED_USER:
            return {
                ...auth,
                user: action.user,
            };
        case AuthActions.SET_SESSION_TOKEN:
            return {
                ...auth,
                sessionToken: action.sessionToken
            };
        case AuthActions.CLEAR_AUTHENTICATION:
            return {
                user: undefined,
                sessionToken: undefined,
                permissions: noPermissions
            };

        case AuthActions.UPDATE_USER:
            return {
                ...auth,
                user: action.user,
            };
        case AuthActions.SET_USER_PERMISSIONS:
            return {
                ...auth,
                permissions: action.permissions
            };
        case GroupActions.REMOVE_GROUP: {
            const groups = { ...auth.permissions.group };
            delete groups[action.identifier];

            return {
                ...auth,
                permissions: {
                    ...auth.permissions,
                    group: groups
                }
            };
        }
        case AuthActions.SET_USER_FLAG: {
            let user = auth.user;
            if (!user) { return auth; }
            user = clone(user);
            user.flags = { ...user.flags, [action.flag]: true };
            return {
                ...auth,
                user: user
            };
        }
        case AuthActions.UPDATE_PROFILE_IMAGE: {
            let user = auth.user;
            if (!user) { return auth; }
            user = clone(user);
            user.profilePicture = action.file;
            return {
                ...auth,
                user: user
            };
        }
        case AuthActions.UPDATE_COVER_IMAGE: {
            let user = auth.user;
            if (!user) { return auth; }
            user = clone(user);
            user.coverImage = action.file;
            return {
                ...auth,
                user: user
            };
        }
        default:
            return auth;
    }
}

export const selectAuth = (state: AppState) => state.auth;
export const selectPermissions = (state: AppState) => state.auth.permissions;
/**
 * @deprecated prefer usage of @see {getCurrentUser}
 */
export const selectCurrentUser = (state: AppState) => state.auth.user;
export const selectSessionToken = (state: AppState) => state.auth.sessionToken;

export const isContentCreator = createSelector(
    selectPermissions,
    (permissions: PermissionMap): boolean => {
        if (permissions && permissions.group) {
            const groupPermissionMap: { [groupId: string]: Permission[] } = permissions.group;
            // Check if user is a content creator in any of its groups
            for (const groupId of Object.keys(groupPermissionMap)) {
                if (groupPermissionMap[groupId].find(p => p === 'course_create')) {
                    return true;
                }
            }
        }
        return false;
    }
);

export const getCurrentUser = createSelector(
    selectCurrentUser,
    (user: User): User | undefined => user
);

export const selectEnabledFeatures = createSelector(
    selectCurrentUser,
    selectGroupState,
    (user: User, gs: GroupState): Required<Features> & { account_delete: boolean } => {
        if (user == null || gs == null || user.organisation == null || gs.groups == null || gs.groups[user.organisation] == null) {
            return {
                chats: false,
                notifications: false,
                account_delete: false
            };
        }
        const userGroup: Group = gs.groups[user.organisation]!;
        return {
            chats: userGroup.features.chats === true && user.features.chats === true,
            notifications: user.features.notifications === true,
            account_delete: userGroup.features.account_delete === true
        };
    }
);

/**
 * Returns if the current user has the requested flag.
 * Will return `null` when no user is available.
 */
export const userHasFlag = (flag: UserFlag) => createSelector(
    selectCurrentUser,
    (user) => user ? (user.flags || {})[flag] == true : null
);
