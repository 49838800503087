import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    exports: [
        MatButtonModule,
        MatDividerModule,
        MatMenuModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatStepperModule,
        MatRadioModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatRippleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSidenavModule,
        MatToolbarModule
    ]
})
export class MaterialModule { }
