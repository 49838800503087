import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencySpacePipe } from './currency-space.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        CurrencySpacePipe
    ],
    declarations: [
        CurrencySpacePipe
    ],
    providers: [],
})
export class PipeModule { }
