import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { buffer, debounceTime, distinct, filter, scan, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { NovoTranslationApi } from './novo-translation-api.class';
import { Translation } from './novo-translation.interface';

export class NovoMissingTranslationHandler implements MissingTranslationHandler {

    /**
     * Subject where missing key will be emitted to
     */
    private missing$: ReplaySubject<Translation> = new ReplaySubject<Translation>(1);

    /**
     * Accumulator of unique missing keys
     */
    readonly missingTranslations$: Observable<Translation[]> = this.missing$.pipe(
        distinct(v => Object.values(v).join('_')),
        scan<Translation, Translation[]>((acc, cur) => [...acc, cur], []),
        startWith([]),
        debounceTime(250),
        shareReplay(1)
    );

    constructor(
        private readonly api: NovoTranslationApi
    ) {
        // Setup observable which buffers values emitted to missing$ and
        // posts them to the API server in a debounced fashion
        this.missing$.pipe(
            distinct(v => Object.values(v).join('_')),
            buffer(this.missing$.pipe(debounceTime(500))),
            filter(a => a.length > 0),
            switchMap(keys => this.api.markMissingTranslations(keys)),
        ).subscribe(_ => {
            // void
        });
    }

    /**
     * This will inform us about missing translations for the current language
     * We'll use this to inform the Novo Api about missing translations
     *
     * @param params
     */
    handle(params: MissingTranslationHandlerParams) {
        this.missing$.next({
            key: params.key,
            value: null,
            language: params.translateService.currentLang
        });

        const defaultLang = params.translateService.defaultLang;
        const defaultTranslations = params.translateService.translations[defaultLang];
        const defaultTranslation = params.translateService.parser.getValue(defaultTranslations, params.key);
        return defaultTranslation ? params.translateService.parser.interpolate(defaultTranslation, params.interpolateParams) : 'missing translation';
    }

}
