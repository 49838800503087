import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LoginProvider, LoginProviderId } from './login-provider';
import { SocialUser } from './user';

export abstract class BaseLoginProvider implements LoginProvider {
    public static readonly PROVIDER_ID: LoginProviderId;

    protected _readyState = new BehaviorSubject<boolean>(false);

    private _enabled = false;

    constructor() { }
    isEnabled(): boolean {
        return this._enabled;
    }

    isCriticalError(err: any): boolean {
        return true; // Default implementation assumes all rejection from social login are critical
    }

    protected onReady(): Promise<void> {
        return this._readyState.pipe(first(b => b === true), map(_ => void 0)).toPromise();
    }

    abstract initialize(): Promise<void>;
    abstract getLoginStatus(): Promise<SocialUser | undefined>;
    abstract signIn(): Promise<SocialUser>;
    abstract signOut(revoke?: boolean): Promise<any>;
    enable(): void { this._enabled = true; }

    loadScript(id: string, src: string, onload: any, async = true): void {
        // get document if platform is only browser
        if (typeof document !== 'undefined' && !document.getElementById(id)) {
            const signInJS = document.createElement('script');
            signInJS.async = async;
            signInJS.src = src;
            signInJS.onload = onload;
            document.head.appendChild(signInJS);
        }
    }
}
