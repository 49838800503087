import { Inject, Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { NovoMissingTranslationHandler } from './novo-missing-translation.handler.class';
import { NovoTranslationApi } from './novo-translation-api.class';
import { Translation } from './novo-translation.interface';
import { flattenObject } from './util';

export interface TranslationStore {
    [key: string]: Translation[];
}

@Injectable()
export class NovoTranslationService {

    /**
     * Current selected translation for editing
     */
    readonly selectedTranslationKey$ = new ReplaySubject<string>(1);

    /**
     * Enabled state of translation editor
     */
    readonly enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Observable with all available translations per language, mapped
     * intro an array of Translation objects. To make sure we always have the
     * latest translations we're using the following events:
     * - onTranslationChange: fired when a translation is modified manually
     * - onLangChange:  fired when a new language is set. This always fires after actual
     *                  translations have been loaded.
     */
    readonly translations$: Observable<TranslationStore> = merge(
        this.translate.onTranslationChange,
        this.translate.onLangChange.pipe(
            distinctUntilKeyChanged('lang')
        )
    ).pipe(
        startWith(null),
        map(_ => this.translate.translations),
        map(translations => {
            return Object.keys(translations).reduce((acc, language) => {
                return { ...acc, [language]: flattenObject(translations[language]).map(obj => ({ language, ...obj })) };
            }, {} as TranslationStore);
        }),
    );

    /**
     * List of available languages
     */
    readonly availLanguages$: Observable<string[]> = this.translate.onTranslationChange.pipe(
        startWith(null),
        map(_ => this.translate.getLangs()),
    );

    /**
     * activeLanguage$ emits the current language on subscription and during
     * language changes.
     */
    readonly activeLanguage$: Observable<string> = this.translate.onLangChange.pipe(
        startWith(null),
        map(_ => this.translate.currentLang),
    );

    /**
     * defaultLanguage$ emits the default language on subscription and during
     * language changes.
     */
    readonly defaultLanguage$: Observable<string> = this.translate.onDefaultLangChange.pipe(
        startWith(null),
        map(_ => this.translate.defaultLang),
    );

    readonly showIndicators$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    /**
     * Remote missing translations based on the current language and
     * mapped into our local Translation object
     */
    readonly remoteMissingTranslations$ = this.activeLanguage$.pipe(
        distinctUntilChanged(),
        switchMap(lang => this.api.getMissingTranslations(lang)),
        withLatestFrom(this.activeLanguage$),
        map(([keys, language]) => keys.map(key => ({ key, value: null, language }))),
        shareReplay(1),
    );

    /**
     * (Replay) observable which holds a collection of missing translations for the
     * currently active language. This combines missing translations from the handler
     * as well as an initial remote API request.
     */
    readonly missingTranslations$ = combineLatest(
        this.activeLanguage$,
        this.remoteMissingTranslations$,
        this.missingHandler.missingTranslations$,
    ).pipe(
        map(([language, local, remote]) => {
            return uniqBy(
                [...local, ...remote].filter(t => t.language === language),
                t => t.key
            );
        }),
        shareReplay(1),
    );

    /**
     * Grabs active translations from ngx-translate and flatten into a key->value object
     */
    readonly activeTranslations$: Observable<Translation[]> = combineLatest(this.activeLanguage$, this.translations$).pipe(
        map(([lang, translations]) => translations[lang] || []),
    );

    /**
     * Returns translations for default language
     */
    readonly defaultTranslations$: Observable<Translation[]> = combineLatest(this.defaultLanguage$, this.translations$).pipe(
        map(([lang, translations]) => translations[lang] || []),
    );

    constructor(
        private translate: TranslateService,
        private api: NovoTranslationApi,
        @Inject(MissingTranslationHandler) private missingHandler: NovoMissingTranslationHandler,
    ) { }

    /**
     * Update translation for the given key.
     */
    updateTranslation(language: string, key: string, value: string): Observable<any> {
        // Save new translation
        this.translate.set(key, value, language);

        return this.api.updateTranslation(language, key, value);
    }

    /**
     * Marks given language as selected
     *
     * @param lang
     */
    selectLanguage(lang: string): Observable<any> {
        return this.translate.use(lang);
    }

}
