<app-wrapped-page [header]="false">
    <div class="content">
        <div style="width: 100%; max-width: 400px;" *ngIf="selectedTab$ | async as selectedTab">
            <div class="tabs" style="margin-bottom: 24px;">
                <div class="tab tab--selected"
                    novoTranslate="PARTIALS.LOGIN">
                </div>
            </div>

            <form [formGroup]="loginForm" (ngSubmit)="loginWithPassword()"
                style="width: 100%; max-width: 400px;">

                <div style="width: calc(100% - 48px); margin: 0 24px;">
                    <ng-container *ngFor="let provider of loginProviders | keyvalue">
                        <app-login-button style="margin: 20px 0; height: 44px;" *ngIf="provider.value.isEnabled()"
                            (click)="loginWithIdProvider(provider.key)" [type]="provider.key"
                            [disabled]="(sending$ | async)?.provider === provider.key"
                            [state]="(sending$ | async)?.provider === provider.key ? 'busy' : 'default'">
                        </app-login-button>
                    </ng-container>
                </div>

                <div class="or-divider">
                    <div class="or-divider-label">{{ 'OR' | novoTranslate }}</div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'FORMS.LABELS.EMAIL' | novoTranslate }}</mat-label>
                    <input matInput formControlName="email" type="email" autocomplete="email">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'FORMS.LABELS.PASSWORD' | novoTranslate }}</mat-label>
                    <input matInput formControlName="password" type="password" autocomplete="current-password">
                </mat-form-field>

                <div style="display: flex; flex-direction: column; align-items: center;">
                    <button style="width: calc(100% - 8px);" class="button--xl" mat-raised-button color="primary"
                        type="submit" [disabled]="(sending$ | async)?.loginType === 'password'">
                        <mat-label>{{ 'PARTIALS.LOGIN' | novoTranslate }}</mat-label>
                    </button>
                    <div style="text-align: center; padding: 0 16px; margin-top: 16px;">
                        <a [routerLink]="['/forgot-password']" [queryParams]="{return: router.url}"
                            novoTranslate="PAGES.SIGNUP.FORGOT_PASSWORD"></a>
                    </div>
                </div>

                <app-alert type="danger" *ngIf="!(sending$ | async) && error">
                    <span novoTranslate="FORMS.VALIDATIONS.INCORRECT_LOGIN"></span>
                </app-alert>
            </form>
        </div>
    </div>
</app-wrapped-page>