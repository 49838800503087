import { Injectable } from '@angular/core';
import { Environment } from '../environment';

@Injectable()
export class AccountsService {

    constructor(private environment: Environment) { }

    checkout(trackId: string, groupId?: string): void {
        window.open(`${this.environment.get('accountsUrl')}checkout?trackId=${trackId}&groupId=${groupId}`, '_blank');
    }

    contentAccess(token: string): void {
        window.open(`${this.environment.get('accountsUrl')}content-access/${token}`, '_blank');
    }
}
