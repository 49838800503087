<app-wrapped-page>

    <div class="grid-row hero" style="padding: 75px 0; background: none;">
        <div class="hero__content">
            <div>
                <div class="hero__title" novoTranslate="PAGES.NOT_FOUND.TITLE"></div>
                <h1 class="hero__text" novoTranslate="PAGES.NOT_FOUND.CONTENT"></h1>
            </div>

            <div class="hero__actions">
                <a mat-flat-button color="primary" [routerLink]="['/']"><span novoTranslate="BACK_TO_HOME"></span></a>
            </div>
        </div>
        <div class="hero__image">
            <svg style="max-width: 400px; width: 100%;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 459" xml:space="preserve">
                <g>
                    <path class="st0" d="M330.1,36.5c10.5-0.4,19.3,7.7,19.8,18.2c0.4,10.5-7.7,19.3-18.2,19.8c-10.5,0.4-19.3-7.7-19.8-18.2
            		C311.4,45.9,319.6,37,330.1,36.5z" />
                    <path class="st1" d="M331.7,76.5c-11.6,0.5-21.4-8.5-21.9-20.1S318.4,35,330,34.5c11.6-0.5,21.4,8.5,21.9,20.1
            		C352.3,66.2,343.3,76,331.7,76.5z M330.1,38.5c-9.4,0.4-16.7,8.3-16.3,17.7s8.3,16.7,17.7,16.3s16.7-8.3,16.3-17.7
            		C347.4,45.5,339.5,38.2,330.1,38.5z" />
                </g>
                <g>
                    <path class="st0" d="M144.5,41.7c10.5-0.4,19.3,7.7,19.8,18.2c0.4,10.5-7.7,19.3-18.2,19.8c-10.5,0.4-19.3-7.7-19.8-18.2
            		C125.9,51,134.1,42.1,144.5,41.7z" />
                    <path class="st1" d="M146.2,81.6c-11.6,0.5-21.4-8.5-21.9-20.1c-0.5-11.6,8.5-21.4,20.1-21.9c11.6-0.5,21.4,8.5,21.9,20.1
            		C166.8,71.3,157.8,81.1,146.2,81.6z M144.6,43.7c-9.4,0.4-16.7,8.3-16.3,17.7c0.4,9.4,8.3,16.7,17.7,16.3
            		c9.4-0.4,16.7-8.3,16.3-17.7C161.9,50.6,154,43.3,144.6,43.7z" />
                </g>
                <g>
                    <g id="XMLID_9_">
                        <g>
                            <g>
                                <path
                                    d="M310.7,60.9l1.1,3.5c-26.2,3.9-41.3,28.8-45,34.5c-0.7-0.1-1.4-0.3-2.1-0.4C267.3,94,282.6,65,310.7,60.9z" />
                            </g>
                            <g>
                                <path
                                    d="M266.9,98.9c-0.4,0.7-0.7,1.1-0.7,1.2l-1.8-0.9c0,0,0.1-0.2,0.3-0.6C265.5,98.6,266.2,98.7,266.9,98.9z" />
                            </g>
                            <g>
                                <path
                                    d="M206.4,101.6l-1.7,1c0,0-0.2-0.4-0.7-1.1c0.7-0.2,1.4-0.4,2-0.5C206.2,101.4,206.3,101.6,206.4,101.6z" />
                            </g>
                            <g>
                                <path d="M206,101c-0.7,0.2-1.4,0.3-2,0.5c-3.6-5.6-19.1-30.9-39.8-31.3l0.9-3.2c10.1,0.2,18.2,7.2,28.5,18.1
            					C200.2,92,204.7,98.9,206,101z" />
                            </g>
                            <g>
                                <path d="M339.7,127.3c0,0,0-0.1,0-0.1" />
                            </g>
                        </g>
                    </g>
                </g>
                <path class="st2"
                    d="M423.8,161.6c0.8,1.9,0.3,3.3-0.7,4.4c-1.4,1.6-3.7,2.1-5.8,1.5c-4-1.1-9.3-0.8-13-0.3
            	c-0.5,0.1-0.7-0.7-0.3-0.9c4.5-2.1,8-4.7,9.9-6.3c1.8-1.5,4.4-2.1,6.6-1.3C421.9,159.1,423.1,160,423.8,161.6z" />
                <path class="st2"
                    d="M414.4,129.8c0.8,1.9,0.2,3.3-0.7,4.4c-1.4,1.6-3.7,2.1-5.8,1.5c-4-1.1-9.3-0.8-13-0.3
            	c-0.5,0.1-0.7-0.7-0.2-0.9c4.5-2.1,8-4.7,9.9-6.3c1.8-1.5,4.4-2.1,6.7-1.3C412.5,127.2,413.7,128.1,414.4,129.8z" />
                <path class="st2"
                    d="M394.8,151.9c0.8,1.9,0.3,3.3-0.7,4.4c-1.4,1.6-3.7,2.1-5.8,1.5c-4-1.1-9.3-0.8-13-0.3
            	c-0.5,0.1-0.7-0.7-0.3-0.9c4.5-2.1,8-4.7,9.9-6.3c1.8-1.5,4.4-2.1,6.6-1.3C392.9,149.4,394.1,150.3,394.8,151.9z" />
                <path d="M380.3,82.7l-1.3,1.7c-0.8,1-18.8,23.4-32.9,29.7c-1,0.1-1.9,0.3-2.9,0.4c11.6-3.8,27.1-23.9,31.7-29.7
            	c-10.5-0.1-14.5-5.7-16.5-8.4c-2.8,7.7-10.9,35.5-47.6,38.3c-0.7-0.4-1.3-0.7-2-1.1c40.5-2.8,47.4-37.4,48.6-38.8l0.8-3.2l1.1,3.1
            	c0.1,0.3,3.4,8.7,18.7,8L380.3,82.7z" />
                <path class="st3" d="M140.2,373.7c-5-15.9-7.4-29.9-8.3-41.9c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0.1-0.3c0.1-0.4,0.1-0.8,0.1-1.2l0,0
            	c0-0.3,0-0.5-0.1-0.8c0,0,0,0,0,0c-0.1-0.2-0.1-0.5-0.2-0.7c-0.5-9-0.1-16.9,0.8-23.6c-0.4,0.1-15.3-2.1-19.2-2.2
            	c-0.5,0-0.9,0-0.9,0c-13.9-1.1-28-1.5-41.7-1.9c-1.4,12.4-8.2,23.4-12.5,35l14.8,37.9c0.7,0,1.5-0.1,2.2-0.1
            	c1.6-0.1,3.4,0.7,4.3,1.5c10.3,14.1,18.6,23.8,34.9,33.8c0.3,0.3,0.6,0.5,0.9,0.8c7.2-12.1,17-22.8,26.8-32.7
            	C141.2,376.1,140.6,375,140.2,373.7z" />
                <path class="st4" d="M326,377.4c-1.3,3.9-5,6.8-9.2,7.1c-12.7,0.9-24.7,1.6-35.9,2c-73.8,3.1-116-2.4-131.9-5.2
            	c-2.9-0.5-5.3-2.1-7-4.3c-9.8,9.9-19.6,20.5-26.8,32.7c12.2,10.2,37.8,16.4,51,20.2c22,6,69.1-15.5,101.4-16.8
            	c33.6-1.4,53.3,15.2,79.8-9.4C342.6,392.8,336,382.4,326,377.4z" />
                <path class="st3"
                    d="M389.5,320.7c0.4-2.4-0.3-4.8-1.9-6.7c-0.4-0.5-0.9-1-1.5-1.4l-21.7-0.1l-32.4-1.1c1.5,27.9-3.1,53.3-5.6,64.8
            	c-0.1,0.4-0.2,0.7-0.3,1.1c10,5,16.5,15.4,21.3,26.3c0.7-0.7,1.4-1.4,2.2-2.1C370.8,380,385,351.3,389.5,320.7z" />
                <path d="M381.9,213.6c7,31.4,1.2,67.5-16.6,97l-2.3-0.1c16.4-29.3,22.7-65.4,16.9-96.6c-6.1-33-23.7-62.7-49.6-83.7
            	c-0.6-0.5-1.2-1-1.8-1.5c0.6-0.5,0.6-0.8,0.1-0.8l-1-1.7l0.4-0.5c1.2,0.9,2.5,1.9,3.7,2.9c0.9,0.7,1.7,1.4,2.6,2.2
            	c-0.2,0.1-0.3,0.2-0.5,0.3l0.8,1.8c0.4-0.3,0.8-0.5,1.3-0.7C359.6,153.3,374.9,182.2,381.9,213.6z" />
                <path d="M386.1,312.7l-21.7-0.1l0-0.5c0.3-0.5,0.6-0.9,0.9-1.4l0,0c0.2,0,0.3,0,0.5,0c0.3,0,0.6,0,1,0l14.5,0.3
            	c1.1,0,2.1,0.2,3.1,0.6l0.1,0L386.1,312.7z" />
                <path class="st5" d="M386.1,312.7l-1.7-1C385,312,385.6,312.3,386.1,312.7z" />
                <path
                    d="M383.8,311.3l0.6,0.3c-1-0.4-2-0.6-3.1-0.6l-14.5-0.3c4.9,0.1,13.2,0.1,15.8,0.3C383,311,383.4,311.1,383.8,311.3z" />
                <path class="st6" d="M379.9,214c-6.1-33-23.7-62.7-49.6-83.7c-0.6-0.5-1.2-1-1.8-1.5c-3.4,3-25.4,13.6-55.6,20.2
            	c-11,2.4-24,4.5-38.5,5.1c-27.8,1.1-62.7-2.5-89.9-17c-20.9,20.7-37.7,48.7-42.7,79.1c-6.9,41.3,14.3,84.2,14.3,84.2l-0.2,0.2
            	c6.6,0.3,12.6,0.7,17,1l-0.1,0.8c1.5-9.8,3.9-16.7,5.6-20.9c1.4-3.4,4.4-5.9,8-6.7c62-13.7,121.8-7.6,154.5-2.1
            	c4.6-8.8,3.4-12.2,3.4-12.2c0.2,0.1,0.9,0.4,1.3,1.6c0.8,2.2-0.1,5.9-2.7,11c7.7,1.3,13.8,2.6,17.9,3.5c3.7,0.8,6.5,3.7,7.4,7.3
            	c1.1,5.2,2,10.3,2.6,15.5c6.4-10.3,9.9-20.1,10-20.2l2.8,2c-0.1,0.1-5.1,10.2-12.4,21.3c0.2,2.3,0.4,4.7,0.6,7l31.1,1
            	C379.3,281.2,385.7,245.2,379.9,214z M145.3,190.4c-0.8,1.1-2,1.9-3.8,2.1c-2,0.2-3.2-0.8-3.9-2c-1.1-1.9-0.8-4.2,0.4-6
            	c2.3-3.4,3.7-8.6,4.4-12.3c0.1-0.5,0.9-0.5,0.9,0c0.5,4.9,1.9,9.1,2.9,11.4C147.1,185.9,146.8,188.5,145.3,190.4z M156.4,165.6
            	c-0.8,1.1-2.1,1.9-3.8,2.1c-2,0.2-3.2-0.8-3.9-2c-1.1-1.9-0.8-4.2,0.4-6c2.3-3.4,3.7-8.6,4.4-12.3c0.1-0.5,0.9-0.5,0.9,0
            	c0.5,4.9,1.9,9.1,2.9,11.4C158.1,161.2,157.9,163.7,156.4,165.6z M236.2,231.9c-0.7,0.2-1.4,0.4-2.1,0.4c-1.3,0.1-2.5-0.3-3.5-1
            	c-4-2.8-4.1-10.6-4.1-10.9l0-0.3l0.2-0.3c0.2-0.2,4-5.2,9.2-5.3c3-0.1,5.8,1.5,8.4,4.6l0.2,0.2l0,0.3
            	C244.5,219.6,245.1,229,236.2,231.9z M277.2,220.5c-13.7,0.6-25.4-10.6-27.1-24.1c-4.1,0.7-7.1,1.2-10.2,1.3
            	c-8.3,0.3-13.1-1.5-15.3-2.7c-1.6,12.5-11.9,23.3-24.8,23.8c-14.4,0.6-26.6-11.9-27.2-26.3c-0.6-14.4,10.7-25.6,25-26.2
            	c14.4-0.6,26.6,9.6,27.2,24c0,0.8,0,1.5,0,2.3c1.9,0.8,9,3.1,25.1,0.5c-0.1-14,11-24.8,25.1-25.4c14.4-0.6,26.6,9.7,27.2,24.1
            	C302.7,206.4,291.5,219.9,277.2,220.5z" />
                <path d="M376,121.1l-0.7,0.9c0,0.1-3.5,4.6-2,11.5l0.3,1.5l-1.5-0.4c-1.5-0.9-26.6-7-36.4-2.3c-0.5-0.5-1-0.9-1.6-1.4
            	c9.9-4.8,31.4-0.5,37.3,1.8c-0.7-5.2,1.4-10,2.4-11.7c-23.7-13.6-44.7-1.3-49.3,2.4c-0.5-0.4-1-0.7-1.5-1.1c2.1-1.3,9.8-6,20.3-7.8
            	l0.6,0.5c0.7-0.2,1.5-0.5,2.2-0.9c8.6-1,18.8,0.1,28.9,6.4L376,121.1z" />
                <path class="st7" d="M373.9,121c-1,1.7-3.1,6.5-2.4,11.7c-5.9-2.4-27.4-6.6-37.3-1.8c-0.8-0.7-1.7-1.5-2.6-2.2
            	c-1.2-1-2.4-1.9-3.7-2.9c-1.1-0.8-2.2-1.7-3.3-2.5C329.1,119.8,350.1,107.5,373.9,121z" />
                <path class="st8" d="M358.5,76.4c2,2.7,5.9,8.3,16.5,8.4c-4.6,5.8-20,25.9-31.7,29.7c-10.4,1.8-18.2,6.5-20.3,7.8
            	c-3.9-2.7-8-5.3-12.1-7.6C347.7,111.9,355.8,84.1,358.5,76.4z" />
                <path class="st5" d="M365.3,310.7l0.5,0C365.6,310.7,365.4,310.7,365.3,310.7L365.3,310.7z" />
                <path d="M365.3,310.7L365.3,310.7L365.3,310.7c-1.4,0-2.3,0-2.3-0.1L365.3,310.7z" />
                <path
                    d="M365.3,310.7c-0.3,0.5-0.6,0.9-0.9,1.4l-1.7-1c0.1-0.2,0.2-0.3,0.3-0.5C363,310.6,363.9,310.7,365.3,310.7z" />
                <path
                    d="M364.4,312.1l0,0.5l-32.4-1.1c0-0.7-0.1-1.3-0.1-2l31.1,1c-0.1,0.2-0.2,0.4-0.3,0.5L364.4,312.1z" />
                <path d="M346.2,114.1c-0.8,0.3-1.5,0.6-2.2,0.9l-0.6-0.5C344.2,114.4,345.2,114.2,346.2,114.1z" />
                <path
                    d="M340.9,279.2l2.8,2c-0.1,0.1-5.1,10.2-12.4,21.3c-0.1-1.1-0.2-2.1-0.4-3.2C337.3,289.1,340.8,279.3,340.9,279.2z" />
                <path
                    d="M335.7,132.3c-0.5,0.2-0.9,0.5-1.3,0.7l-0.8-1.8c0.2-0.1,0.3-0.2,0.5-0.3C334.7,131.3,335.2,131.8,335.7,132.3z" />
                <path
                    d="M328.6,128c0.5,0,0.5,0.3-0.1,0.8c-0.7-0.6-1.5-1.2-2.3-1.7c0.9-0.5,1.3-0.7,1.3-0.8L328.6,128z" />
                <path d="M327.9,125.9l-0.4,0.5c0,0-0.5,0.3-1.3,0.8c-1-0.7-2-1.5-3-2.2l0.4-0.6l0,0c0-0.1,0.3-0.4,0.9-0.9
            	C325.7,124.2,326.8,125,327.9,125.9z" />
                <path d="M326.2,127.1c0.8,0.6,1.5,1.1,2.3,1.7c-3.4,3-25.4,13.6-55.6,20.2c-11,2.4-24,4.5-38.5,5.1c-27.8,1.1-62.7-2.5-89.9-17
            	c0.5-0.5,1.1-1,1.6-1.5c49.5,21.9,96,18.4,126.3,11.5C301,140.6,321,130,326.2,127.1z" />
                <path class="st9"
                    d="M323.2,124.9c1,0.7,2,1.4,3,2.2c-5.2,2.9-25.2,13.5-53.8,20c-30.4,6.9-76.8,10.4-126.3-11.5
            	c0.9-0.9,1.8-1.7,2.8-2.6c0.9-0.8,1.8-1.7,2.8-2.5c1.2-1.1,2.5-2.1,3.7-3.1c1.1-0.9,2.2-1.7,3.3-2.5c1.5-1.1,3-2.2,4.5-3.2
            	c29.1,8.4,54.9,11.1,74.6,10.3c12.4-0.5,22.9-2.2,31.3-4.2c20.8-4.9,32.6-12.2,35.5-14C311,117,317.3,120.7,323.2,124.9z" />
                <path
                    d="M323.1,122.3c0.5,0.3,1,0.7,1.5,1.1c-0.6,0.5-0.9,0.8-0.9,0.9l-1.1-1.6C322.5,122.7,322.7,122.5,323.1,122.3z" />
                <path d="M142.1,373.1c-4.9-15.6-7.3-29.4-8.2-41.2l-0.1,0l-0.8,1.3c-0.4-0.5-0.8-1-1.1-1.4c0.9,12,3.3,26,8.3,41.9
            	c0.4,1.3,1,2.4,1.8,3.4c0.5-0.5,0.9-0.9,1.4-1.4C142.8,374.9,142.4,374,142.1,373.1z" />
                <path d="M316.7,382.6c-96.1,3.3-149.1-1.5-167.4-3.1c-2.5-0.2-4.6-1.7-6-3.8c-0.5,0.5-0.9,0.9-1.4,1.4c1.7,2.3,4.2,3.8,7,4.3
            	c16,2.8,58.1,8.2,131.9,5.2c11.2-0.5,23.2-1.1,35.9-2c4.2-0.3,7.9-3.2,9.2-7.1c-0.6-0.3-1.2-0.6-1.8-0.8
            	C323.2,379.9,320.2,382.4,316.7,382.6z" />
                <path d="M332,311.6l-1.4-0.1l-0.6,0c1.5,27.7-3,52.9-5.6,64.4c-0.1,0.2-0.1,0.5-0.2,0.7c0.6,0.3,1.2,0.5,1.8,0.8
            	c0.1-0.4,0.2-0.7,0.3-1.1C328.9,364.9,333.5,339.5,332,311.6z" />
                <path d="M332,311.6l-1.4-0.1l0.1-2l1.2,0C331.9,310.2,331.9,310.9,332,311.6z" />
                <path d="M331.3,302.6c-0.1,0.1-0.1,0.2-0.2,0.3l-1.7-1.1l-0.2,0c0.3,3.3,0.6,6.5,0.8,9.7l0.6,0l0.1-2l1.2,0
            	C331.7,307.3,331.5,304.9,331.3,302.6z" />
                <path d="M330.9,299.4c-0.3,0.5-0.6,0.9-0.9,1.4c0.1,0.4,0.2,0.8,0.3,1.2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2,0.3-0.4,0.6-0.6,1l1.7,1.1
            	c0.1-0.1,0.1-0.2,0.2-0.3C331.2,301.5,331,300.5,330.9,299.4z" />
                <path d="M323.7,124.3L323.7,124.3C323.7,124.3,323.6,124.3,323.7,124.3z" />
                <path
                    d="M323.7,124.3l-0.4,0.6c-6-4.2-12.2-8-18.7-11.2c0.4-0.3,0.6-0.4,0.7-0.5l-2.7-0.6c0,0,0,0-0.1,0.1
            	c-11.6-5.5-23.8-9.5-36.4-11.7l0.2-0.9c0,0,0.3-0.4,0.7-1.2c14.7,2.7,28.9,7.7,42.1,14.8c-0.4,0-0.7,0.1-1.1,0.1l2.1,1.1
            	c0.3,0,0.7,0,1-0.1c4.2,2.3,8.2,4.8,12.1,7.6c-0.3,0.2-0.5,0.4-0.5,0.4L323.7,124.3C323.6,124.3,323.7,124.3,323.7,124.3z" />
                <path
                    d="M329.2,301.8l0.2,0c0.2-0.3,0.4-0.6,0.6-1c-0.4-1.5-0.9-2.9-1.5-4.2C328.8,298.3,329,300.1,329.2,301.8z" />
                <path d="M328.3,283.9c-0.8-3.6-3.7-6.5-7.4-7.3c-4.1-0.9-10.2-2.2-17.9-3.5c-0.2,0.4-0.4,0.8-0.6,1.2l-0.2,1.1
            	c7.9,1.2,14.1,2.4,18.3,3.2c2.9,0.6,5.2,3,5.8,5.8c0.9,4.1,1.6,8.1,2.2,12.2c0.6,1.3,1.1,2.7,1.5,4.2c0.3-0.5,0.6-0.9,0.9-1.4
            	C330.3,294.3,329.4,289.1,328.3,283.9z" />
                <path class="st10" d="M328.5,296.6c-0.6-4.1-1.3-8.1-2.2-12.2c-0.6-2.9-2.9-5.3-5.8-5.8c-4.1-0.8-10.3-2-18.3-3.2
            	c-0.7-0.1-1.4-0.2-2.1-0.3C311.1,280.7,323.3,285.2,328.5,296.6z" />
                <path class="st11"
                    d="M330,311.6c-0.2-3.2-0.4-6.5-0.8-9.7c-0.2-1.7-0.4-3.5-0.7-5.2c-5.2-11.4-17.4-15.9-28.4-21.6
            	c-21.2-3.3-53.6-6.9-90.7-6.2c-19.9,0.8-41.1,3.2-62.5,7.9c-3,0.7-5.4,2.7-6.6,5.5c-2.7,6.3-6.8,19.2-6.8,38.6
            	c6.6,31.3,36,54.3,65.8,61.9c27.9,1.1,66.7,1.6,117.5-0.1c3.5-0.1,6.5-2.6,7.5-5.9c-2.1-0.9-4.4-1.5-6.9-1.9c2.5,0.4,4.8,1,6.9,1.9
            	c0.1-0.2,0.1-0.5,0.2-0.7C326.9,364.5,331.5,339.3,330,311.6z M240.5,340.4c-0.6,0.8-1.2,1-1.7,1c0,0,0,0-0.1,0
            	c-1.1,0-1.8-0.7-2.3-1.2c-0.1-0.1-0.2-0.2-0.3-0.2c-1.3-1.1-2.5-1.9-3.7-2.3c-1.8-0.6-3.6-0.3-5.4,0.8c-0.5,0.3-1,0.7-1.5,1.2
            	c-0.5,0.4-0.9,0.8-1.5,1.2c-0.6,0.4-1.2,0.5-1.7,0.5c-0.1,0-0.2,0-0.3,0.1c-0.5,0.1-0.9,0-1.3-0.3c-0.4-0.3-0.6-0.8-0.5-1.2
            	c0-0.2,0-0.3,0-0.5c0-0.6,0-1.4,0.3-2.1c2.2-3.9,5.6-6.1,9.8-6.3c0,0,0.1,0,0.1,0c3.8,0.1,6.8,1.7,9.3,5l0.1,0.1
            	C240.5,336.8,241.9,338.6,240.5,340.4z M250.6,335.4c0,0.2,0,0.3,0,0.5c0,0.5-0.3,1-0.8,1.3c-0.2,0.1-0.4,0.2-0.7,0.2
            	c-0.3,0-0.6-0.1-0.8-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.2-0.9-0.5-1.3-1c-0.5-0.5-0.8-1.1-1.2-1.7c-0.2-0.3-0.4-0.6-0.6-0.9
            	c-2.9-4.1-6.5-6.6-10.5-7.6c-0.8-0.2-1.7-0.3-2.6-0.4c-0.4,0-0.8-0.1-1.2-0.1c-5.8,0.3-10.5,3.1-14.3,8.7c-0.3,0.4-0.6,0.8-0.8,1.3
            	c-0.4,0.6-0.8,1.3-1.3,1.9c-0.4,0.5-0.9,0.7-1.2,0.9c-0.1,0-0.1,0-0.2,0.1c-0.4,0.2-0.9,0.3-1.3,0.1c-0.4-0.2-0.8-0.6-0.9-1
            	c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.3-1-0.2-1.7c0.3-1.1,0.6-2.1,1-2.9c3.9-7.9,9.8-12.3,17.4-13.2c1-0.1,2-0.2,3-0.1
            	c6.8,0.2,12.5,3.7,17,10.4c0.9,1.3,1.5,2.8,2,4.2C250.8,334.2,250.7,334.8,250.6,335.4z M256.8,326.2l-0.4,0.4
            	c-0.3,0.2-0.6,0.4-0.9,0.4c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8-0.2-1-0.5l-0.5-0.6c-0.4-0.4-0.8-0.9-1.1-1.3c-4.5-5.7-9.3-9.2-14.6-10.7
            	c-10.9-3.1-20.1-0.1-28.2,9.2c-0.9,1-1.7,2-2.6,3l-0.7,0.8c-0.5,0.6-1.4,0.7-2,0.2l-0.3-0.2c-0.5-0.4-0.7-1.1-0.5-1.7
            	c0-0.1,0.1-0.3,0.1-0.4c0.1-0.4,0.2-0.8,0.4-1.2c1.8-3.9,4.5-7.1,8.1-9.9c5-3.9,10.1-6.1,15.3-6.6c1.4-0.1,2.7-0.1,4.1-0.1
            	c9.7,0.7,17.6,5.4,23.5,14c0.5,0.7,0.9,1.6,1.3,2.4c0.2,0.3,0.3,0.7,0.5,1C257.5,325,257.3,325.8,256.8,326.2z" />
                <path class="st12"
                    d="M133.4,320.8c0,3.5,0.1,7.1,0.4,11c0.9,11.8,3.3,25.6,8.2,41.2c0.3,0.9,0.7,1.8,1.3,2.6c0.7-0.7,1.3-1.3,2-2
            	c-0.7,0.7-1.3,1.3-2,2c1.4,2.1,3.5,3.6,6,3.8c8.6,0.7,25,2.2,49.9,3.2C169.4,375.1,140.1,352.2,133.4,320.8z" />
                <path d="M311,114.8c-0.3,0-0.7,0-1,0.1l-2.1-1.1c0.4,0,0.7,0,1.1-0.1C309.6,114,310.3,114.4,311,114.8z" />
                <path
                    d="M302.5,112.6l2.7,0.6c-0.1,0-0.3,0.2-0.7,0.5C303.8,113.4,303.1,113,302.5,112.6C302.5,112.7,302.5,112.6,302.5,112.6z" />
                <path d="M302.4,112.7c0.7,0.3,1.4,0.7,2.1,1c-2.8,1.9-14.7,9.2-35.5,14c-8.4,2-18.9,3.6-31.3,4.2c-19.7,0.8-45.5-1.9-74.6-10.3
            	c0.6-0.4,1.2-0.8,1.8-1.2C253,145.9,300.1,114.3,302.4,112.7z" />
                <path d="M305.7,262.1c0.8,2.2-0.1,5.9-2.7,11c-0.7-0.1-1.4-0.2-2.1-0.4c4.6-8.8,3.4-12.2,3.4-12.2
            	C304.5,260.6,305.3,260.9,305.7,262.1z" />
                <path
                    d="M303,273.1c-0.7-0.1-1.4-0.2-2.1-0.4c-0.1,0.2-0.2,0.4-0.3,0.6l1.8,0.9C302.6,273.9,302.8,273.5,303,273.1z" />
                <path d="M302.4,274.3l-1.8-0.9c0.1-0.2,0.2-0.4,0.3-0.6c-32.8-5.5-92.5-11.6-154.5,2.1c-3.6,0.8-6.6,3.3-8,6.7
            	c-1.8,4.2-4.1,11.2-5.6,20.9l-0.4,2.6l0,0.1c-0.9,6.7-1.2,14.6-0.8,23.6c0.1,0.2,0.1,0.5,0.2,0.7c0,0,0,0,0,0
            	c0.6,0.8,1.3,1.7,1.9,2.5l0.1,0c-1.9-25.5,3.3-42.2,6.4-49.6c1.2-2.8,3.7-4.8,6.6-5.5c21.5-4.7,42.6-7.1,62.5-7.9
            	c37.1-0.7,69.5,2.9,90.7,6.2c-1-0.5-2-1.1-3-1.6c1,0.5,2,1.1,3,1.6c0.7,0.1,1.4,0.2,2.1,0.3L302.4,274.3z" />
                <path
                    d="M302.1,192c0.6,14.4-10.6,27.9-24.9,28.5c-13.7,0.6-25.4-10.6-27.1-24.1c0.3-0.1,0.7-0.1,1-0.2l-0.4-3.1
            	c-0.3,0.1-0.6,0.1-0.9,0.1c-0.1-14,11-24.8,25.1-25.4C289.3,167.3,301.5,177.6,302.1,192z M300.1,192.1
            	c-0.6-13.3-11.8-23.6-25.1-23.1c-13.3,0.6-23.7,11.8-23.1,25.1c0.6,13.3,11.8,23.7,25.1,23.1C290.3,216.7,300.7,205.4,300.1,192.1z" />
                <path class="st4" d="M275,169c13.3-0.6,24.6,9.8,25.1,23.1c0.6,13.3-9.8,24.6-23.1,25.1c-13.3,0.6-24.6-9.8-25.1-23.1
            	C251.3,180.8,261.7,169.6,275,169z M266.1,197.5c-0.1-2.2-1-3.9-2-3.9c-1,0-1.7,1.9-1.6,4c0.1,2.2,1,3.9,2,3.9
            	C265.4,201.5,266.1,199.7,266.1,197.5z M261,192.8c-0.1-2.8-1.4-4.9-2.8-4.9c-1.5,0.1-2.6,2.3-2.4,5.1s1.4,4.9,2.9,4.9
            	C260,197.8,261.1,195.5,261,192.8z" />
                <path class="st6" d="M266,100.9c12.6,2.3,24.9,6.2,36.4,11.7c-2.4,1.6-49.5,33.2-137.4,7.7c12.1-8,25.6-13.8,39.7-17.5
            	c11.9-3.1,24.3-4.5,36.8-4.4C249.7,98.7,257.9,99.5,266,100.9z" />
                <path d="M266.2,100l-0.2,0.9c-8.1-1.5-16.3-2.3-24.5-2.4c-12.4-0.2-24.8,1.3-36.8,4.4l-0.1-0.3l1.7-1c0,0-0.1-0.2-0.4-0.6
            	c11.5-2.9,23.4-4.4,35.5-4.4c7.8,0,15.6,0.7,23.3,1.9c-0.2,0.4-0.3,0.6-0.3,0.6L266.2,100z" />
                <path class="st6"
                    d="M264.1,193.6c1,0,1.9,1.7,2,3.9c0.1,2.2-0.6,4-1.7,4c-1,0-1.9-1.7-2-3.9C262.3,195.5,263.1,193.7,264.1,193.6z" />
                <ellipse transform="matrix(0.9991 -4.184616e-02 4.184616e-02 0.9991 -7.8443 10.9808)" class="st6"
                    cx="258.4" cy="192.9" rx="2.6" ry="5" />
                <path class="st6" d="M257.2,324.4c0.3,0.6,0.2,1.3-0.3,1.8l-0.4,0.4c-0.3,0.2-0.6,0.4-0.9,0.4c-0.1,0-0.1,0-0.2,0
            	c-0.4,0-0.8-0.2-1-0.5l-0.5-0.6c-0.4-0.4-0.8-0.9-1.1-1.3c-4.5-5.7-9.3-9.2-14.6-10.7c-10.9-3.1-20.1-0.1-28.2,9.2
            	c-0.9,1-1.7,2-2.6,3l-0.7,0.8c-0.5,0.6-1.4,0.7-2,0.2l-0.3-0.2c-0.5-0.4-0.7-1.1-0.5-1.7c0-0.1,0.1-0.3,0.1-0.4
            	c0.1-0.4,0.2-0.8,0.4-1.2c1.8-3.9,4.5-7.1,8.1-9.9c5-3.9,10.1-6.1,15.3-6.6c1.4-0.1,2.7-0.1,4.1-0.1c9.7,0.7,17.6,5.4,23.5,14
            	c0.5,0.7,0.9,1.6,1.3,2.4C256.8,323.8,257,324.1,257.2,324.4z M255.4,325.5c0.1-0.1,0.3-0.2,0.4-0.4c-0.5-1.1-1-2.2-1.7-3.2
            	c-5.8-8.5-13.3-12.7-22.4-13.3c-1.3-0.1-2.6-0.1-3.8,0.1c-5.2,0.5-10.1,2.8-14.6,6.3c-3.1,2.4-5.8,5.4-7.7,9.4
            	c-0.2,0.5-0.3,1-0.5,1.5c0.1,0.1,0.2,0.1,0.3,0.2c1.1-1.3,2.2-2.5,3.3-3.8c8.4-9.8,18.4-13,29.8-9.7c6.1,1.8,11.1,5.9,15.3,11.2
            	C254.4,324.3,254.9,324.9,255.4,325.5z" />
                <path d="M250.7,193.2l0.4,3.1c-0.3,0.1-0.7,0.1-1,0.2c-0.1-0.7-0.2-1.5-0.2-2.2c0-0.3,0-0.6,0-0.9
            	C250.2,193.3,250.5,193.2,250.7,193.2z" />
                <path class="st6"
                    d="M255.8,325.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.5-0.6-1.1-1.2-1.6-1.8c-4.3-5.4-9.2-9.5-15.3-11.2
            	c-11.4-3.3-21.3,0-29.8,9.7c-1.1,1.3-2.2,2.5-3.3,3.8c-0.1-0.1-0.2-0.1-0.3-0.2c0.2-0.5,0.3-1,0.5-1.5c1.9-4,4.6-7,7.7-9.4
            	c4.5-3.5,9.3-5.7,14.6-6.3c1.3-0.1,2.5-0.1,3.8-0.1c9,0.6,16.6,4.9,22.4,13.3C254.8,322.9,255.3,324.1,255.8,325.1z" />
                <path d="M249.9,193.3c0,0.3,0,0.6,0,0.9c0,0.7,0.1,1.5,0.2,2.2c-4.1,0.7-7.1,1.2-10.2,1.3c-8.3,0.3-13.1-1.5-15.3-2.7
            	c0.1-0.7,0.2-1.5,0.2-2.3C226.6,193.6,233.8,195.9,249.9,193.3z" />
                <path class="st6" d="M250.5,333.5c0.3,0.7,0.2,1.4,0.1,1.9c0,0.2,0,0.3,0,0.5c0,0.5-0.3,1-0.8,1.3c-0.2,0.1-0.4,0.2-0.7,0.2
            	c-0.3,0-0.6-0.1-0.8-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.2-0.9-0.5-1.3-1c-0.5-0.5-0.8-1.1-1.2-1.7c-0.2-0.3-0.4-0.6-0.6-0.9
            	c-2.9-4.1-6.5-6.6-10.5-7.6c-0.8-0.2-1.7-0.3-2.6-0.4c-0.4,0-0.8-0.1-1.2-0.1c-5.8,0.3-10.5,3.1-14.3,8.7c-0.3,0.4-0.6,0.8-0.8,1.3
            	c-0.4,0.6-0.8,1.3-1.3,1.9c-0.4,0.5-0.9,0.7-1.2,0.9c-0.1,0-0.1,0-0.2,0.1c-0.4,0.2-0.9,0.3-1.3,0.1c-0.4-0.2-0.8-0.6-0.9-1
            	c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.3-1-0.2-1.7c0.3-1.1,0.6-2.1,1-2.9c3.9-7.9,9.8-12.3,17.4-13.2c1-0.1,2-0.2,3-0.1
            	c6.8,0.2,12.5,3.7,17,10.4C249.3,330.6,249.9,332.1,250.5,333.5z M249.1,335.8c0-0.6,0.2-1.3,0-1.8c-0.5-1.3-1.1-2.7-1.9-3.9
            	c-4.2-6.2-9.4-9.5-15.8-9.7c-0.9,0-1.8,0-2.8,0.1c-7.1,0.8-12.6,5-16.2,12.3c-0.4,0.8-0.7,1.7-0.9,2.6c-0.1,0.4,0.2,0.9,0.3,1.3
            	c0.3-0.2,0.7-0.3,0.9-0.6c0.7-1,1.4-2.1,2.1-3.1c4-5.9,9.2-9.1,15.6-9.3c1.4,0.2,2.8,0.2,4.2,0.5c4.6,1.1,8.3,4,11.4,8.2
            	c0.6,0.8,1.1,1.8,1.8,2.5C248.1,335.4,248.6,335.6,249.1,335.8z" />
                <path d="M244.5,219.5c0,0.1,0.7,9.4-8.2,12.3c-0.7,0.2-1.4,0.4-2.1,0.4c-1.3,0.1-2.5-0.3-3.5-1c-4-2.8-4.1-10.6-4.1-10.9l0-0.3
            	l0.2-0.3c0.2-0.2,4-5.2,9.2-5.3c3-0.1,5.8,1.5,8.4,4.6l0.2,0.2L244.5,219.5z M241.7,225.4c0.9-2.3,0.8-4.6,0.8-5.4
            	c-2.1-2.5-4.5-2.6-6.8-2.6c-3.7,0.1-6.4,2.3-7.2,3.2c-0.1,1.1-0.2,2.8,0.1,4.4c0,0,0,0,0,0c0,0.3,0.3,5.2,6,5.3c0,0,0.1,0,0.1,0
            	c0.5,0,0.9,0,1.4-0.1C240.3,229.7,241.5,226,241.7,225.4C241.7,225.4,241.7,225.4,241.7,225.4z" />
                <path class="st6"
                    d="M249.1,334c0.2,0.5,0,1.2,0,1.8c-0.4-0.3-0.9-0.4-1.3-0.8c-0.7-0.8-1.2-1.7-1.8-2.5c-3-4.2-6.8-7.1-11.4-8.2
            	c-1.4-0.3-2.8-0.4-4.2-0.5c-6.4,0.2-11.6,3.5-15.6,9.3c-0.7,1-1.4,2.1-2.1,3.1c-0.2,0.3-0.6,0.4-0.9,0.6c-0.1-0.4-0.4-0.9-0.3-1.3
            	c0.2-0.9,0.5-1.8,0.9-2.6c3.7-7.4,9.1-11.6,16.2-12.3c0.9-0.1,1.9-0.1,2.8-0.1c6.3,0.2,11.6,3.5,15.8,9.7
            	C248,331.3,248.6,332.7,249.1,334z M241.1,325.5c-2.9-2.3-6.1-3.5-9.6-3.6c-0.8,0-1.7,0-2.6,0.1c-3.6,0.4-6.8,1.8-9.5,4.1
            	c3.2-2.4,6.9-3.7,11-3.9c0.1,0,0.2,0,0.3,0c0.4,0.1,0.8,0.1,1.3,0.1c1,0.1,2,0.2,3.1,0.4C237.2,323.4,239.2,324.3,241.1,325.5z" />
                <path class="st13" d="M242.5,220c0,0.8,0.1,3.1-0.8,5.4c0,0,0-0.1,0-0.1s-5.2-3.3-13.1-0.3c-0.3-1.6-0.3-3.3-0.1-4.4
            	c0.8-1,3.6-3.2,7.2-3.2C238,217.4,240.4,217.5,242.5,220z" />
                <path class="st14" d="M241.7,225.3C241.7,225.3,241.7,225.4,241.7,225.3C241.7,225.4,241.7,225.4,241.7,225.3
            	c-0.8,2-2.3,3.9-5.3,4.8c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0.1-0.9,0.1-1.4,0.1c-1.1-0.1-2.1-0.4-2.9-0.7c-1.9-0.8-2.8-2.6-3.2-4.6
            	c0,0,0,0,0,0C236.5,222,241.7,225.3,241.7,225.3z" />
                <path class="st14"
                    d="M236.4,230.2c3-1,4.5-2.9,5.3-4.8c-0.2,0.6-1.4,4.2-5.7,4.9C236.2,230.2,236.3,230.2,236.4,230.2z" />
                <path class="st6"
                    d="M239.9,336.1c0.6,0.7,2,2.5,0.6,4.3c-0.6,0.8-1.2,1-1.7,1c0,0,0,0-0.1,0c-1.1,0-1.8-0.7-2.3-1.2
            	c-0.1-0.1-0.2-0.2-0.3-0.2c-1.3-1.1-2.5-1.9-3.7-2.3c-1.8-0.6-3.6-0.3-5.4,0.8c-0.5,0.3-1,0.7-1.5,1.2c-0.5,0.4-0.9,0.8-1.5,1.2
            	c-0.6,0.4-1.2,0.5-1.7,0.5c-0.1,0-0.2,0-0.3,0.1c-0.5,0.1-0.9,0-1.3-0.3c-0.4-0.3-0.6-0.8-0.5-1.2c0-0.2,0-0.3,0-0.5
            	c0-0.6,0-1.4,0.3-2.1c2.2-3.9,5.6-6.1,9.8-6.3c0,0,0.1,0,0.1,0c3.8,0.1,6.8,1.7,9.3,5L239.9,336.1z M239.3,339.5
            	c0.7-1-0.2-1.9-0.7-2.6c-2.1-2.7-4.7-4.3-8.2-4.4c-3.4,0.2-6.4,1.8-8.6,5.6c-0.3,0.5-0.1,1.3-0.1,1.9c0.5-0.1,1.1-0.1,1.5-0.4
            	c1.1-0.7,2-1.7,3.1-2.4c2.3-1.4,4.5-1.6,6.7-0.9c1.5,0.4,2.9,1.3,4.3,2.6C237.8,339.4,238.6,340.5,239.3,339.5z" />
                <path class="st13" d="M234.7,230.3c0.5,0,0.9,0,1.4-0.1C235.6,230.4,235.1,230.4,234.7,230.3z" />
                <path class="st6" d="M238.6,336.9c0.6,0.7,1.4,1.7,0.7,2.6c-0.7,1-1.5-0.1-2.1-0.6c-1.4-1.2-2.8-2.1-4.3-2.6
            	c-2.1-0.7-4.3-0.4-6.7,0.9c-1.1,0.6-2,1.7-3.1,2.4c-0.4,0.3-1,0.3-1.5,0.4c0-0.7-0.1-1.5,0.1-1.9c2.1-3.7,5.2-5.4,8.6-5.6
            	C233.9,332.6,236.5,334.2,238.6,336.9z M233.4,334.9c0.6,0.2,1.2,0.4,1.8,0.8c-1.4-1-3-1.5-4.8-1.6c-2,0.1-3.7,0.8-5.1,2
            	c0.1,0,0.1-0.1,0.2-0.1C228.1,334.4,230.8,334.1,233.4,334.9z" />
                <path class="st14" d="M234.7,230.3C234.6,230.3,234.6,230.4,234.7,230.3c-5.7-0.1-6-4.9-6.1-5.3c0.4,1.9,1.3,3.8,3.2,4.6
            	C232.5,229.9,233.6,230.3,234.7,230.3z" />
                <path class="st6"
                    d="M231.4,321.9c3.6,0.1,6.8,1.3,9.6,3.6c-1.9-1.3-3.9-2.1-6-2.7c-1.1-0.3-2.1-0.4-3.1-0.4c-0.4,0-0.8-0.1-1.3-0.1
            	c-0.1,0-0.2,0-0.3,0c-4.1,0.2-7.8,1.5-11,3.9c2.7-2.3,5.9-3.7,9.5-4.1C229.7,321.9,230.6,321.9,231.4,321.9z" />
                <path class="st6" d="M235.2,335.6c-0.6-0.3-1.2-0.6-1.8-0.8c-2.6-0.8-5.3-0.4-7.9,1.1c-0.1,0-0.1,0.1-0.2,0.1c1.4-1.2,3.1-1.9,5.1-2
            	C232.3,334.1,233.8,334.6,235.2,335.6z" />
                <path
                    d="M224.8,190.5c0,0.8,0,1.5,0,2.3c-0.3-0.1-0.5-0.2-0.5-0.2l-1,1.7c0.1,0,0.5,0.4,1.4,0.8c-1.6,12.5-11.9,23.3-24.8,23.8
            	c-14.4,0.6-26.6-11.9-27.2-26.3c-0.6-14.4,10.7-25.6,25-26.2C212,165.9,224.2,176.1,224.8,190.5z M199.7,215.7
            	c13.3-0.6,23.7-11.8,23.1-25.1c-0.6-13.3-11.8-23.6-25.1-23.1c-13.3,0.6-23.6,11.8-23.1,25.1C175.2,205.9,186.4,216.2,199.7,215.7z" />
                <path
                    d="M224.8,192.8c0,0.8-0.1,1.5-0.2,2.3c-0.9-0.4-1.3-0.8-1.4-0.8l1-1.7C224.3,192.6,224.5,192.7,224.8,192.8z" />
                <path class="st4" d="M222.8,190.5c0.6,13.3-9.8,24.6-23.1,25.1c-13.3,0.6-24.6-9.8-25.1-23.1c-0.6-13.3,9.8-24.6,23.1-25.1
            	C211,166.9,222.3,177.3,222.8,190.5z M187.8,196.8c-0.1-2.8-1.3-4.9-2.7-4.9c-1.4,0.1-2.4,2.3-2.3,5.1c0.1,2.8,1.3,4.9,2.7,4.9
            	C186.9,201.9,188,199.6,187.8,196.8z M182.6,191.5c-0.1-2.8-1.3-4.9-2.7-4.9c-1.4,0.1-2.4,2.3-2.3,5.1s1.3,4.9,2.7,4.9
            	C181.6,196.5,182.7,194.2,182.6,191.5z" />
                <path
                    d="M157.7,123.9c-0.1-0.1-0.2-0.2-0.3-0.3c14.1-10.2,29.9-17.7,46.6-22.2c0.4,0.7,0.6,1,0.7,1.1l0.1,0.3
            	c-14.1,3.6-27.6,9.5-39.7,17.5c-0.1,0-0.1,0-0.2,0l-1.7,1.3c-1.5,1-3,2.1-4.5,3.2C158.3,124.6,158,124.3,157.7,123.9z" />
                <path class="st6" d="M185.1,191.9c1.4-0.1,2.6,2.1,2.7,4.9c0.1,2.8-0.9,5-2.3,5.1c-1.4,0.1-2.6-2.1-2.7-4.9
            	C182.7,194.3,183.7,192,185.1,191.9z" />
                <path class="st6" d="M179.8,186.6c1.4-0.1,2.6,2.1,2.7,4.9c0.1,2.8-0.9,5-2.3,5.1c-1.4,0.1-2.6-2.1-2.7-4.9S178.4,186.6,179.8,186.6
            	z" />
                <path d="M165,120.4c-0.6,0.4-1.2,0.8-1.8,1.2l1.7-1.3C164.9,120.4,164.9,120.4,165,120.4z" />
                <path
                    d="M157.7,123.9c0.3,0.3,0.6,0.6,1,0.9c-1.1,0.8-2.2,1.7-3.3,2.5C155.8,126.2,156.5,125,157.7,123.9z" />
                <path d="M157.4,123.7c0.1,0.1,0.2,0.2,0.3,0.3c-1.1,1-1.9,2.2-2.3,3.4c-1.3,1-2.5,2-3.7,3.1c-0.5-0.4-1-0.8-1.6-1.2
            	C152.5,127.3,154.9,125.5,157.4,123.7z" />
                <path d="M150.1,129.3c0.5,0.4,1,0.8,1.6,1.2c-0.9,0.8-1.9,1.6-2.8,2.5c-0.9,0.9-1.8,1.7-2.8,2.6c-0.5-0.2-1-0.4-1.5-0.7l-0.4-0.4
            	c1.1-1,2.2-2,3.4-3C148.4,130.7,149.2,130,150.1,129.3z" />
                <path
                    d="M146.1,135.6c-0.5,0.5-1.1,1-1.6,1.5c-0.2-0.1-0.5-0.3-0.7-0.4l0.8-1.8C145.1,135.1,145.6,135.4,146.1,135.6z" />
                <path d="M144.2,134.5l0.4,0.4l-0.8,1.8c0.2,0.1,0.5,0.3,0.7,0.4c-20.9,20.7-37.7,48.7-42.7,79.1c-6.9,41.3,14.3,84.2,14.3,84.2
            	l-0.2,0.2c-0.6,0-1.2-0.1-1.7-0.1c-0.3,0-0.6,0-0.9,0c-3.6-7.5-17.4-39.1-14.6-73.7c0.4-4.9,1.1-9.8,2.1-14.6
            	C106.5,182.5,121.9,155.1,144.2,134.5z" />
                <path d="M132.9,301.6l-0.1,0.8l-0.2,1.3c-0.4,0.1-15.5-0.8-19.4-0.9l0-0.5c0.3-0.1,0.6-0.2,0.9-0.3l1.8-1.6
            	C122.5,300.9,128.5,301.2,132.9,301.6z" />
                <path d="M132.8,302.4l-0.4,2.6c0.1-0.4,0.1-0.8,0.2-1.2L132.8,302.4z" />
                <path
                    d="M132.6,303.7c-0.1,0.4-0.1,0.8-0.2,1.2l0,0.1c-0.4,0.1-15.3-2.1-19.2-2.2C117.1,302.9,132.2,303.9,132.6,303.7z" />
                <path d="M133.8,331.9l-0.8,1.3c-0.4-0.5-0.8-1-1.1-1.4c0-0.1,0-0.3,0-0.4c0.1-0.4,0.1-0.8,0.1-1.2c0,0,0,0,0,0c0-0.2,0-0.5-0.1-0.7
            	C132.5,330.2,133.1,331,133.8,331.9z" />
                <path d="M131.8,329.3c0,0.2,0.1,0.5,0.1,0.7C131.9,329.8,131.9,329.6,131.8,329.3C131.8,329.3,131.8,329.3,131.8,329.3
            	C131.8,329.3,131.8,329.3,131.8,329.3z" />
                <path d="M131.8,331.3c0,0.1,0,0.3,0,0.4c0,0-0.1-0.1-0.1-0.1C131.8,331.5,131.8,331.4,131.8,331.3z" />
                <path class="st15" d="M117.3,143.4c0.4,0.4,0,1-0.5,0.8c-4.5-2.1-8.7-3.1-11.2-3.6c-2.4-0.4-4.5-2-5.3-4.2c-0.5-1.3-0.6-2.8,0.2-4.3
            	c0.9-1.8,2.4-2.3,3.8-2.3c2.2,0.1,4,1.5,4.9,3.5C110.8,137,114.5,140.9,117.3,143.4z" />
                <path
                    d="M115.9,300.6l-1.8,1.6c0.2-0.2,0.3-0.5,0.5-0.7c-0.2-0.3-0.3-0.7-0.5-1C114.7,300.5,115.3,300.5,115.9,300.6z" />
                <path
                    d="M114.1,300.5c0.1,0.3,0.3,0.7,0.5,1c-0.2,0.2-0.4,0.4-0.5,0.7c0,0-0.3-0.6-0.8-1.7C113.6,300.5,113.9,300.5,114.1,300.5z" />
                <path class="st15" d="M106.1,114.9c0.4,0.4,0,1-0.5,0.8c-4.5-2.1-8.7-3.1-11.2-3.6c-2.4-0.4-4.5-2-5.3-4.2c-0.5-1.3-0.6-2.7,0.2-4.3
            	c0.9-1.8,2.4-2.3,3.8-2.3c2.2,0.1,4,1.5,4.9,3.5C99.7,108.5,103.4,112.4,106.1,114.9z" />
                <path
                    d="M114.1,302.1c-0.3,0.1-0.6,0.1-0.9,0.3c-0.3-0.7-0.7-1.3-1-2c0.4,0,0.7,0,1.1,0.1C113.8,301.6,114.1,302.1,114.1,302.1z" />
                <path d="M112.2,300.4c-14.3-0.6-30.9-1.1-41.5-1.4c0,0.7-0.1,1.3-0.2,2c13.7,0.4,27.8,0.8,41.7,1.9c0,0,0.4,0,0.9,0l0-0.5
            	C112.8,301.7,112.5,301.1,112.2,300.4z" />
                <path class="st15" d="M87.7,142.6c0.4,0.4,0,1-0.5,0.8c-4.5-2.1-8.7-3.1-11.2-3.6c-2.4-0.4-4.5-2-5.3-4.2c-0.5-1.3-0.6-2.7,0.3-4.3
            	c0.9-1.8,2.4-2.3,3.8-2.3c2.2,0.1,4,1.5,4.9,3.5C81.2,136.1,84.9,140,87.7,142.6z" />
                <g>
                    <path class="st16"
                        d="M380,261.7c0,0-20.2,37.4-41.8,48.6l26.3,1.8C364.4,312.1,382.7,269,380,261.7z" />
                </g>
                <g>
                    <path class="st16" d="M371.6,251.3" />
                </g>
                <g>
                    <path class="st16" d="M370.7,283.7" />
                </g>
                <g>
                    <path class="st16"
                        d="M135.3,290.6c0,0-27.9-23.8-34.5-48c0,0-0.6,28.4,13.9,58.9l18,2.2L135.3,290.6z" />
                </g>
            </svg>
        </div>
    </div>

</app-wrapped-page>