import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { PipeModule } from './pipes/pipes.module';
import { SvgUtil } from './utils/svg-util';

@NgModule({
    imports: [
        ComponentsModule,
        PipeModule,
    ],
    exports: [
        ComponentsModule,
        PipeModule
    ],
    providers: [
        SvgUtil,
    ]
})
export class SharedModule { }
