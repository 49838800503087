import { Type } from 'class-transformer';
import { CertificateIssuingConstraints, CertificateTemplate, ExpirationMessage } from './certificate-template';
import { initConstructor, serializeType } from './utilities';

export interface TrackCertificateModel {
    template: CertificateTemplate;
    constraints: CertificateIssuingConstraints[];
    overrideDefaultExpirationMessage: boolean;
    expiresIn?: string;
    expirationMessage?: ExpirationMessage;
}

export class TrackCertificate {
    @Type(serializeType(CertificateTemplate))
    template: CertificateTemplate;
    constraints: CertificateIssuingConstraints[];
    overrideDefaultExpirationMessage: boolean;
    expiresIn?: string;
    expirationMessage?: ExpirationMessage;

    static initialize(json: any): TrackCertificate {
        return new TrackCertificate({
            ...json,
            template: CertificateTemplate.initialize(json.template),
        });
    }

    constructor(data: TrackCertificateModel) {
        initConstructor<TrackCertificateModel>(this, data);
    }
}
