import { MediaFile } from '@novo/platform-common/models';
import { AuthenticationStorage } from '@novo/platform-common/services/auth/auth-storage/auth.storage.service';
import { map } from 'rxjs/operators';
import { Environment } from './services/environment';

// AoT requires an exported function for factories
export const SetMediaBaseUrl = (env: Environment) => {
    return () => {
        MediaFile.baseUrl = env.get('mediaBaseUrl');
    };
};

export const translationEditorEnablerFactory = (auth: AuthenticationStorage) => {
    return auth.user$.pipe(
        map(u => u != null)
    );
};
