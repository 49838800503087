export class SupportedBrowser {
    static readonly CHROME = new SupportedBrowser('Chrome', 'Google', '53', 'chrome', 'https://www.google.com/chrome/browser/desktop', 'chrome_64x64.png');
    static readonly FIREFOX = new SupportedBrowser('Firefox', 'Mozilla', '48', 'firefox', 'http://www.mozilla.org/firefox/new', 'firefox_64x64.png');
    static readonly EDGE = new SupportedBrowser('Edge', 'Microsoft', '38', 'edge', 'https://www.microsoft.com/en-us/windows/microsoft-edge', 'edge_64x64.png');
    static readonly OPERA = new SupportedBrowser('Opera', 'Opera', '40', 'opera', 'https://www.opera.com/download', 'opera_64x64.png');

    // private to disallow creating other instances of this type
    private constructor(public name: string,
        public vendor: string,
        public minVersion: string,
        public code: string,
        public downloadLink: string,
        public icon: string) {
    }
}