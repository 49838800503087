<div class="container" *ngIf="user$ | async as user">
    <mat-menu [overlapTrigger]="false" #userMenu="matMenu">
        <div style="margin: 16px; font-size: 14px;">
            <div style="font-weight: 500;">
                {{ user.fullName }}
            </div>
            <div style="color: rgba(0, 0, 0, 0.54);">
                {{ user.email }}
            </div>
        </div>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">
            <fa-icon style="margin-right: 8px;" [icon]="['fal', 'sign-out']"></fa-icon>
            <span novoTranslate="PARTIALS.LOGOUT"></span>
        </button>
    </mat-menu>
    <div [mat-menu-trigger-for]="userMenu" style="cursor: pointer; display: flex; align-items: center;">
        <span style="margin: 0 10px;">{{ user.fullName }}</span>
        <user-icon [user]="user"></user-icon>
    </div>
</div>
