import { createSelector } from '@ngrx/store';
import { Settings } from '../models';
import { AppState } from './state';

export const settingsState = (state: AppState) => state.settings;

/**
 * Selector returning the currenct DownloadWifiOnly state
 */
export const selectDownloadWifiOnly = createSelector(
    settingsState,
    (settings: Settings) => settings.downloadWifiOnly
);

/**
 * Selector returning the currenct SmartDownloads state
 */
export const selectSmartDownloads = createSelector(
    settingsState,
    (settings: Settings) => settings.smartDownloads
);
