import { Exclude, Type } from 'class-transformer';
import { Group } from './group';
import { LearningTrackTemplatePricing } from './learning-track-template';
import { initConstructor, serializeType } from './utilities';

export interface PublishedData {
    on: Date;
    version?: number;
}

export interface SharedData extends PublishedData {
    by: Group;
    copyAllowed?: boolean;
    pricing?: LearningTrackTemplatePricing;
}

export interface ContentModel {
    readonly identifier: string;
    owner?: Group;
    readonly sourceId?: string;
    lastPublishedVersionId?: string;

    shared?: SharedData;
    published?: PublishedData;
}

export class Content implements ContentModel {
    public readonly identifier: string;
    @Type(serializeType(Group)) public owner?: Group;
    readonly sourceId?: string = undefined;
    lastPublishedVersionId?: string = undefined;

    @Exclude() public shared?: SharedData;
    @Exclude() public published?: { on: Date, version: number };

    public get isPublishedVersion(): boolean {
        return this.sourceId != null && this.sourceId !== '';
    }

    public get hasPublishedVersion(): boolean {
        return this.lastPublishedVersionId != null && this.lastPublishedVersionId !== '';
    }

    public get isSharedWithMe(): boolean {
        return this.shared != null;
    }

    public get isEditable(): boolean {
        return !this.isSharedWithMe && !this.isPublishedVersion;
    }

    public static initializeContent(json: any): ContentModel {
        let owner: Group | undefined;
        if (json.owner) { owner = Group.initialize(json.owner); }

        let shared: SharedData | undefined;
        if (json.shared) {
            shared = {
                ...json.shared,
                by: Group.initialize(json.shared.by),
                on: new Date(json.shared.on)
            };
        }
        let published: PublishedData | undefined;
        if (json.published) {
            published = {
                ...json.published,
                on: new Date(json.published.on)
            };
        }
        return {
            identifier: json.identifier,
            owner,
            sourceId: json.sourceId,
            lastPublishedVersionId: json.lastPublishedVersionId,
            shared,
            published
        }
    }

    constructor(data: ContentModel) {
        initConstructor<ContentModel>(this, data);
    }
}
