export type SocialLoginProvider = 'facebook' | 'google' | 'apple';

export type LoginType = 'password' | 'social';

export interface SocialLoginCredentials {
    provider: SocialLoginProvider;
    userId: string;
    accessToken?: string;
    idToken?: string;
    email?: string;
}

export interface PasswordLoginCredentials {
    username: string;
    password: string;
}


export function isPasswordLogin(type: LoginType | undefined, credentials: PasswordLoginCredentials | SocialLoginCredentials): credentials is PasswordLoginCredentials {
    return type == null || type === 'password';
}

export function isSocialLogin(type: LoginType, credentials: PasswordLoginCredentials | SocialLoginCredentials): credentials is SocialLoginCredentials {
    return type === 'social';
}
