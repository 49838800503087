import { Type } from 'class-transformer';
import { AssignableUnit } from './learning-track';
import { MediaFile } from './media';
import { LanguageCode } from './model-types';
import { TrackCertificate } from './track-certificate';
import { initConstructor, serializeType } from './utilities';

export interface CertificateIssuingConstraints {
    activityIds: string[]; // Ids and the mimimum score required of the editable activities (required)
    // timeFrame?: number; // The maximum number of days between completing all activities
    maximumAttempts?: number; // Maximum number of times a learner is allowed to do the activities. Only the first maximumAttempts attempts are taken into account
}

export interface ExpirationMessage {
    text: Partial<{ [key in LanguageCode]: { email: string, push: string } }>;
    schedule: string[]; // periods in ISO 8601
    extensionUrl: string;
}

export const isValidExpirationMessage = (message: ExpirationMessage): boolean => {
    if (message.extensionUrl != null && message.schedule != null && message.schedule.length > 0 && message.text != null) {
        const languages = Object.keys(message.text) as LanguageCode[];
        return languages.length > 0 && languages.every(lang => {
            const langObj = message.text[lang];
            return langObj && langObj.email && langObj.push;
        });
    }
    return false;
};

export const isValidTrackCertificate = (certificate: TrackCertificate | undefined, units: AssignableUnit[]): boolean => {
    if (!certificate) { return true; }
    if (!certificate.constraints || certificate.constraints.length === 0) { return false; }

    // Check is the constraints specified in the certificate are available in a unit
    const metConstraints = certificate.constraints.filter(constraint => {
        return constraint.activityIds.every(activityId => {
            return units.some(unit => unit.getAssignableSections().find(as => as.identifier === activityId) != null);
        });
    });
    if (metConstraints.length !== certificate.constraints.length) {
        return false;
    }

    return (certificate.expirationMessage == null || isValidExpirationMessage(certificate.expirationMessage));
};


export interface CertificateTemplateModel {
    identifier: string;
    title: string;
    files: MediaFile[];
    ownerId: string;
    defaultExpiresIn?: string; // duration in ISO 8601
    defaultExpirationMessage?: ExpirationMessage;
}

export class CertificateTemplate {
    identifier: string;
    title: string;
    @Type(serializeType(MediaFile))
    files: MediaFile[];
    ownerId: string;
    defaultExpiresIn?: string; // duration in ISO 8601
    defaultExpirationMessage?: ExpirationMessage;

    static initialize(json: any): CertificateTemplate {
        let files: MediaFile[] = [];
        if (json.files) {
            files = json.files.map(file => MediaFile.initialize(file));
        }
        return new CertificateTemplate({
            identifier: json.identifier,
            title: json.title,
            files,
            ownerId: json.ownerId,
            defaultExpiresIn: json.defaultExpiresIn,
            defaultExpirationMessage: json.defaultExpirationMessage
        });
    }

    constructor(data: CertificateTemplateModel) {
        initConstructor<CertificateTemplateModel>(this, data);
    }

    get templateURL(): string | undefined {
        const files = this.files;
        if (files && files.length > 0) {
            return files[0].getURL();
        }
    }
}
