import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { LoginButtonComponent } from './login-button.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        TranslateModule.forChild(),
        FontAwesomeModule
    ],
    declarations: [
        LoginButtonComponent,
    ],
    exports: [
        LoginButtonComponent,
    ]
})
export class LoginButtonModule {

}
