import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Dictionary } from '@novo/platform-common/models';
import * as Sentry from '@sentry/browser';
import { ErrorLoggerService } from './error-logger.service';
import { normalizeMiddlewareData } from './error-logger.util';
import { LoggingDataMiddleware } from './logging-data-middleware';

export interface SentryConfig {
    dsn: string;
    release: string;
}

export const SENTRY_CONFIG = new InjectionToken<SentryConfig>('<Error logger service> Sentry configuration');


const ignoreURLs: RegExp[] = [
    /E-v1\.js/,
    /wistia\.com/,
    /\/login/
];

const ignoreErrors: RegExp[] = [
    /ResizeObserver loop limit exceeded/,
    /Cannot read property 'getReadMode/,
    /Username and emailaddress must be unique/,
    /fireTimeChangedEventsIfChanged/
];

const errorSendAt = {}; // Keep track on when an event was sent, so we filter out many of the same events.

@Injectable()
export class SentryErrorLoggerService extends ErrorLoggerService {

    constructor(@Inject(SENTRY_CONFIG) private sentryConfig: SentryConfig, @Optional() @Inject(LoggingDataMiddleware) private middleware?: LoggingDataMiddleware[]) {
        super();

        if (!this.sentryConfig || !this.sentryConfig.dsn) {
            throw new Error('Sentry has not been configured properly.');
        }

        Sentry.init({
            dsn: this.sentryConfig.dsn,
            release: this.sentryConfig.release,
            beforeSend: this.filterEvent.bind(this)
        });
    }

    /**
     * Filter events before sending them to Sentry.
     * @param event The event which will be sent to Sentry
     */
    private filterEvent(event: Sentry.Event): Sentry.Event | null {
        // Filter on request url
        const requestUrl = event.request?.url;
        if (requestUrl && ignoreURLs.some(ignoreUrl => requestUrl.match(ignoreUrl))) {
            return null;
        }

        const msg = event.message;
        if (msg && !this.sendMessage(msg)) {
            return null;
        } else if (event.exception && event.exception.values) {
            const toSend = event.exception.values.filter(value => !value.value || this.sendMessage(value.value));
            if (toSend.length === 0) { return null; }
            event.exception.values = toSend;
        }

        return event;
    }

    private sendMessage(msg: string): boolean {
        if (ignoreErrors.some(ignoreError => msg.match(ignoreError))) {
            return false;
        }

        const lastSeenAt = errorSendAt[msg];
        const now = new Date().getTime();
        errorSendAt[msg] = now;

        return !lastSeenAt || now - lastSeenAt > 5 * 60 * 1000;
    }

    captureException(error: string | Error | ErrorEvent, data: { extra?: {} } = {}): void {
        if (typeof error === 'string') {
            error = new Error(error);
        }

        void this.setupDataScope(
            error,
            () => { Sentry.captureException(error); },
            data
        );
    }

    captureMessage(message: string, data: { extra?: {} } = {}): void {
        void this.setupDataScope(
            message,
            () => { Sentry.captureMessage(message); },
            data
        );
    }

    /**
     * Creates a new Sentry scope with all data provided by registered middlewares
     *
     * @param err
     * @param closure
     * @param data
     */
    private async setupDataScope(err: string | Error | ErrorEvent, closure: () => void, data: { extra?: Dictionary<unknown> } = {}) {
        console.log(err);
        try {
            // Normalize LoggingDataMiddleware which allows to optionally return a promise.
            const normalizedMiddlewareData: Array<Dictionary<unknown>> = this.middleware ? await normalizeMiddlewareData(this.middleware) : [];

            // Setup a sentry scope with all data and invoke provided closure
            Sentry.withScope((scope) => {
                const extra: Dictionary<unknown> = normalizedMiddlewareData.reduce<Dictionary<unknown>>((acc, accData) => ({
                    ...acc,
                    ...accData,
                }), data.extra || {});
                scope.setExtras(extra);
                closure();
            });
        } catch (e) {
            console.error('Exception trying to send logs to Sentry', e);
        }
    }

    setUserContext(id: string) {
        Sentry.configureScope(scope => scope.setUser({ id }));
    }

    clearUserContext() {
        Sentry.configureScope(scope => scope.clear());
    }
}
