import { Type } from 'class-transformer';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import { Background } from './background';
import { Course } from './course';
import { Group } from './group';
import { InternationalText } from './international-text';
import { LearningTrackTemplate } from './learning-track-template';
import { AssignableUnitType } from './model-types';
import { Prerequisite } from './prerequisite';
import { Section } from './section';
import { TrackCertificate } from './track-certificate';
import { getTempId, initialize, initializeWithId, New, serializeType } from './utilities';

export class AssignableUnitSettings {
    public hideFeedback = false;
    public optional = false;
    public active = true;

    static initialize(json: any): AssignableUnitSettings {
        return initialize(AssignableUnitSettings, json);
    }
}

export class AssignableUnitSection {
    readonly identifier: string;
    readonly prerequisites?: Prerequisite[];
    @Type(serializeType(InternationalText)) title: InternationalText;
    icon?: Background;

    static initialize(json: any): AssignableUnitSection {
        return new AssignableUnitSection(
            json.identifier,
            InternationalText.initialize(json.title),
            json.prerequisites,
            json.icon ? Background.initialize(json.icon) : undefined);
    }

    static initFromSection(section: Section): AssignableUnitSection {
        if (section.identifier == null) { throw new Error('A section must have an identifier before it can be assigned to a learning track.'); }
        return new AssignableUnitSection(section.identifier, section.title, section.prerequisites);
    }

    constructor(id: string, title: InternationalText, prerequisites?: Prerequisite[], icon?: Background) {
        this.identifier = id;
        this.title = title;
        this.prerequisites = prerequisites;
        this.icon = icon;
    }

    getAssignableSections(): AssignableUnitSection[] {
        return [this];
    }
}

export class AssignableUnit {
    readonly type: AssignableUnitType;
    readonly identifier: string;
    @Type(serializeType(InternationalText)) title: InternationalText = new InternationalText();
    @Type(serializeType(AssignableUnitSection)) sections: AssignableUnitSection[] = [];
    @Type(serializeType(AssignableUnitSettings)) settings = new AssignableUnitSettings();
    prerequisites?: Prerequisite[] = undefined;
    icon?: Background;

    static initialize(json: any): AssignableUnit {
        const result = initializeWithId(AssignableUnit, json);
        if (json.title) {
            result.title = InternationalText.initialize(json.title);
        }
        if (json.icon) {
            result.icon = Background.initialize(json.icon);
        }
        if (json.sections) {
            result.sections = json.sections.map(x => AssignableUnitSection.initialize(x));
        }
        if (json.settings) {
            result.settings = AssignableUnitSettings.initialize(json.settings);
        }
        return result;
    }

    static initFromCourse(course: Course): AssignableUnit {
        if (course.identifier == null) { throw new Error('A course must have an identifier before it can be assigned to a learning track.'); }
        const result = new AssignableUnit(course.identifier, 'course', course.title);
        result.sections = course.sections.map((section: Section) => {
            return AssignableUnitSection.initFromSection(section);
        });
        return result;
    }

    static initFromSection(section: Section): AssignableUnit {
        if (section.identifier == null) { throw new Error('A section must have an identifier before it can be assigned to a learning track.'); }
        return new AssignableUnit(section.identifier, 'activity', section.title);
    }

    static is(data: any): data is AssignableUnit {
        return data instanceof AssignableUnit;
    }

    constructor(id: string, type?: 'course' | 'activity' | 'sup-material', title?: InternationalText) {
        this.identifier = id;
        this.type = type || 'activity';
        this.title = title || new InternationalText();
    }

    getAssignableSections(): AssignableUnitSection[] {
        if (this.sections && this.type === 'course') {
            return [...this.sections];
        }
        return [this];
    }
}

export class Assignment {
    public readonly identifier: string;
    public name = '';
    public units: AssignableUnit[] = [];
    public startDate: Date;
    public dueDate?: Date = undefined;
    public prerequisites?: Prerequisite[] = undefined;

    static initialize(json: any): Assignment {
        const result = initializeWithId(Assignment, json);
        if (json.startDate) {
            result.startDate = new Date(json.startDate);
        }
        if (json.dueDate) {
            result.dueDate = new Date(json.dueDate);
        }
        if (json.units) {
            result.units = json.units.map(x => AssignableUnit.initialize(x));
        }
        return result;
    }

    constructor(id: string, startDate: Date = new Date()) {
        this.identifier = id;
        this.startDate = startDate;
    }

    getAssignableSections(): AssignableUnitSection[] {
        return this.units.reduce((sections, unit) => [...sections, ...unit.getAssignableSections()], [] as AssignableUnitSection[]);
    }
}

/**
 * A learning track
 */
export class LearningTrack {
    public readonly identifier: string;
    public readonly templateId?: string;
    public name = '';
    public userId?: string; // Used for personal tracks
    @Type(serializeType(Group)) public owner?: Group = undefined;
    @Type(serializeType(Assignment)) public assignments: Assignment[] = [];
    @Type(serializeType(Background)) coverImage: Background = new Background();
    @Type(serializeType(TrackCertificate)) certificate?: TrackCertificate;


    static initialize(json: any): LearningTrack {
        const result = initializeWithId(LearningTrack, json);
        if (json.owner) {
            result.owner = Group.initialize(json.owner);
        }
        if (json.assignments) {
            result.assignments = json.assignments.map(x => Assignment.initialize(x)).sort(
                (a: Assignment, b: Assignment) => {
                    return (a.startDate < b.startDate) ? -1 : 1;
                }
            );
        }
        if (json.coverImage) {
            result.coverImage = Background.initialize(json.coverImage);
        }
        if (json.certificate) {
            result.certificate = TrackCertificate.initialize(json.certificate);
        }
        return result;
    }

    static create(startDate: Date, owner: Group, template?: LearningTrackTemplate): New<LearningTrack> {
        let learningTrack: New<LearningTrack>;
        if (template) {
            learningTrack = new LearningTrack(undefined, template.identifier);
            learningTrack.name = template.name;
            learningTrack.coverImage = cloneDeep(template.coverImage);
            learningTrack.assignments = template.assignments.map(at => {
                const a = new Assignment(getTempId(at.identifier));
                a.name = at.name;
                a.units = cloneDeep(at.units);
                a.startDate = moment(startDate).add(at.startOffset, 'days').startOf('day').toDate();
                if (at.dueOffset != null) {
                    a.dueDate = moment(startDate).add(at.dueOffset, 'days').endOf('day').toDate();
                }
                if (at.prerequisites != null) {
                    a.prerequisites = at.prerequisites.map((pq: Prerequisite) => {
                        return { targetId: getTempId(pq.targetId), targetType: 'Assignment' } as Prerequisite;
                    });
                }
                return a;
            });
            learningTrack.certificate = cloneDeep(template.certificate);
        }
        else {
            learningTrack = new LearningTrack(undefined);
            learningTrack.assignments = [];
        }
        learningTrack.owner = owner;
        return learningTrack;
    }

    constructor(id: string, templateId?: string) {
        this.identifier = id;
        this.templateId = templateId;
    }

    getStartDate(): Date | undefined {
        if (this.assignments.length === 0) { return; }
        return this.assignments.reduce((p, c) => p > c.startDate ? c.startDate : p, this.assignments[0].startDate);
    }
}
