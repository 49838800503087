import { Exclude, Type } from 'class-transformer';
import { MediaProvider, MediaType, MimeType } from './model-types';
import { initialize, serializeType } from './utilities';


export type ImageFilter = 'svg::duotone' | '_1977' | 'aden' | 'amaro' | 'brannan' | 'brooklyn' | 'clarendon' | 'gingham' |
    'hudson' | 'inkwell' | 'kelvin' | 'lark' | 'lofi' | 'mayfair' | 'moon' | 'nashville' | 'perpetua' |
    'reyes' | 'rise' | 'slumber' | 'stinson' | 'toaster' | 'valencia' | 'walden' | 'willow' | 'xpro2';

export interface DuotoneFilterParams {
    colorA: string;
    colorB: string;
    contrast: number;
    brightness: number;
}

export type ImageFilterParams = DuotoneFilterParams;

export class MediaRole {
    static content: MediaRole = new MediaRole('content', 400, 800, ['', '1:1', '16:9']);
    static background: MediaRole = new MediaRole('background', 400, 1600, ['1:1', '16:9']);
    static logo: MediaRole = new MediaRole('logo', 200, 400, ['', '1:1', '16:9']);
    static icon: MediaRole = new MediaRole('icon', 128, 128, ['', '1:1']);

    readonly name: string;
    readonly minWidth: number;
    readonly maxWidth: number;
    readonly aspectRatios: ('' | '1:1' | '16:9')[];
    private constructor(name: string, minWidth: number, maxWidth: number, aspectRatios: ('' | '1:1' | '16:9')[]) {
        this.name = name;
        this.minWidth = minWidth;
        this.maxWidth = maxWidth;
        this.aspectRatios = aspectRatios;
    }

    public getInstance(roleName: 'background' | 'logo' | 'content' | 'icon'): MediaRole {
        switch (roleName) {
            case 'background':
                return MediaRole.background;
            case 'logo':
                return MediaRole.logo;
            case 'icon':
                return MediaRole.icon;
            case 'content': // tslint:disable-next-line:no-switch-case-fall-through
            default:
                return MediaRole.content;
        }
    }
}

export class MediaTypeInstance {
    static audio = new MediaTypeInstance('audio', [
        'audio/mp3',
        'audio/mpeg',
        'audio/ogg'
    ], ['internal']);
    static video = new MediaTypeInstance('video', [
        'video/mp4', // .mp4, .m4a, .m4v
        'video/3gpp', // .3gp
        'video/quicktime', // .mov
        'video/x-quicktime', // .mov
        'video/webm', // .webm
        'video/x-m4v', // .m4v
        'video/ms-asf', // .wmv, .asf
        'video/x-ms-wmv', // .wmv
        'video/x-ms-wm', // .asf
        'video/x-msvideo', // .avi
        'video/msvideo', // .avi
        'video/avi', // .avi
        'video/x-matroska', // .mkv
        'video/mp1s', // .mpg, .mpeg
        'video/mpeg', // .mpg, .mpeg
        'video/mpg', // .mpg, .mpeg
        'video/x-mpeg', // .mpg, .mpeg
        'video/x-mpg', // .mpg, .mpeg
        'video/x-flv', // .flv, f4v
        'video/x-ms-vob' // .vob
    ], ['internal', 'wistia']);
    static image = new MediaTypeInstance('image', [
        'image/gif',
        'image/png',
        'image/svg+xml',
        'image/jpeg'
    ], ['internal']);
    static imageAvatar = new MediaTypeInstance('image-avatar', [
        'image/gif',
        'image/png',
        'image/svg+xml',
        'image/jpeg'
    ], ['internal']);
    static pixiAvatar = new MediaTypeInstance('pixi-avatar', [
        'application/zip'
    ], ['internal']);


    readonly name: string;
    readonly mimeTypes: MimeType[];
    readonly providers: MediaProvider[];

    public static getInstance(mediaType: MediaType): MediaTypeInstance {
        switch (mediaType) {
            case 'audio':
                return this.audio;
            case 'image':
                return this.image;
            case 'image-avatar':
                return this.imageAvatar;
            case 'pixi-avatar':
                return this.pixiAvatar;
            case 'video':
                return this.video;
        }
    }
    private constructor(name: string, mimeTypes: MimeType[], providers: MediaProvider[]) {
        this.name = name;
        this.mimeTypes = mimeTypes;
        this.providers = providers;
    }
}

/**
 * A MediaFile represents the source of an image, audio or video.
 * The file can define multiple variants which contain the same content,
 * but in a different format or from a different provider.
 */
export class MediaFile {
    @Exclude()
    public static baseUrl: string;

    readonly mimeType: MimeType;
    readonly src: string;
    readonly origSrc?: string;
    thumbnail?: string;

    provider: MediaProvider = 'internal';

    static initialize(json: any): MediaFile {
        const file = new MediaFile(json.mimeType, json.src, json.thumbnail, json.origSrc);
        if (json.provider) {
            file.provider = json.provider;
        }
        return file;
    }

    constructor(mimeType: MimeType, src: string, thumbnail?: string, origSrc?: string) {
        this.mimeType = mimeType;
        this.src = src;
        this.thumbnail = thumbnail;
        this.origSrc = origSrc;
    }

    getURL(): string | undefined {
        return this.constructUrl(this.src);
    }

    getOriginalURL(): string | undefined {
        if (this.origSrc) {
            return this.constructUrl(this.origSrc);
        } else {
            return this.getURL();
        }
    }

    getThumbnailURL(): string | undefined {
        if (this.provider === 'external' || this.provider === 'internal') {
            if (this.mimeType.startsWith('image')) {
                return this.constructUrl(this.thumbnail || this.src);
            }
            if (this.mimeType === 'application/zip') {
                // Animated avatar
                return this.constructUrl(this.thumbnail);
            }
        }
        if (this.provider === 'wistia') {
            if (this.thumbnail) {
                return `https://embed-ssl.wistia.com/deliveries/${this.thumbnail}.jpg?image_crop_resized=200x200`;
            }
        }
        return undefined;
    }

    private constructUrl(location?: string): string | undefined {
        if (this.provider === 'external' || this.provider === 'cached') {
            return location;
        } else if (this.provider === 'internal' && location) {
            return `${MediaFile.baseUrl}${location}`;
        } else if (this.provider === 'wistia' && location) {
            return `https://novolanguage.wistia.com/medias/${location}`;
        }
    }
}

/**
 * A image, audio or video annotated with search keys.
 */
export class Media {
    readonly mediaId: string;
    readonly type: MediaType;

    readonly role: string;

    searchTags: string[] = [];

    @Type(serializeType(MediaFile))
    files: MediaFile[] = [];

    @Exclude()
    ownerId?: string;

    title?: string = undefined;

    static initialize(json?: any): Media | undefined {
        if (json?.mediaId == null || json?.type == null) { return; }

        const media = new Media(json.mediaId, json.type);
        for (const key of Object.keys(media)) {
            if (key === 'files') {
                if (json.files) {
                    for (const file of json.files) {
                        media.files.push(MediaFile.initialize(file));
                    }
                }
            } else if (key !== 'type') {
                media[key] = json[key];
            }
        }
        if (json.group != null) {
            media.ownerId = json.group.identifier;
        }
        return media;
    }

    get mp3(): string | undefined {
        const mp3File = this.files.find(file => file.mimeType === 'audio/mpeg' || file.mimeType === 'audio/mp3');
        return mp3File && mp3File.getURL();
    }

    get ogg(): string | undefined {
        const oggFile = this.files.find(file => file.mimeType === 'audio/ogg');
        return oggFile && oggFile.getURL();
    }

    get url(): string | undefined {
        if (this.files.length > 0) {
            return this.files[0].getURL();
        }
    }

    get originalUrl(): string | undefined {
        if (this.files.length > 0) {
            return this.files[0].getOriginalURL();
        }
    }

    get thumbnailUrl(): string | undefined {
        if (this.files.length > 0) {
            return this.files[0].getThumbnailURL();
        }
    }

    constructor(id: string, type: MediaType, role = 'content') {
        this.mediaId = id;
        this.type = type;
        this.role = role;
    }
}

/**
 * Media with custom settings for each inclusion in a section.
 */
export abstract class MediaInstance {

    get title(): string | undefined {
        if (this.media) { return this.media.title; }
        return '';
    }

    readonly type: MediaType;
    readonly media?: Media = undefined;

    static create(type: MediaType): MediaInstance {
        switch (type) {
            case 'audio':
                return new AudioInstance();
            case 'video':
                return new VideoInstance();
            case 'image':
                return new ImageInstance();
            case 'image-avatar':
                return new ImageAvatarInstance();
            case 'pixi-avatar':
                return new PixiAvatarInstance();
        }
    }

    static initialize(json: any): MediaInstance | undefined {
        let instance: MediaInstance;
        if (!json.media) {
            return undefined;
        }
        switch (json.media.type) {
            case 'audio':
                instance = AudioInstance.initialize(json);
                break;
            case 'video':
                instance = VideoInstance.initialize(json);
                break;
            case 'image':
                instance = ImageInstance.initialize(json);
                break;
            case 'image-avatar':
                instance = ImageAvatarInstance.initialize(json);
                break;
            case 'pixi-avatar':
                instance = PixiAvatarInstance.initialize(json);
                break;
            default:
                return undefined;
        }
        if (instance) {
            (instance as any).media = Media.initialize(json.media);
        }
        return instance;
    }

    constructor(type: MediaType) {
        this.type = type;
    }

    setMedia(media: Media): void {
        if (media.type === this.type) {
            (this as any).media = media;
        } else {
            console.error('Media type ' + media.type + ' does not match media instance type ' + this.type);
        }
    }

    getURL(): string | undefined {
        if (this.media) {
            return this.media.url;
        }
    }
}

export interface PlaybackInterval {
    start?: number;
    duration?: number;
}

export class AudioInstance extends MediaInstance {
    interval: PlaybackInterval = {};
    autoplay = false;

    static initialize(json: any): AudioInstance {
        return initialize(AudioInstance, json);
    }

    constructor() {
        super('audio');
    }
}

export class VideoInstance extends MediaInstance {
    interval: PlaybackInterval = {};
    autoplay = false;

    static initialize(json: any): VideoInstance {
        return initialize(VideoInstance, json);
    }

    constructor() {
        super('video');
    }

    hasInterval(): this is VideoInstance & { interval: { start: number, duration: number } } {
        return this.interval != null && this.interval.start != null && this.interval.duration != null;
    }
}

export class ImageInstance extends MediaInstance {
    filter?: ImageFilter = undefined;
    scale = 1.0;
    containerSize = { width: 1.0, height: 1.0 };
    translation = { x: 0, y: 0 };
    filterParams?: ImageFilterParams = undefined;

    static initialize(json: any): ImageInstance {
        return initialize(ImageInstance, json);
    }

    constructor() {
        super('image');
    }
}

export class ImageAvatarInstance extends MediaInstance {

    variant = 0;
    size = '100%';
    horizontalAlignment: 'left' | 'center' | 'right' = 'center';
    textBalloonType: 'scream' | 'speech' | 'thought' | 'whisper' = 'speech';
    @Type(serializeType(Media)) audio?: Media = undefined;
    autoplay = true;

    static initialize(json: any): ImageAvatarInstance {
        const ai = initialize(ImageAvatarInstance, json);
        if (json.audio) {
            ai.audio = Media.initialize(json.audio);
        }
        return ai;
    }

    constructor() {
        super('image-avatar');
    }
}

export class PixiAvatarInstance extends MediaInstance {

    size = '100%';
    horizontalAlignment: 'left' | 'center' | 'right' = 'center';
    @Type(serializeType(Media))
    audio?: Media = undefined;
    autoplay = true;

    static initialize(json: any): PixiAvatarInstance {
        const ai = initialize(PixiAvatarInstance, json);
        if (json.audio) {
            ai.audio = Media.initialize(json.audio);
        }
        return ai;
    }

    constructor() {
        super('pixi-avatar');
    }
}

export class SubtitleEntry {
    from = 0;
    to = 0;
    text = '';
}


export interface MediaQuery {
    type: MediaType;
    role?: string;
    title: string;
    pageSize: number;
    pageSelected: number;
    sortBy: string;
    sortDirection: 'ASC' | 'DESC';
    hidePublic: boolean;
    limitGroup: number;
    includeReferences: boolean;
}
