import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ProvisionalDataService } from './provisional-data.interface';

const LOCAL_STORAGE_KEY = 'provisional';

@Injectable()
export class ClientProvisionalDataService implements ProvisionalDataService {

    constructor(private localStorage: LocalStorageService) { }

    setProvisionalUser(firstName: string, lastName: string) {
        this.localStorage.store(LOCAL_STORAGE_KEY, { firstName, lastName });
    }

    getProvisionalUser(): { firstName: string, lastName: string } | null {
        const data = this.localStorage.retrieve(LOCAL_STORAGE_KEY);
        return data != null ? data : null;
    }
}
