export const countries = [
    {
        'name': 'Taiwan',
        'currency': null,
        'iso2': 'TW',
        'iso3': 'TWN',
        'languages': 'zh-TW,zh,nan,hak',
        'continent': 'AS'
    },
    {
        'name': 'Afghanistan',
        'currency': 'AFN',
        'iso2': 'AF',
        'iso3': 'AFG',
        'languages': 'fa-AF,ps,uz-AF,tk',
        'continent': 'AS'
    },
    {
        'name': 'Albania',
        'currency': 'ALL',
        'iso2': 'AL',
        'iso3': 'ALB',
        'languages': 'sq,el',
        'continent': 'EU'
    },
    {
        'name': 'Algeria',
        'currency': 'DZD',
        'iso2': 'DZ',
        'iso3': 'DZA',
        'languages': 'ar-DZ',
        'continent': 'AF'
    },
    {
        'name': 'American Samoa',
        'currency': 'USD',
        'iso2': 'AS',
        'iso3': 'ASM',
        'languages': 'en-AS,sm,to',
        'continent': 'OC'
    },
    {
        'name': 'Andorra',
        'currency': 'EUR',
        'iso2': 'AD',
        'iso3': 'AND',
        'languages': 'ca',
        'continent': 'EU'
    },
    {
        'name': 'Angola',
        'currency': 'AOA',
        'iso2': 'AO',
        'iso3': 'AGO',
        'languages': 'pt-AO',
        'continent': 'AF'
    },
    {
        'name': 'Anguilla',
        'currency': 'XCD',
        'iso2': 'AI',
        'iso3': 'AIA',
        'languages': 'en-AI',
        'continent': 'NA'
    },
    {
        'name': 'Antarctica',
        'currency': null,
        'iso2': 'AQ',
        'iso3': 'ATA',
        'languages': null,
        'continent': 'AN'
    },
    {
        'name': 'Antigua & Barbuda',
        'currency': 'XCD',
        'iso2': 'AG',
        'iso3': 'ATG',
        'languages': 'en-AG',
        'continent': 'NA'
    },
    {
        'name': 'Argentina',
        'currency': 'ARS',
        'iso2': 'AR',
        'iso3': 'ARG',
        'languages': 'es-AR,en,it,de,fr,gn',
        'continent': 'SA'
    },
    {
        'name': 'Armenia',
        'currency': 'AMD',
        'iso2': 'AM',
        'iso3': 'ARM',
        'languages': 'hy',
        'continent': 'AS'
    },
    {
        'name': 'Aruba',
        'currency': 'AWG',
        'iso2': 'AW',
        'iso3': 'ABW',
        'languages': 'nl-AW,es,en',
        'continent': 'NA'
    },
    {
        'name': 'Australia',
        'currency': 'AUD',
        'iso2': 'AU',
        'iso3': 'AUS',
        'languages': 'en-AU',
        'continent': 'OC'
    },
    {
        'name': 'Austria',
        'currency': 'EUR',
        'iso2': 'AT',
        'iso3': 'AUT',
        'languages': 'de-AT,hr,hu,sl',
        'continent': 'EU'
    },
    {
        'name': 'Azerbaijan',
        'currency': 'AZN',
        'iso2': 'AZ',
        'iso3': 'AZE',
        'languages': 'az,ru,hy',
        'continent': 'AS'
    },
    {
        'name': 'Bahamas',
        'currency': 'BSD',
        'iso2': 'BS',
        'iso3': 'BHS',
        'languages': 'en-BS',
        'continent': 'NA'
    },
    {
        'name': 'Bahrain',
        'currency': 'BHD',
        'iso2': 'BH',
        'iso3': 'BHR',
        'languages': 'ar-BH,en,fa,ur',
        'continent': 'AS'
    },
    {
        'name': 'Bangladesh',
        'currency': 'BDT',
        'iso2': 'BD',
        'iso3': 'BGD',
        'languages': 'bn-BD,en',
        'continent': 'AS'
    },
    {
        'name': 'Barbados',
        'currency': 'BBD',
        'iso2': 'BB',
        'iso3': 'BRB',
        'languages': 'en-BB',
        'continent': 'NA'
    },
    {
        'name': 'Belarus',
        'currency': 'BYN',
        'iso2': 'BY',
        'iso3': 'BLR',
        'languages': 'be,ru',
        'continent': 'EU'
    },
    {
        'name': 'Belgium',
        'currency': 'EUR',
        'iso2': 'BE',
        'iso3': 'BEL',
        'languages': 'nl-BE,fr-BE,de-BE',
        'continent': 'EU'
    },
    {
        'name': 'Belize',
        'currency': 'BZD',
        'iso2': 'BZ',
        'iso3': 'BLZ',
        'languages': 'en-BZ,es',
        'continent': 'NA'
    },
    {
        'name': 'Benin',
        'currency': 'XOF',
        'iso2': 'BJ',
        'iso3': 'BEN',
        'languages': 'fr-BJ',
        'continent': 'AF'
    },
    {
        'name': 'Bermuda',
        'currency': 'BMD',
        'iso2': 'BM',
        'iso3': 'BMU',
        'languages': 'en-BM,pt',
        'continent': 'NA'
    },
    {
        'name': 'Bhutan',
        'currency': 'INR,BTN',
        'iso2': 'BT',
        'iso3': 'BTN',
        'languages': 'dz',
        'continent': 'AS'
    },
    {
        'name': 'Bolivia',
        'currency': 'BOB',
        'iso2': 'BO',
        'iso3': 'BOL',
        'languages': 'es-BO,qu,ay',
        'continent': 'SA'
    },
    {
        'name': 'Caribbean Netherlands',
        'currency': 'USD',
        'iso2': 'BQ',
        'iso3': 'BES',
        'languages': 'nl,pap,en',
        'continent': 'NA'
    },
    {
        'name': 'Bosnia',
        'currency': 'BAM',
        'iso2': 'BA',
        'iso3': 'BIH',
        'languages': 'bs,hr-BA,sr-BA',
        'continent': 'EU'
    },
    {
        'name': 'Botswana',
        'currency': 'BWP',
        'iso2': 'BW',
        'iso3': 'BWA',
        'languages': 'en-BW,tn-BW',
        'continent': 'AF'
    },
    {
        'name': 'Bouvet Island',
        'currency': 'NOK',
        'iso2': 'BV',
        'iso3': 'BVT',
        'languages': null,
        'continent': 'AN'
    },
    {
        'name': 'Brazil',
        'currency': 'BRL',
        'iso2': 'BR',
        'iso3': 'BRA',
        'languages': 'pt-BR,es,en,fr',
        'continent': 'SA'
    },
    {
        'name': 'British Indian Ocean Territory',
        'currency': 'USD',
        'iso2': 'IO',
        'iso3': 'IOT',
        'languages': 'en-IO',
        'continent': 'AS'
    },
    {
        'name': 'British Virgin Islands',
        'currency': 'USD',
        'iso2': 'VG',
        'iso3': 'VGB',
        'languages': 'en-VG',
        'continent': 'NA'
    },
    {
        'name': 'Brunei',
        'currency': 'BND',
        'iso2': 'BN',
        'iso3': 'BRN',
        'languages': 'ms-BN,en-BN',
        'continent': 'AS'
    },
    {
        'name': 'Bulgaria',
        'currency': 'BGN',
        'iso2': 'BG',
        'iso3': 'BGR',
        'languages': 'bg,tr-BG,rom',
        'continent': 'EU'
    },
    {
        'name': 'Burkina Faso',
        'currency': 'XOF',
        'iso2': 'BF',
        'iso3': 'BFA',
        'languages': 'fr-BF',
        'continent': 'AF'
    },
    {
        'name': 'Burundi',
        'currency': 'BIF',
        'iso2': 'BI',
        'iso3': 'BDI',
        'languages': 'fr-BI,rn',
        'continent': 'AF'
    },
    {
        'name': 'Cape Verde',
        'currency': 'CVE',
        'iso2': 'CV',
        'iso3': 'CPV',
        'languages': 'pt-CV',
        'continent': 'AF'
    },
    {
        'name': 'Cambodia',
        'currency': 'KHR',
        'iso2': 'KH',
        'iso3': 'KHM',
        'languages': 'km,fr,en',
        'continent': 'AS'
    },
    {
        'name': 'Cameroon',
        'currency': 'XAF',
        'iso2': 'CM',
        'iso3': 'CMR',
        'languages': 'en-CM,fr-CM',
        'continent': 'AF'
    },
    {
        'name': 'Canada',
        'currency': 'CAD',
        'iso2': 'CA',
        'iso3': 'CAN',
        'languages': 'en-CA,fr-CA,iu',
        'continent': 'NA'
    },
    {
        'name': 'Cayman Islands',
        'currency': 'KYD',
        'iso2': 'KY',
        'iso3': 'CYM',
        'languages': 'en-KY',
        'continent': 'NA'
    },
    {
        'name': 'Central African Republic',
        'currency': 'XAF',
        'iso2': 'CF',
        'iso3': 'CAF',
        'languages': 'fr-CF,sg,ln,kg',
        'continent': 'AF'
    },
    {
        'name': 'Chad',
        'currency': 'XAF',
        'iso2': 'TD',
        'iso3': 'TCD',
        'languages': 'fr-TD,ar-TD,sre',
        'continent': 'AF'
    },
    {
        'name': 'Chile',
        'currency': 'CLP',
        'iso2': 'CL',
        'iso3': 'CHL',
        'languages': 'es-CL',
        'continent': 'SA'
    },
    {
        'name': 'China',
        'currency': 'CNY',
        'iso2': 'CN',
        'iso3': 'CHN',
        'languages': 'zh-CN,yue,wuu,dta,ug,za',
        'continent': 'AS'
    },
    {
        'name': 'Hong Kong',
        'currency': 'HKD',
        'iso2': 'HK',
        'iso3': 'HKG',
        'languages': 'zh-HK,yue,zh,en',
        'continent': 'AS'
    },
    {
        'name': 'Macau',
        'currency': 'MOP',
        'iso2': 'MO',
        'iso3': 'MAC',
        'languages': 'zh,zh-MO,pt',
        'continent': 'AS'
    },
    {
        'name': 'Christmas Island',
        'currency': 'AUD',
        'iso2': 'CX',
        'iso3': 'CXR',
        'languages': 'en,zh,ms-CC',
        'continent': 'AS'
    },
    {
        'name': 'Cocos (Keeling) Islands',
        'currency': 'AUD',
        'iso2': 'CC',
        'iso3': 'CCK',
        'languages': 'ms-CC,en',
        'continent': 'AS'
    },
    {
        'name': 'Colombia',
        'currency': 'COP',
        'iso2': 'CO',
        'iso3': 'COL',
        'languages': 'es-CO',
        'continent': 'SA'
    },
    {
        'name': 'Comoros',
        'currency': 'KMF',
        'iso2': 'KM',
        'iso3': 'COM',
        'languages': 'ar,fr-KM',
        'continent': 'AF'
    },
    {
        'name': 'Congo - Brazzaville',
        'currency': 'XAF',
        'iso2': 'CG',
        'iso3': 'COG',
        'languages': 'fr-CG,kg,ln-CG',
        'continent': 'AF'
    },
    {
        'name': 'Cook Islands',
        'currency': 'NZD',
        'iso2': 'CK',
        'iso3': 'COK',
        'languages': 'en-CK,mi',
        'continent': 'OC'
    },
    {
        'name': 'Costa Rica',
        'currency': 'CRC',
        'iso2': 'CR',
        'iso3': 'CRI',
        'languages': 'es-CR,en',
        'continent': 'NA'
    },
    {
        'name': 'Croatia',
        'currency': 'HRK',
        'iso2': 'HR',
        'iso3': 'HRV',
        'languages': 'hr-HR,sr',
        'continent': 'EU'
    },
    {
        'name': 'Cuba',
        'currency': 'CUP,CUC',
        'iso2': 'CU',
        'iso3': 'CUB',
        'languages': 'es-CU',
        'continent': 'NA'
    },
    {
        'name': 'Curaçao',
        'currency': 'ANG',
        'iso2': 'CW',
        'iso3': 'CUW',
        'languages': 'nl,pap',
        'continent': 'NA'
    },
    {
        'name': 'Cyprus',
        'currency': 'EUR',
        'iso2': 'CY',
        'iso3': 'CYP',
        'languages': 'el-CY,tr-CY,en',
        'continent': 'EU'
    },
    {
        'name': 'Czechia',
        'currency': 'CZK',
        'iso2': 'CZ',
        'iso3': 'CZE',
        'languages': 'cs,sk',
        'continent': 'EU'
    },
    {
        'name': 'Côte d’Ivoire',
        'currency': 'XOF',
        'iso2': 'CI',
        'iso3': 'CIV',
        'languages': 'fr-CI',
        'continent': 'AF'
    },
    {
        'name': 'North Korea',
        'currency': 'KPW',
        'iso2': 'KP',
        'iso3': 'PRK',
        'languages': 'ko-KP',
        'continent': 'AS'
    },
    {
        'name': 'Congo - Kinshasa',
        'currency': 'CDF',
        'iso2': 'CD',
        'iso3': 'COD',
        'languages': 'fr-CD,ln,kg',
        'continent': 'AF'
    },
    {
        'name': 'Denmark',
        'currency': 'DKK',
        'iso2': 'DK',
        'iso3': 'DNK',
        'languages': 'da-DK,en,fo,de-DK',
        'continent': 'EU'
    },
    {
        'name': 'Djibouti',
        'currency': 'DJF',
        'iso2': 'DJ',
        'iso3': 'DJI',
        'languages': 'fr-DJ,ar,so-DJ,aa',
        'continent': 'AF'
    },
    {
        'name': 'Dominica',
        'currency': 'XCD',
        'iso2': 'DM',
        'iso3': 'DMA',
        'languages': 'en-DM',
        'continent': 'NA'
    },
    {
        'name': 'Dominican Republic',
        'currency': 'DOP',
        'iso2': 'DO',
        'iso3': 'DOM',
        'languages': 'es-DO',
        'continent': 'NA'
    },
    {
        'name': 'Ecuador',
        'currency': 'USD',
        'iso2': 'EC',
        'iso3': 'ECU',
        'languages': 'es-EC',
        'continent': 'SA'
    },
    {
        'name': 'Egypt',
        'currency': 'EGP',
        'iso2': 'EG',
        'iso3': 'EGY',
        'languages': 'ar-EG,en,fr',
        'continent': 'AF'
    },
    {
        'name': 'El Salvador',
        'currency': 'SVC,USD',
        'iso2': 'SV',
        'iso3': 'SLV',
        'languages': 'es-SV',
        'continent': 'NA'
    },
    {
        'name': 'Equatorial Guinea',
        'currency': 'XAF',
        'iso2': 'GQ',
        'iso3': 'GNQ',
        'languages': 'es-GQ,fr',
        'continent': 'AF'
    },
    {
        'name': 'Eritrea',
        'currency': 'ERN',
        'iso2': 'ER',
        'iso3': 'ERI',
        'languages': 'aa-ER,ar,tig,kun,ti-ER',
        'continent': 'AF'
    },
    {
        'name': 'Estonia',
        'currency': 'EUR',
        'iso2': 'EE',
        'iso3': 'EST',
        'languages': 'et,ru',
        'continent': 'EU'
    },
    {
        'name': 'Ethiopia',
        'currency': 'ETB',
        'iso2': 'ET',
        'iso3': 'ETH',
        'languages': 'am,en-ET,om-ET,ti-ET,so-ET,sid',
        'continent': 'AF'
    },
    {
        'name': 'Falkland Islands',
        'currency': null,
        'iso2': 'FK',
        'iso3': 'FLK',
        'languages': 'en-FK',
        'continent': 'SA'
    },
    {
        'name': 'Faroe Islands',
        'currency': 'DKK',
        'iso2': 'FO',
        'iso3': 'FRO',
        'languages': 'fo,da-FO',
        'continent': 'EU'
    },
    {
        'name': 'Fiji',
        'currency': 'FJD',
        'iso2': 'FJ',
        'iso3': 'FJI',
        'languages': 'en-FJ,fj',
        'continent': 'OC'
    },
    {
        'name': 'Finland',
        'currency': 'EUR',
        'iso2': 'FI',
        'iso3': 'FIN',
        'languages': 'fi-FI,sv-FI,smn',
        'continent': 'EU'
    },
    {
        'name': 'France',
        'currency': 'EUR',
        'iso2': 'FR',
        'iso3': 'FRA',
        'languages': 'fr-FR,frp,br,co,ca,eu,oc',
        'continent': 'EU'
    },
    {
        'name': 'French Guiana',
        'currency': 'EUR',
        'iso2': 'GF',
        'iso3': 'GUF',
        'languages': 'fr-GF',
        'continent': 'SA'
    },
    {
        'name': 'French Polynesia',
        'currency': 'XPF',
        'iso2': 'PF',
        'iso3': 'PYF',
        'languages': 'fr-PF,ty',
        'continent': 'OC'
    },
    {
        'name': 'French Southern Territories',
        'currency': 'EUR',
        'iso2': 'TF',
        'iso3': 'ATF',
        'languages': 'fr',
        'continent': 'AN'
    },
    {
        'name': 'Gabon',
        'currency': 'XAF',
        'iso2': 'GA',
        'iso3': 'GAB',
        'languages': 'fr-GA',
        'continent': 'AF'
    },
    {
        'name': 'Gambia',
        'currency': 'GMD',
        'iso2': 'GM',
        'iso3': 'GMB',
        'languages': 'en-GM,mnk,wof,wo,ff',
        'continent': 'AF'
    },
    {
        'name': 'Georgia',
        'currency': 'GEL',
        'iso2': 'GE',
        'iso3': 'GEO',
        'languages': 'ka,ru,hy,az',
        'continent': 'AS'
    },
    {
        'name': 'Germany',
        'currency': 'EUR',
        'iso2': 'DE',
        'iso3': 'DEU',
        'languages': 'de',
        'continent': 'EU'
    },
    {
        'name': 'Ghana',
        'currency': 'GHS',
        'iso2': 'GH',
        'iso3': 'GHA',
        'languages': 'en-GH,ak,ee,tw',
        'continent': 'AF'
    },
    {
        'name': 'Gibraltar',
        'currency': 'GIP',
        'iso2': 'GI',
        'iso3': 'GIB',
        'languages': 'en-GI,es,it,pt',
        'continent': 'EU'
    },
    {
        'name': 'Greece',
        'currency': 'EUR',
        'iso2': 'GR',
        'iso3': 'GRC',
        'languages': 'el-GR,en,fr',
        'continent': 'EU'
    },
    {
        'name': 'Greenland',
        'currency': 'DKK',
        'iso2': 'GL',
        'iso3': 'GRL',
        'languages': 'kl,da-GL,en',
        'continent': 'NA'
    },
    {
        'name': 'Grenada',
        'currency': 'XCD',
        'iso2': 'GD',
        'iso3': 'GRD',
        'languages': 'en-GD',
        'continent': 'NA'
    },
    {
        'name': 'Guadeloupe',
        'currency': 'EUR',
        'iso2': 'GP',
        'iso3': 'GLP',
        'languages': 'fr-GP',
        'continent': 'NA'
    },
    {
        'name': 'Guam',
        'currency': 'USD',
        'iso2': 'GU',
        'iso3': 'GUM',
        'languages': 'en-GU,ch-GU',
        'continent': 'OC'
    },
    {
        'name': 'Guatemala',
        'currency': 'GTQ',
        'iso2': 'GT',
        'iso3': 'GTM',
        'languages': 'es-GT',
        'continent': 'NA'
    },
    {
        'name': 'Guernsey',
        'currency': 'GBP',
        'iso2': 'GG',
        'iso3': 'GGY',
        'languages': 'en,fr',
        'continent': 'EU'
    },
    {
        'name': 'Guinea',
        'currency': 'GNF',
        'iso2': 'GN',
        'iso3': 'GIN',
        'languages': 'fr-GN',
        'continent': 'AF'
    },
    {
        'name': 'Guinea-Bissau',
        'currency': 'XOF',
        'iso2': 'GW',
        'iso3': 'GNB',
        'languages': 'pt-GW,pov',
        'continent': 'AF'
    },
    {
        'name': 'Guyana',
        'currency': 'GYD',
        'iso2': 'GY',
        'iso3': 'GUY',
        'languages': 'en-GY',
        'continent': 'SA'
    },
    {
        'name': 'Haiti',
        'currency': 'HTG,USD',
        'iso2': 'HT',
        'iso3': 'HTI',
        'languages': 'ht,fr-HT',
        'continent': 'NA'
    },
    {
        'name': 'Heard & McDonald Islands',
        'currency': 'AUD',
        'iso2': 'HM',
        'iso3': 'HMD',
        'languages': null,
        'continent': 'AN'
    },
    {
        'name': 'Vatican City',
        'currency': 'EUR',
        'iso2': 'VA',
        'iso3': 'VAT',
        'languages': 'la,it,fr',
        'continent': 'EU'
    },
    {
        'name': 'Honduras',
        'currency': 'HNL',
        'iso2': 'HN',
        'iso3': 'HND',
        'languages': 'es-HN',
        'continent': 'NA'
    },
    {
        'name': 'Hungary',
        'currency': 'HUF',
        'iso2': 'HU',
        'iso3': 'HUN',
        'languages': 'hu-HU',
        'continent': 'EU'
    },
    {
        'name': 'Iceland',
        'currency': 'ISK',
        'iso2': 'IS',
        'iso3': 'ISL',
        'languages': 'is,en,de,da,sv,no',
        'continent': 'EU'
    },
    {
        'name': 'India',
        'currency': 'INR',
        'iso2': 'IN',
        'iso3': 'IND',
        'languages': 'en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc',
        'continent': 'AS'
    },
    {
        'name': 'Indonesia',
        'currency': 'IDR',
        'iso2': 'ID',
        'iso3': 'IDN',
        'languages': 'id,en,nl,jv',
        'continent': 'AS'
    },
    {
        'name': 'Iran',
        'currency': 'IRR',
        'iso2': 'IR',
        'iso3': 'IRN',
        'languages': 'fa-IR,ku',
        'continent': 'AS'
    },
    {
        'name': 'Iraq',
        'currency': 'IQD',
        'iso2': 'IQ',
        'iso3': 'IRQ',
        'languages': 'ar-IQ,ku,hy',
        'continent': 'AS'
    },
    {
        'name': 'Ireland',
        'currency': 'EUR',
        'iso2': 'IE',
        'iso3': 'IRL',
        'languages': 'en-IE,ga-IE',
        'continent': 'EU'
    },
    {
        'name': 'Isle of Man',
        'currency': 'GBP',
        'iso2': 'IM',
        'iso3': 'IMN',
        'languages': 'en,gv',
        'continent': 'EU'
    },
    {
        'name': 'Israel',
        'currency': 'ILS',
        'iso2': 'IL',
        'iso3': 'ISR',
        'languages': 'he,ar-IL,en-IL,',
        'continent': 'AS'
    },
    {
        'name': 'Italy',
        'currency': 'EUR',
        'iso2': 'IT',
        'iso3': 'ITA',
        'languages': 'it-IT,de-IT,fr-IT,sc,ca,co,sl',
        'continent': 'EU'
    },
    {
        'name': 'Jamaica',
        'currency': 'JMD',
        'iso2': 'JM',
        'iso3': 'JAM',
        'languages': 'en-JM',
        'continent': 'NA'
    },
    {
        'name': 'Japan',
        'currency': 'JPY',
        'iso2': 'JP',
        'iso3': 'JPN',
        'languages': 'ja',
        'continent': 'AS'
    },
    {
        'name': 'Jersey',
        'currency': 'GBP',
        'iso2': 'JE',
        'iso3': 'JEY',
        'languages': 'en,pt',
        'continent': 'EU'
    },
    {
        'name': 'Jordan',
        'currency': 'JOD',
        'iso2': 'JO',
        'iso3': 'JOR',
        'languages': 'ar-JO,en',
        'continent': 'AS'
    },
    {
        'name': 'Kazakhstan',
        'currency': 'KZT',
        'iso2': 'KZ',
        'iso3': 'KAZ',
        'languages': 'kk,ru',
        'continent': 'AS'
    },
    {
        'name': 'Kenya',
        'currency': 'KES',
        'iso2': 'KE',
        'iso3': 'KEN',
        'languages': 'en-KE,sw-KE',
        'continent': 'AF'
    },
    {
        'name': 'Kiribati',
        'currency': 'AUD',
        'iso2': 'KI',
        'iso3': 'KIR',
        'languages': 'en-KI,gil',
        'continent': 'OC'
    },
    {
        'name': 'Kuwait',
        'currency': 'KWD',
        'iso2': 'KW',
        'iso3': 'KWT',
        'languages': 'ar-KW,en',
        'continent': 'AS'
    },
    {
        'name': 'Kyrgyzstan',
        'currency': 'KGS',
        'iso2': 'KG',
        'iso3': 'KGZ',
        'languages': 'ky,uz,ru',
        'continent': 'AS'
    },
    {
        'name': 'Laos',
        'currency': 'LAK',
        'iso2': 'LA',
        'iso3': 'LAO',
        'languages': 'lo,fr,en',
        'continent': 'AS'
    },
    {
        'name': 'Latvia',
        'currency': 'EUR',
        'iso2': 'LV',
        'iso3': 'LVA',
        'languages': 'lv,ru,lt',
        'continent': 'EU'
    },
    {
        'name': 'Lebanon',
        'currency': 'LBP',
        'iso2': 'LB',
        'iso3': 'LBN',
        'languages': 'ar-LB,fr-LB,en,hy',
        'continent': 'AS'
    },
    {
        'name': 'Lesotho',
        'currency': 'LSL,ZAR',
        'iso2': 'LS',
        'iso3': 'LSO',
        'languages': 'en-LS,st,zu,xh',
        'continent': 'AF'
    },
    {
        'name': 'Liberia',
        'currency': 'LRD',
        'iso2': 'LR',
        'iso3': 'LBR',
        'languages': 'en-LR',
        'continent': 'AF'
    },
    {
        'name': 'Libya',
        'currency': 'LYD',
        'iso2': 'LY',
        'iso3': 'LBY',
        'languages': 'ar-LY,it,en',
        'continent': 'AF'
    },
    {
        'name': 'Liechtenstein',
        'currency': 'CHF',
        'iso2': 'LI',
        'iso3': 'LIE',
        'languages': 'de-LI',
        'continent': 'EU'
    },
    {
        'name': 'Lithuania',
        'currency': 'EUR',
        'iso2': 'LT',
        'iso3': 'LTU',
        'languages': 'lt,ru,pl',
        'continent': 'EU'
    },
    {
        'name': 'Luxembourg',
        'currency': 'EUR',
        'iso2': 'LU',
        'iso3': 'LUX',
        'languages': 'lb,de-LU,fr-LU',
        'continent': 'EU'
    },
    {
        'name': 'Madagascar',
        'currency': 'MGA',
        'iso2': 'MG',
        'iso3': 'MDG',
        'languages': 'fr-MG,mg',
        'continent': 'AF'
    },
    {
        'name': 'Malawi',
        'currency': 'MWK',
        'iso2': 'MW',
        'iso3': 'MWI',
        'languages': 'ny,yao,tum,swk',
        'continent': 'AF'
    },
    {
        'name': 'Malaysia',
        'currency': 'MYR',
        'iso2': 'MY',
        'iso3': 'MYS',
        'languages': 'ms-MY,en,zh,ta,te,ml,pa,th',
        'continent': 'AS'
    },
    {
        'name': 'Maldives',
        'currency': 'MVR',
        'iso2': 'MV',
        'iso3': 'MDV',
        'languages': 'dv,en',
        'continent': 'AS'
    },
    {
        'name': 'Mali',
        'currency': 'XOF',
        'iso2': 'ML',
        'iso3': 'MLI',
        'languages': 'fr-ML,bm',
        'continent': 'AF'
    },
    {
        'name': 'Malta',
        'currency': 'EUR',
        'iso2': 'MT',
        'iso3': 'MLT',
        'languages': 'mt,en-MT',
        'continent': 'EU'
    },
    {
        'name': 'Marshall Islands',
        'currency': 'USD',
        'iso2': 'MH',
        'iso3': 'MHL',
        'languages': 'mh,en-MH',
        'continent': 'OC'
    },
    {
        'name': 'Martinique',
        'currency': 'EUR',
        'iso2': 'MQ',
        'iso3': 'MTQ',
        'languages': 'fr-MQ',
        'continent': 'NA'
    },
    {
        'name': 'Mauritania',
        'currency': 'MRO',
        'iso2': 'MR',
        'iso3': 'MRT',
        'languages': 'ar-MR,fuc,snk,fr,mey,wo',
        'continent': 'AF'
    },
    {
        'name': 'Mauritius',
        'currency': 'MUR',
        'iso2': 'MU',
        'iso3': 'MUS',
        'languages': 'en-MU,bho,fr',
        'continent': 'AF'
    },
    {
        'name': 'Mayotte',
        'currency': 'EUR',
        'iso2': 'YT',
        'iso3': 'MYT',
        'languages': 'fr-YT',
        'continent': 'AF'
    },
    {
        'name': 'Mexico',
        'currency': 'MXN',
        'iso2': 'MX',
        'iso3': 'MEX',
        'languages': 'es-MX',
        'continent': 'NA'
    },
    {
        'name': 'Micronesia',
        'currency': 'USD',
        'iso2': 'FM',
        'iso3': 'FSM',
        'languages': 'en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg',
        'continent': 'OC'
    },
    {
        'name': 'Monaco',
        'currency': 'EUR',
        'iso2': 'MC',
        'iso3': 'MCO',
        'languages': 'fr-MC,en,it',
        'continent': 'EU'
    },
    {
        'name': 'Mongolia',
        'currency': 'MNT',
        'iso2': 'MN',
        'iso3': 'MNG',
        'languages': 'mn,ru',
        'continent': 'AS'
    },
    {
        'name': 'Montenegro',
        'currency': 'EUR',
        'iso2': 'ME',
        'iso3': 'MNE',
        'languages': 'sr,hu,bs,sq,hr,rom',
        'continent': 'EU'
    },
    {
        'name': 'Montserrat',
        'currency': 'XCD',
        'iso2': 'MS',
        'iso3': 'MSR',
        'languages': 'en-MS',
        'continent': 'NA'
    },
    {
        'name': 'Morocco',
        'currency': 'MAD',
        'iso2': 'MA',
        'iso3': 'MAR',
        'languages': 'ar-MA,ber,fr',
        'continent': 'AF'
    },
    {
        'name': 'Mozambique',
        'currency': 'MZN',
        'iso2': 'MZ',
        'iso3': 'MOZ',
        'languages': 'pt-MZ,vmw',
        'continent': 'AF'
    },
    {
        'name': 'Myanmar',
        'currency': 'MMK',
        'iso2': 'MM',
        'iso3': 'MMR',
        'languages': 'my',
        'continent': 'AS'
    },
    {
        'name': 'Namibia',
        'currency': 'NAD,ZAR',
        'iso2': 'NA',
        'iso3': 'NAM',
        'languages': 'en-NA,af,de,hz,naq',
        'continent': 'AF'
    },
    {
        'name': 'Nauru',
        'currency': 'AUD',
        'iso2': 'NR',
        'iso3': 'NRU',
        'languages': 'na,en-NR',
        'continent': 'OC'
    },
    {
        'name': 'Nepal',
        'currency': 'NPR',
        'iso2': 'NP',
        'iso3': 'NPL',
        'languages': 'ne,en',
        'continent': 'AS'
    },
    {
        'name': 'Netherlands',
        'currency': 'EUR',
        'iso2': 'NL',
        'iso3': 'NLD',
        'languages': 'nl-NL,fy-NL',
        'continent': 'EU'
    },
    {
        'name': 'New Caledonia',
        'currency': 'XPF',
        'iso2': 'NC',
        'iso3': 'NCL',
        'languages': 'fr-NC',
        'continent': 'OC'
    },
    {
        'name': 'New Zealand',
        'currency': 'NZD',
        'iso2': 'NZ',
        'iso3': 'NZL',
        'languages': 'en-NZ,mi',
        'continent': 'OC'
    },
    {
        'name': 'Nicaragua',
        'currency': 'NIO',
        'iso2': 'NI',
        'iso3': 'NIC',
        'languages': 'es-NI,en',
        'continent': 'NA'
    },
    {
        'name': 'Niger',
        'currency': 'XOF',
        'iso2': 'NE',
        'iso3': 'NER',
        'languages': 'fr-NE,ha,kr,dje',
        'continent': 'AF'
    },
    {
        'name': 'Nigeria',
        'currency': 'NGN',
        'iso2': 'NG',
        'iso3': 'NGA',
        'languages': 'en-NG,ha,yo,ig,ff',
        'continent': 'AF'
    },
    {
        'name': 'Niue',
        'currency': 'NZD',
        'iso2': 'NU',
        'iso3': 'NIU',
        'languages': 'niu,en-NU',
        'continent': 'OC'
    },
    {
        'name': 'Norfolk Island',
        'currency': 'AUD',
        'iso2': 'NF',
        'iso3': 'NFK',
        'languages': 'en-NF',
        'continent': 'OC'
    },
    {
        'name': 'Northern Mariana Islands',
        'currency': 'USD',
        'iso2': 'MP',
        'iso3': 'MNP',
        'languages': 'fil,tl,zh,ch-MP,en-MP',
        'continent': 'OC'
    },
    {
        'name': 'Norway',
        'currency': 'NOK',
        'iso2': 'NO',
        'iso3': 'NOR',
        'languages': 'no,nb,nn,se,fi',
        'continent': 'EU'
    },
    {
        'name': 'Oman',
        'currency': 'OMR',
        'iso2': 'OM',
        'iso3': 'OMN',
        'languages': 'ar-OM,en,bal,ur',
        'continent': 'AS'
    },
    {
        'name': 'Pakistan',
        'currency': 'PKR',
        'iso2': 'PK',
        'iso3': 'PAK',
        'languages': 'ur-PK,en-PK,pa,sd,ps,brh',
        'continent': 'AS'
    },
    {
        'name': 'Palau',
        'currency': 'USD',
        'iso2': 'PW',
        'iso3': 'PLW',
        'languages': 'pau,sov,en-PW,tox,ja,fil,zh',
        'continent': 'OC'
    },
    {
        'name': 'Panama',
        'currency': 'PAB,USD',
        'iso2': 'PA',
        'iso3': 'PAN',
        'languages': 'es-PA,en',
        'continent': 'NA'
    },
    {
        'name': 'Papua New Guinea',
        'currency': 'PGK',
        'iso2': 'PG',
        'iso3': 'PNG',
        'languages': 'en-PG,ho,meu,tpi',
        'continent': 'OC'
    },
    {
        'name': 'Paraguay',
        'currency': 'PYG',
        'iso2': 'PY',
        'iso3': 'PRY',
        'languages': 'es-PY,gn',
        'continent': 'SA'
    },
    {
        'name': 'Peru',
        'currency': 'PEN',
        'iso2': 'PE',
        'iso3': 'PER',
        'languages': 'es-PE,qu,ay',
        'continent': 'SA'
    },
    {
        'name': 'Philippines',
        'currency': 'PHP',
        'iso2': 'PH',
        'iso3': 'PHL',
        'languages': 'tl,en-PH,fil',
        'continent': 'AS'
    },
    {
        'name': 'Pitcairn Islands',
        'currency': 'NZD',
        'iso2': 'PN',
        'iso3': 'PCN',
        'languages': 'en-PN',
        'continent': 'OC'
    },
    {
        'name': 'Poland',
        'currency': 'PLN',
        'iso2': 'PL',
        'iso3': 'POL',
        'languages': 'pl',
        'continent': 'EU'
    },
    {
        'name': 'Portugal',
        'currency': 'EUR',
        'iso2': 'PT',
        'iso3': 'PRT',
        'languages': 'pt-PT,mwl',
        'continent': 'EU'
    },
    {
        'name': 'Puerto Rico',
        'currency': 'USD',
        'iso2': 'PR',
        'iso3': 'PRI',
        'languages': 'en-PR,es-PR',
        'continent': 'NA'
    },
    {
        'name': 'Qatar',
        'currency': 'QAR',
        'iso2': 'QA',
        'iso3': 'QAT',
        'languages': 'ar-QA,es',
        'continent': 'AS'
    },
    {
        'name': 'South Korea',
        'currency': 'KRW',
        'iso2': 'KR',
        'iso3': 'KOR',
        'languages': 'ko-KR,en',
        'continent': 'AS'
    },
    {
        'name': 'Moldova',
        'currency': 'MDL',
        'iso2': 'MD',
        'iso3': 'MDA',
        'languages': 'ro,ru,gag,tr',
        'continent': 'EU'
    },
    {
        'name': 'Romania',
        'currency': 'RON',
        'iso2': 'RO',
        'iso3': 'ROU',
        'languages': 'ro,hu,rom',
        'continent': 'EU'
    },
    {
        'name': 'Russia',
        'currency': 'RUB',
        'iso2': 'RU',
        'iso3': 'RUS',
        'languages': 'ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,av,sah,nog',
        'continent': 'EU'
    },
    {
        'name': 'Rwanda',
        'currency': 'RWF',
        'iso2': 'RW',
        'iso3': 'RWA',
        'languages': 'rw,en-RW,fr-RW,sw',
        'continent': 'AF'
    },
    {
        'name': 'Réunion',
        'currency': 'EUR',
        'iso2': 'RE',
        'iso3': 'REU',
        'languages': 'fr-RE',
        'continent': 'AF'
    },
    {
        'name': 'St. Barthélemy',
        'currency': 'EUR',
        'iso2': 'BL',
        'iso3': 'BLM',
        'languages': 'fr',
        'continent': 'NA'
    },
    {
        'name': 'St. Helena',
        'currency': 'SHP',
        'iso2': 'SH',
        'iso3': 'SHN',
        'languages': 'en-SH',
        'continent': 'AF'
    },
    {
        'name': 'St. Kitts & Nevis',
        'currency': 'XCD',
        'iso2': 'KN',
        'iso3': 'KNA',
        'languages': 'en-KN',
        'continent': 'NA'
    },
    {
        'name': 'St. Lucia',
        'currency': 'XCD',
        'iso2': 'LC',
        'iso3': 'LCA',
        'languages': 'en-LC',
        'continent': 'NA'
    },
    {
        'name': 'St. Martin',
        'currency': 'EUR',
        'iso2': 'MF',
        'iso3': 'MAF',
        'languages': 'fr',
        'continent': 'NA'
    },
    {
        'name': 'St. Pierre & Miquelon',
        'currency': 'EUR',
        'iso2': 'PM',
        'iso3': 'SPM',
        'languages': 'fr-PM',
        'continent': 'NA'
    },
    {
        'name': 'St. Vincent & Grenadines',
        'currency': 'XCD',
        'iso2': 'VC',
        'iso3': 'VCT',
        'languages': 'en-VC,fr',
        'continent': 'NA'
    },
    {
        'name': 'Samoa',
        'currency': 'WST',
        'iso2': 'WS',
        'iso3': 'WSM',
        'languages': 'sm,en-WS',
        'continent': 'OC'
    },
    {
        'name': 'San Marino',
        'currency': 'EUR',
        'iso2': 'SM',
        'iso3': 'SMR',
        'languages': 'it-SM',
        'continent': 'EU'
    },
    {
        'name': 'São Tomé & Príncipe',
        'currency': 'STD',
        'iso2': 'ST',
        'iso3': 'STP',
        'languages': 'pt-ST',
        'continent': 'AF'
    },
    {
        'name': 'Saudi Arabia',
        'currency': 'SAR',
        'iso2': 'SA',
        'iso3': 'SAU',
        'languages': 'ar-SA',
        'continent': 'AS'
    },
    {
        'name': 'Senegal',
        'currency': 'XOF',
        'iso2': 'SN',
        'iso3': 'SEN',
        'languages': 'fr-SN,wo,fuc,mnk',
        'continent': 'AF'
    },
    {
        'name': 'Serbia',
        'currency': 'RSD',
        'iso2': 'RS',
        'iso3': 'SRB',
        'languages': 'sr,hu,bs,rom',
        'continent': 'EU'
    },
    {
        'name': 'Seychelles',
        'currency': 'SCR',
        'iso2': 'SC',
        'iso3': 'SYC',
        'languages': 'en-SC,fr-SC',
        'continent': 'AF'
    },
    {
        'name': 'Sierra Leone',
        'currency': 'SLL',
        'iso2': 'SL',
        'iso3': 'SLE',
        'languages': 'en-SL,men,tem',
        'continent': 'AF'
    },
    {
        'name': 'Singapore',
        'currency': 'SGD',
        'iso2': 'SG',
        'iso3': 'SGP',
        'languages': 'cmn,en-SG,ms-SG,ta-SG,zh-SG',
        'continent': 'AS'
    },
    {
        'name': 'Sint Maarten',
        'currency': 'ANG',
        'iso2': 'SX',
        'iso3': 'SXM',
        'languages': 'nl,en',
        'continent': 'NA'
    },
    {
        'name': 'Slovakia',
        'currency': 'EUR',
        'iso2': 'SK',
        'iso3': 'SVK',
        'languages': 'sk,hu',
        'continent': 'EU'
    },
    {
        'name': 'Slovenia',
        'currency': 'EUR',
        'iso2': 'SI',
        'iso3': 'SVN',
        'languages': 'sl,sh',
        'continent': 'EU'
    },
    {
        'name': 'Solomon Islands',
        'currency': 'SBD',
        'iso2': 'SB',
        'iso3': 'SLB',
        'languages': 'en-SB,tpi',
        'continent': 'OC'
    },
    {
        'name': 'Somalia',
        'currency': 'SOS',
        'iso2': 'SO',
        'iso3': 'SOM',
        'languages': 'so-SO,ar-SO,it,en-SO',
        'continent': 'AF'
    },
    {
        'name': 'South Africa',
        'currency': 'ZAR',
        'iso2': 'ZA',
        'iso3': 'ZAF',
        'languages': 'zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr',
        'continent': 'AF'
    },
    {
        'name': 'South Georgia & South Sandwich Islands',
        'currency': null,
        'iso2': 'GS',
        'iso3': 'SGS',
        'languages': 'en',
        'continent': 'AN'
    },
    {
        'name': 'South Sudan',
        'currency': 'SSP',
        'iso2': 'SS',
        'iso3': 'SSD',
        'languages': 'en',
        'continent': 'AF'
    },
    {
        'name': 'Spain',
        'currency': 'EUR',
        'iso2': 'ES',
        'iso3': 'ESP',
        'languages': 'es-ES,ca,gl,eu,oc',
        'continent': 'EU'
    },
    {
        'name': 'Sri Lanka',
        'currency': 'LKR',
        'iso2': 'LK',
        'iso3': 'LKA',
        'languages': 'si,ta,en',
        'continent': 'AS'
    },
    {
        'name': 'Palestine',
        'currency': null,
        'iso2': 'PS',
        'iso3': 'PSE',
        'languages': 'ar-PS',
        'continent': 'AS'
    },
    {
        'name': 'Sudan',
        'currency': 'SDG',
        'iso2': 'SD',
        'iso3': 'SDN',
        'languages': 'ar-SD,en,fia',
        'continent': 'AF'
    },
    {
        'name': 'Suriname',
        'currency': 'SRD',
        'iso2': 'SR',
        'iso3': 'SUR',
        'languages': 'nl-SR,en,srn,hns,jv',
        'continent': 'SA'
    },
    {
        'name': 'Svalbard & Jan Mayen',
        'currency': 'NOK',
        'iso2': 'SJ',
        'iso3': 'SJM',
        'languages': 'no,ru',
        'continent': 'EU'
    },
    {
        'name': 'Swaziland',
        'currency': 'SZL',
        'iso2': 'SZ',
        'iso3': 'SWZ',
        'languages': 'en-SZ,ss-SZ',
        'continent': 'AF'
    },
    {
        'name': 'Sweden',
        'currency': 'SEK',
        'iso2': 'SE',
        'iso3': 'SWE',
        'languages': 'sv-SE,se,sma,fi-SE',
        'continent': 'EU'
    },
    {
        'name': 'Switzerland',
        'currency': 'CHF',
        'iso2': 'CH',
        'iso3': 'CHE',
        'languages': 'de-CH,fr-CH,it-CH,rm',
        'continent': 'EU'
    },
    {
        'name': 'Syria',
        'currency': 'SYP',
        'iso2': 'SY',
        'iso3': 'SYR',
        'languages': 'ar-SY,ku,hy,arc,fr,en',
        'continent': 'AS'
    },
    {
        'name': 'Tajikistan',
        'currency': 'TJS',
        'iso2': 'TJ',
        'iso3': 'TJK',
        'languages': 'tg,ru',
        'continent': 'AS'
    },
    {
        'name': 'Thailand',
        'currency': 'THB',
        'iso2': 'TH',
        'iso3': 'THA',
        'languages': 'th,en',
        'continent': 'AS'
    },
    {
        'name': 'Macedonia',
        'currency': 'MKD',
        'iso2': 'MK',
        'iso3': 'MKD',
        'languages': 'mk,sq,tr,rmm,sr',
        'continent': 'EU'
    },
    {
        'name': 'Timor-Leste',
        'currency': 'USD',
        'iso2': 'TL',
        'iso3': 'TLS',
        'languages': 'tet,pt-TL,id,en',
        'continent': 'OC'
    },
    {
        'name': 'Togo',
        'currency': 'XOF',
        'iso2': 'TG',
        'iso3': 'TGO',
        'languages': 'fr-TG,ee,hna,kbp,dag,ha',
        'continent': 'AF'
    },
    {
        'name': 'Tokelau',
        'currency': 'NZD',
        'iso2': 'TK',
        'iso3': 'TKL',
        'languages': 'tkl,en-TK',
        'continent': 'OC'
    },
    {
        'name': 'Tonga',
        'currency': 'TOP',
        'iso2': 'TO',
        'iso3': 'TON',
        'languages': 'to,en-TO',
        'continent': 'OC'
    },
    {
        'name': 'Trinidad & Tobago',
        'currency': 'TTD',
        'iso2': 'TT',
        'iso3': 'TTO',
        'languages': 'en-TT,hns,fr,es,zh',
        'continent': 'NA'
    },
    {
        'name': 'Tunisia',
        'currency': 'TND',
        'iso2': 'TN',
        'iso3': 'TUN',
        'languages': 'ar-TN,fr',
        'continent': 'AF'
    },
    {
        'name': 'Turkey',
        'currency': 'TRY',
        'iso2': 'TR',
        'iso3': 'TUR',
        'languages': 'tr-TR,ku,diq,az,av',
        'continent': 'AS'
    },
    {
        'name': 'Turkmenistan',
        'currency': 'TMT',
        'iso2': 'TM',
        'iso3': 'TKM',
        'languages': 'tk,ru,uz',
        'continent': 'AS'
    },
    {
        'name': 'Turks & Caicos Islands',
        'currency': 'USD',
        'iso2': 'TC',
        'iso3': 'TCA',
        'languages': 'en-TC',
        'continent': 'NA'
    },
    {
        'name': 'Tuvalu',
        'currency': 'AUD',
        'iso2': 'TV',
        'iso3': 'TUV',
        'languages': 'tvl,en,sm,gil',
        'continent': 'OC'
    },
    {
        'name': 'Uganda',
        'currency': 'UGX',
        'iso2': 'UG',
        'iso3': 'UGA',
        'languages': 'en-UG,lg,sw,ar',
        'continent': 'AF'
    },
    {
        'name': 'Ukraine',
        'currency': 'UAH',
        'iso2': 'UA',
        'iso3': 'UKR',
        'languages': 'uk,ru-UA,rom,pl,hu',
        'continent': 'EU'
    },
    {
        'name': 'United Arab Emirates',
        'currency': 'AED',
        'iso2': 'AE',
        'iso3': 'ARE',
        'languages': 'ar-AE,fa,en,hi,ur',
        'continent': 'AS'
    },
    {
        'name': 'UK',
        'currency': 'GBP',
        'iso2': 'GB',
        'iso3': 'GBR',
        'languages': 'en-GB,cy-GB,gd',
        'continent': 'EU'
    },
    {
        'name': 'Tanzania',
        'currency': 'TZS',
        'iso2': 'TZ',
        'iso3': 'TZA',
        'languages': 'sw-TZ,en,ar',
        'continent': 'AF'
    },
    {
        'name': 'U.S. Outlying Islands',
        'currency': 'USD',
        'iso2': 'UM',
        'iso3': 'UMI',
        'languages': 'en-UM',
        'continent': 'OC'
    },
    {
        'name': 'U.S. Virgin Islands',
        'currency': 'USD',
        'iso2': 'VI',
        'iso3': 'VIR',
        'languages': 'en-VI',
        'continent': 'NA'
    },
    {
        'name': 'US',
        'currency': 'USD',
        'iso2': 'US',
        'iso3': 'USA',
        'languages': 'en-US,es-US,haw,fr',
        'continent': 'NA'
    },
    {
        'name': 'Uruguay',
        'currency': 'UYU',
        'iso2': 'UY',
        'iso3': 'URY',
        'languages': 'es-UY',
        'continent': 'SA'
    },
    {
        'name': 'Uzbekistan',
        'currency': 'UZS',
        'iso2': 'UZ',
        'iso3': 'UZB',
        'languages': 'uz,ru,tg',
        'continent': 'AS'
    },
    {
        'name': 'Vanuatu',
        'currency': 'VUV',
        'iso2': 'VU',
        'iso3': 'VUT',
        'languages': 'bi,en-VU,fr-VU',
        'continent': 'OC'
    },
    {
        'name': 'Venezuela',
        'currency': 'VEF',
        'iso2': 'VE',
        'iso3': 'VEN',
        'languages': 'es-VE',
        'continent': 'SA'
    },
    {
        'name': 'Vietnam',
        'currency': 'VND',
        'iso2': 'VN',
        'iso3': 'VNM',
        'languages': 'vi,en,fr,zh,km',
        'continent': 'AS'
    },
    {
        'name': 'Wallis & Futuna',
        'currency': 'XPF',
        'iso2': 'WF',
        'iso3': 'WLF',
        'languages': 'wls,fud,fr-WF',
        'continent': 'OC'
    },
    {
        'name': 'Western Sahara',
        'currency': 'MAD',
        'iso2': 'EH',
        'iso3': 'ESH',
        'languages': 'ar,mey',
        'continent': 'AF'
    },
    {
        'name': 'Yemen',
        'currency': 'YER',
        'iso2': 'YE',
        'iso3': 'YEM',
        'languages': 'ar-YE',
        'continent': 'AS'
    },
    {
        'name': 'Zambia',
        'currency': 'ZMW',
        'iso2': 'ZM',
        'iso3': 'ZMB',
        'languages': 'en-ZM,bem,loz,lun,lue,ny,toi',
        'continent': 'AF'
    },
    {
        'name': 'Zimbabwe',
        'currency': 'ZWL',
        'iso2': 'ZW',
        'iso3': 'ZWE',
        'languages': 'en-ZW,sn,nr,nd',
        'continent': 'AF'
    },
    {
        'name': 'Åland Islands',
        'currency': 'EUR',
        'iso2': 'AX',
        'iso3': 'ALA',
        'languages': 'sv-AX',
        'continent': 'EU'
    }
];

