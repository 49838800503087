import { deviceIsiOS, isIpadAtLeastIOS13 } from '@novo/platform-common/util/browser-check';
import { AppleCordovaLoginProvider, AppleLoginProvider, FacebookCordovaLoginProvider, FacebookLoginProvider, GoogleCordovaLoginProvider, GoogleLoginProvider, SocialLoginServiceConfig, SocialLoginServiceConfigItem } from '@novo/social-login';

export const provideSocialLoginConfig = (window: Window | undefined): SocialLoginServiceConfig => {
    const googleClientId = '157014334852-jhoahmvi3i2bu3rs719su1e5e2mq5aeg.apps.googleusercontent.com';
    const facebookClientId = '1435281556744167';

    if (window && window['cordova']) {
        const providers: SocialLoginServiceConfigItem[] = [
            { id: 'google', provider: new GoogleCordovaLoginProvider(googleClientId) },
            { id: 'facebook', provider: new FacebookCordovaLoginProvider() }
        ];
        if (deviceIsiOS(13, true) || isIpadAtLeastIOS13()) {
            // Apple only supports auth starting from iOS 13 devices
            providers.push({ id: 'apple', provider: new AppleCordovaLoginProvider() });
        }
        return new SocialLoginServiceConfig(providers);
    } else {
        const appleServicesId = 'com.novo-learning.player';
        return new SocialLoginServiceConfig([
            { id: 'google', provider: new GoogleLoginProvider(googleClientId) },
            { id: 'facebook', provider: new FacebookLoginProvider(facebookClientId) },
            { id: 'apple', provider: new AppleLoginProvider(appleServicesId) }
        ]);
    }
};
