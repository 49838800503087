import { LocationStrategy } from '@angular/common';
import { Component, Inject, NgZone, OnInit, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@novo/platform-common/services/auth';
import { AuthenticationStorage } from '@novo/platform-common/services/auth/auth-storage/auth.storage.service';
import { WINDOW } from '@novo/platform-common/util/ssr/ssr-utils.module';
import { NovoTranslationService, TranslationEditorInjector } from '@novo/platform-translations';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as smoothscroll from 'smoothscroll-polyfill';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {

    isPopState = false;

    constructor(
        private router: Router,
        private locStrat: LocationStrategy,
        private zone: NgZone,
        private translationEditor: NovoTranslationService,
        private translationInjector: TranslationEditorInjector,
        private auth: AuthService,
        private authStorage: AuthenticationStorage,
        @Optional() @Inject(WINDOW) private window: Window | undefined
    ) {
        if (this.window) {
            smoothscroll.polyfill(); // only execute on client rendering
        }
    }

    ngOnInit(): void {
        this.locStrat.onPopState(() => {
            this.isPopState = true;
        });

        this.router.events.subscribe(event => {
            // Scroll to top if accessing a page, not via browser history stack
            if (event instanceof NavigationEnd && !this.isPopState && this.window) {
                this.window.scrollTo(0, 0);
                this.isPopState = false;
            }

            // Ensures that isPopState is reset
            if (event instanceof NavigationEnd) {
                this.isPopState = false;
                this.zone.runOutsideAngular(() => {
                    //  this.changeSvgPaths();
                });

            }
        });

        // Watch for authentication user changes and enable/disable
        // the translation editor.
        // @todo: This might not be the ideal location for this piece of code...
        this.authStorage.user$.pipe(
            map(u => (u == null) ? false : this.auth.hasPermission('system', '', 'translation_edit', u)),
            distinctUntilChanged()
        ).subscribe(loggedIn => {
            this.translationEditor.enabled$.next(loggedIn);
            if (loggedIn) {
                this.translationInjector.injectEditor(true);
            } else {
                this.translationInjector.destroyEditor();
            }
        });

    }

}
