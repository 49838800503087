<app-wrapped-page *ngIf="localizedMedia$ | async as media">

    <div class="grid-row hero">

        <div class="hero__content">
            <div>
                <div class="hero__title">Hi, we're Novo</div>
                <h1 class="hero__text">Improve your learners’ spoken language skills through our AI based
                    speech analysis</h1>
            </div>
            <!-- <a class="button--xl" mat-raised-button color="primary" [routerLink]="['/onboarding']">
                <span novoTranslate="PAGES.LANDING.HERO.C2A"></span>
            </a> -->
            <div class="hero__actions">
            </div>
        </div>
        <div class="hero__image">
            <img alt="Smiling person showing the Novo Play app" [src]="media.heroImage" />
        </div>

    </div>

    <section class="mission">
        <div class="mission__image">
            <img alt="Children in Vietnam playing with the Novo Play app and various Novo Play app screenshots"
                src="assets/mission.jpg" />
        </div>
        <div class="mission__content">
            <h3>What we do</h3>
            <div class="mission__text">
                Novo develops a proprietary ASR algorithm that is specifically capable of
                detecting what has been said and how it has been said. This empowers learners to speak more
                confidentally,
                fluently, and even accent-free. The Novo ASR is also applicable for learning how to read through
                pronunciation assessment.

            </div>
            <div class="mission__text_2">
                <h3>USPs</h3>
                <ul>
                    <li>Multiple languages: English, Dutch, Mandarin, French, German, Spanish</li>
                    <li>High accuracy</li>
                    <li>Applicable for pronunciation improvement and learning how to read</li>
                    <li>Configurable per level</li>
                    <li>Different types of feedback</li>
                    <li>Pronunciation feedback tailored to learner level
                    <li>Research based from Radboud CLST</li>
                </ul>
            </div>

        </div>
    </section>



    <section style="margin: 12px 0 0 0;">
        <!--  <app-color-blob class="left-blob" shape="1" color="orange"></app-color-blob> -->

        <!--<div class="header">
            <div class="mat-display-2">The Novo Learning Experience</div>
        </div> -->

        <div class="grid-row learner-ubrs">
            <div class="ubr">
                <div class="ubr__image"
                    style="background: #FC5275; --fa-primary-opacity: 0.6; --fa-secondary-opacity: 1;">
                    <!-- <fa-icon style="font-size: 36px;" [icon]="['fad', 'magic']"></fa-icon> -->
                    <app-animated-logo></app-animated-logo>
                    <!-- <video autoplay loop muted playsinline>
                        <source src="assets/videos/output.mp4" type="video/mp4">
                    </video> -->
                </div>
                <div class="ubr__text">
                    <h3>Interactive learning</h3>
                    Cater to different learning styles
                </div>
            </div>
            <div class="ubr">
                <div class="ubr__image" style="background: #636FD2">
                    <fa-icon style="font-size: 36px;" [icon]="['fad', 'comments']"></fa-icon>
                </div>
                <div class="ubr__text">
                    <h3>Increased motivation</h3>
                    Help learners speak and read confidently
                </div>
            </div>
            <div class="ubr">
                <div class="ubr__image"
                    style="background: #FDC05F; --fa-secondary-opacity: 1; --fa-primary-opacity: 0.7;">
                    <fa-icon style="font-size: 36px;" [icon]="['fad', 'file-certificate']"></fa-icon>
                </div>
                <div class="ubr__text">
                    <h3>Improve learning outcomes</h3>
                    Give learners detailed feedback on their pronunciation
                </div>
            </div>
        </div>
    </section>

    <section class="mission">
        <div class="mission__content">
            <h2>State of the art Rest/WS API</h2>
            <div class="mission__text_2">

                <h3>Technical properties</h3>
                <ul>
                    <li>always available
                    <li>secure
                    <li>cloud based
                    <li>high quality
                    <li>direct feedback
                    <li>multi region
                </ul>
                <h3>Integration Options</h3>
                <ul>
                    <li>Fully documented (openAPI)
                    <li>plugins for popular e-learning environments (Moodle/Wordpress/etc) and even plain HTML pages
                </ul>
            </div>
        </div>
        <div class="mission__text">
            A trial account lets you evaluate/test the features of our Speech Analysis service with no cost or contractual obligations.
        </div>
        <a [href]="environment.get('trialAccountUrl')" class="button--xl" mat-raised-button color="primary">
            <span>Request a trial account</span>
        </a>
    </section>



    <section style="margin: 0;">
        <div style="position: relative; overflow: hidden;">

            <svg #svg style="width: 100%; min-width: 780px;" width="1438" height="710" viewBox="0 0 1438 710"
                fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g>
                    <rect width="1438" height="710" fill="white" />
                    <path
                        d="M1358.37 89.2419C941.702 130.742 -152.765 -157.271 -122.334 148.256C-91.9032 453.782 -540.463 749.192 -123.799 707.692C72.6775 688.123 338.626 653.618 590.435 661.875C872.629 671.124 1139.06 696.107 1328.13 620.429C1685.91 477.224 1775.03 47.7396 1358.37 89.2397L1358.37 89.2419Z"
                        fill="url(#pattern0)" fill-opacity="0.49" />
                    <path
                        d="M1358.37 89.2419C941.702 130.742 -152.765 -157.271 -122.334 148.256C-91.9032 453.782 -540.463 749.192 -123.799 707.692C72.6775 688.123 338.626 653.618 590.435 661.875C872.629 671.124 1139.06 696.107 1328.13 620.429C1685.91 477.224 1775.03 47.7396 1358.37 89.2397L1358.37 89.2419Z"
                        fill="white" fill-opacity="0.79" />
                </g>
                <defs>
                    <pattern id="pattern0" patternContentUnits="userSpaceOnUse" width="1" height="1">
                        <image id="image0" width="1470" height="787" transform="scale(1.2)"
                            xlink:href="assets/img/whitepaper-bg.jpg">
                        </image>
                    </pattern>
                </defs>
            </svg>
            <div
                style="position: absolute; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; top: 0px; left: 0px;">
                <div style="text-align: center; max-width: 700px;">
                    <h3 style="font-weight: bold;  color: rgba(0,0,0,0.68)"
                        novoTranslate="PAGES.LANDING.CASE_STUDY.TITLE">
                    </h3>
                    <div class="mat-headline" style="font-size: 36px; color: rgba(0,0,0,0.87)"
                        novoTranslate="PAGES.LANDING.CASE_STUDY.TEXT">
                    </div>
                    <button class="button--xl" mat-raised-button color="primary" (click)="downloadReport()">
                        {{'PAGES.LANDING.CASE_STUDY.DOWNLOAD_REPORT' | novoTranslate}}
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div style="padding: 0 8px; display: flex; justify-content: center; margin-top: -90px; margin-bottom: 20px;">
        <app-partner-card style="height: 200px; width: 100%; max-width: 500px;" class="partner-card" category="language"
            color="purple">
            <span title novoTranslate="PAGES.LANDING.PARTNER.TITLE"></span>
            <ng-container logos>
                <a href="https://www.ailat.org" rel="noopener">
                    <img alt="AI-based Language Assessment (AILAT) logo"
                        style="max-width: 94px; width: calc(100% - 16px); margin: 0 8px;"
                        src="assets/logos/ailat-logo.png" />
                </a>
                <a href="https://www.ru.nl/cls/@1093235/linguistics-research-radboud-university-number-1/"
                    rel="noopener">
                    <img alt="Radboud University (RU) logo"
                        style="max-width: 286px; width: calc(100% - 16px); margin: 0 8px;" src="assets/logos/ru.jpg" />
                </a>
            </ng-container>
        </app-partner-card>
    </div>

    <!-- <section>
        <app-color-blob class="right-blob" shape="1" color="purple"></app-color-blob>
        <div class="header">
            <span class="mat-display-2" novoTranslate="PAGES.LANDING.YOUR_ORGANIZATION.TITLE">For Your
                Organization</span>
        </div>
        <div class="grid-row">
            <div class="ubr">
                <div class="ubr__image" style="background-image: url('assets/img/icons/scalable-2.svg')"></div>
                <div class="ubr__text">
                    <h3 novoTranslate="PAGES.LANDING.YOUR_ORGANIZATION.SCALABLE.TITLE"></h3>
                    {{ 'PAGES.LANDING.YOUR_ORGANIZATION.SCALABLE.TEXT' | novoTranslate}}
                </div>
            </div>
            <div class="ubr">
                <div class="ubr__image" style="background-image: url('assets/img/icons/monitor-2.svg')">
                </div>
                <div class="ubr__text">
                    <h3 novoTranslate="PAGES.LANDING.YOUR_ORGANIZATION.MONITOR.TITLE"></h3>
                    {{ 'PAGES.LANDING.YOUR_ORGANIZATION.MONITOR.TEXT' | novoTranslate}}
                </div>
            </div>
            <div class="ubr">
                <div class="ubr__image" style="background-image: url('assets/img/icons/tailor-2.svg')"></div>
                <div class="ubr__text">
                    <h3 novoTranslate="PAGES.LANDING.YOUR_ORGANIZATION.TAILOR.TITLE"></h3>
                    {{ 'PAGES.LANDING.YOUR_ORGANIZATION.TAILOR.TEXT' | novoTranslate}}
                </div>
            </div>
        </div>
    </section> -->

    <section>
        <div class="header">
            <div class="mat-display-2" novoTranslate="PAGES.LANDING.TESTIMONIALS.TITLE"></div>
        </div>
        <div class="grid-row">
            <div class="testimonial">
                <div class="testimonial__image" style="background-image: url('assets/img/irma.jpg')">
                </div>
                <div class="testimonial__text">
                    <span novoTranslate="PAGES.LANDING.TESTIMONIALS.LEARNER_1"></span>
                    <div class="testimonial__person">
                        <img alt="Astria Nur Fauziah" style="height: 54px; margin-right: 8px;"
                            src="assets/logos/uni-logo-unes.png" />
                        <div>
                            <div>Astria Nur Fauziah</div>
                            <div style="font-style: italic;">Universitas Negeri Semarang</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial">
                <div class="testimonial__image" style="background-image: url('assets/img/gabriella.jpg')"></div>
                <div class="testimonial__text">
                    <span novoTranslate="PAGES.LANDING.TESTIMONIALS.LEARNER_2"></span>
                    <div class="testimonial__person">
                        <img alt="Gabriella Luciano" style="height: 62px; margin-right: 8px;"
                            src="assets/logos/uni-logo-manado.png" />
                        <div>
                            <div>Gabriella Luciano</div>
                            <div style="font-style: italic;">Universitas Negeri Manado</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial">
                <div class="testimonial__image" style="background-image: url('assets/img/mariska.jpeg')"></div>
                <div class="testimonial__text">
                    <span>"The collaboration between Uitgeverij Deviant and Novo Language combines teaching methods and
                        speech recognition, leading to a unique cooperation and new insights."</span>
                    <div class="testimonial__person">
                        <img alt="Mariska Wiersma" style="height: 54px; margin-right: 8px;"
                            src="assets/logos/logo-deviant.jpeg" />
                        <div>
                            <div>Mariska Wiersma</div>
                            <div style="font-style: italic;">Uitgeverij Deviant</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial">
                <div class="testimonial__image" style="background-image: url('assets/img/eva.jpg')"></div>
                <div class="testimonial__text">
                    <span novoTranslate="PAGES.LANDING.TESTIMONIALS.LEARNER_4"></span>
                    <div class="testimonial__person">
                        <img alt="Eva Thu" style="height: 62px; margin-right: 8px;" src="assets/logos/de-heus.png" />
                        <div>
                            <div>Eva Thu</div>
                            <div style="font-style: italic;">De Heus - Finance Department</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</app-wrapped-page>