import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-color-blob',
    template: `
        <img alt="" width="100%" [src]="imageUrl" />
    `,
    styles: [`
        :host {
            display: block;
        }

        :host img {
            display: block;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorBlobComponent implements OnChanges {

    @Input() color = 'green';
    @Input() shape = 0;

    imageUrl: string;

    ngOnChanges(changes: SimpleChanges) {
        this.imageUrl = `assets/img/color-blob-${this.shape}.${this.color}.svg`;
    }

}
