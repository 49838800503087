import { Injectable, InjectionToken } from '@angular/core';

export const ERROR_LOGGER_TOKEN = new InjectionToken<ErrorLoggerService>('ErrorLogger');

@Injectable()
export abstract class ErrorLoggerService {

    abstract captureException(error: string | Error | ErrorEvent, data?: { extra?: {} }): void;

    abstract captureMessage(message: string, data?: { extra?: {} }): void;

    abstract setUserContext(id: string);

    abstract clearUserContext();
}
