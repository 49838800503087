import { Type } from 'class-transformer';
import { Background } from './background';
import { Content, ContentModel } from './content';
import { Group } from './group';
import { AssignableUnit } from './learning-track';
import { Prerequisite } from './prerequisite';
import { TrackCertificate } from './track-certificate';
import { getRandomId, initConstructor, initialize, LocaleCompare, serializeType } from './utilities';

export class AssignmentTemplate {
    public name = '';
    public units: AssignableUnit[] = [];
    public startOffset = 0;
    public dueOffset?: number = undefined;
    public identifier: string;
    public prerequisites?: Prerequisite[] = undefined;

    static initialize(json: any): AssignmentTemplate {
        const result = initialize(AssignmentTemplate, json);
        if (json.units) {
            result.units = json.units.map(x => AssignableUnit.initialize(x));
        }
        if (json.identifier == null) {
            result.identifier = getRandomId();
        }
        return result;
    }

    constructor() {
        this.identifier = getRandomId();
    }
}

export interface LearningTrackTemplatePricing {
    forSale: boolean;
    forFree?: boolean;
    override: boolean;
    baseCurrency: string;
    prices: {
        [key: string]: number;
    };
    inheritedFrom?: { identifier: string, name: string };
}

export interface LearningTrackTemplateModel extends ContentModel {
    name: string;
    pricing?: LearningTrackTemplatePricing;
    assignments: AssignmentTemplate[];
    coverImage: Background;
    certificate?: TrackCertificate;
}


/**
 * A learning track template
 */
export class LearningTrackTemplate extends Content {
    name: string;
    pricing?: LearningTrackTemplatePricing;
    @Type(serializeType(Group)) public owner: Group;

    @Type(serializeType(AssignmentTemplate)) assignments: AssignmentTemplate[];
    @Type(serializeType(Background)) coverImage: Background;
    @Type(serializeType(TrackCertificate)) certificate?: TrackCertificate;

    static initialize(json: any): LearningTrackTemplate {
        const content = super.initializeContent(json);

        const assignments = (json.assignments) ? json.assignments.map(x => AssignmentTemplate.initialize(x)) : [];
        let coverImage: Background;
        if (json.coverImage) {
            coverImage = Background.initialize(json.coverImage);
        } else {
            coverImage = new Background();
        }

        return new LearningTrackTemplate({
            ...content,
            name: json.name,
            assignments,
            coverImage,
            pricing: json.pricing,
            certificate: json.certificate && TrackCertificate.initialize(json.certificate)

        });
    }

    static sort(templateA: { name?: string }, templateB: { name?: string }): number {
        const a = templateA.name;
        const b = templateB.name;
        return LocaleCompare.compare(a, b);
    }

    get forSale(): boolean {
        if (this.shared && this.shared.pricing && this.shared.pricing.override) {
            // Override
            return this.shared.pricing.forSale;
        }
        return this.pricing != null && this.pricing.forSale;
    }

    constructor(data: LearningTrackTemplateModel) {
        super(data);
        initConstructor<LearningTrackTemplateModel>(this, data);
    }
}
