/// <reference types="@types/googlemaps" />
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormField } from '@novo/platform-common/models';
import { ParseAPIService } from '@novo/platform-common/services/api/parse-api.service';
import { NEVER } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPageComponent {

    readonly form: FormGroup;
    sending = false;

    constructor(
        private api: ParseAPIService,
        private fb: FormBuilder,
        private snackbar: MatSnackBar
    ) {
        this.form = this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.email],
            message: ['']
        });
    }

    submitForm() {
        if (!this.form.valid) {
            return;
        }
        const fields: FormField[] = [
            { label: 'Email address', id: 'email', value: this.form.controls['email'].value },
            { label: 'Name', id: 'name', value: this.form.controls['name'].value },
            { label: 'Message', id: 'message', value: this.form.controls['message'].value },
            { label: 'Context', id: 'context', value: 'Sent through contact form: not a trial account request' },
        ];

        this.sending = true;
        this.api.trialRequestEmail(fields).pipe(
            catchError(_ => {
                this.snackbar.open('Oops! something went wrong while handling your request. Please try again.', 'OK', {
                    panelClass: ['form-snackbar', 'form-snackbar-warn']
                });
                this.sending = false;
                return NEVER;
            }),
        ).subscribe(_ => {
            this.snackbar.open('Thank you for your message!', 'OK', { panelClass: ['form-snackbar'] });
            this.sending = false;
            this.form.setValue({
                email: null,
                name: null,
                message: null
            }, { emitEvent: false });
        });
    }
}
