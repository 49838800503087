import { Injectable } from '@angular/core';
import { SessionTokenService, SESSION_TOKEN_KEY } from './session-token.service';

/**
 * Responsible for storing the session token in local storage
 */
@Injectable()
export class LocalStorageSessionTokenService implements SessionTokenService {

    constructor() { }

    get(): string | undefined {
        const sessionToken = window.localStorage.getItem(SESSION_TOKEN_KEY);
        return (sessionToken !== '' && sessionToken != null) ? sessionToken : undefined;
    }

    set(sessionToken: string) {
        if (!sessionToken) {
            this.remove();
            return;
        }
        window.localStorage.setItem(SESSION_TOKEN_KEY, sessionToken);
    }

    remove() {
        window.localStorage.removeItem(SESSION_TOKEN_KEY);
    }

}
