import { BaseLoginProvider } from '../entities/base-login-provider';
import { LoginProviderId } from '../entities/login-provider';
import { SocialUser } from '../entities/user';
import { LoginOpt } from '../social-login.service';

declare var plugins: any;

export class GoogleCordovaLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: LoginProviderId = 'google';

    constructor(private clientId: string, private opt: LoginOpt = { scope: 'email' }) {
        super();

        if (plugins == null || plugins.googleplus == null) {
            throw new Error('Cordova googleplus plugin not available');
        }
    }

    initialize(): Promise<void> {
        return new Promise((resolve, _) => {
            this._readyState.next(true);
            resolve();
        });
    }

    async getLoginStatus(): Promise<SocialUser> {
        await this.onReady();

        return new Promise((resolve, reject) => {
            plugins.googleplus.trySilentLogin({
                scopes: 'profile email',
                webClientId: this.clientId,
                offline: true
            }, (result: any) => {
                const user = this._getUser(result);
                resolve(user);
            }, (err) => {
                reject('No user is currently logged in');
            });
        });
    }

    async signIn(opt?: LoginOpt): Promise<SocialUser> {
        await this.onReady();

        return new Promise((resolve, reject) => {
            plugins.googleplus.login({
                scopes: 'profile email',
                webClientId: this.clientId,
                offline: true
            }, (result: any) => {
                const user = this._getUser(result);
                resolve(user);
            }, (err) => {
                reject(err);
            });
        });
    }

    async signOut(revoke?: boolean): Promise<any> {
        await this.onReady();
        plugins.googleplus.logout();
    }

    _getUser(response?: any): SocialUser {
        const user: SocialUser = new SocialUser();
        user.id = response.userId;
        user.name = response.displayName;
        user.email = response.email;
        user.photoUrl = response.imageUrl;
        user.firstName = response.givenName;
        user.lastName = response.familyName;
        user.accessToken = response.accessToken;
        user.idToken = response.idToken;
        user.authorizationCode = response.serverAuthCode;
        return user;
    }

    isCriticalError(err: number): boolean {
        // 12501: pop-up closed (by user?)
        return err !== 12501;
    }
}
