import { BaseLoginProvider } from '../entities/base-login-provider';
import { LoginProviderId } from '../entities/login-provider';
import { SocialUser } from '../entities/user';
import { LoginOpt } from '../social-login.service';

declare var gapi: any;

export class GoogleLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: LoginProviderId = 'google';

    protected auth2: any; // gapi.auth2.GoogleAuth;

    constructor(private clientId: string, private opt: LoginOpt = { scope: 'email' }) {
        super();
    }

    async initialize(): Promise<void> {
        this.auth2 = await new Promise((resolve) => {
            this.loadScript(GoogleLoginProvider.PROVIDER_ID, 'https://apis.google.com/js/api.js', () => {
                gapi.load('auth2', () => {
                    resolve(gapi.auth2.init({
                        ...this.opt,
                        client_id: this.clientId
                    }));
                });
            });
        });
        this._readyState.next(true);
    }

    async getLoginStatus(): Promise<SocialUser> {
        await this.onReady();
        if (this.auth2.isSignedIn.get()) {
            return this._getUser();
        } else {
            throw new Error('No user is currently logged in');
        }
    }

    async signIn(opt?: LoginOpt): Promise<SocialUser> {
        await this.onReady();
        const offlineAccess: boolean = (opt && opt.offline_access) || (this.opt && this.opt.offline_access);
        const promise = !offlineAccess ? this.auth2.signIn(opt) : this.auth2.grantOfflineAccess(opt);
        const response = await promise;
        return this._getUser(response);
    }

    async signOut(revoke?: boolean): Promise<any> {
        await this.onReady();
        const signoutPromise = revoke ? this.auth2.disconnect() : this.auth2.signOut();
        await signoutPromise;
    }

    _getUser(response?: any): SocialUser {
        const user: SocialUser = new SocialUser();
        const profile = this.auth2.currentUser.get().getBasicProfile();
        const token = this.auth2.currentUser.get().getAuthResponse(true).access_token;
        const backendToken = this.auth2.currentUser.get().getAuthResponse(true).id_token;

        user.id = profile.getId();
        user.name = profile.getName();
        user.email = profile.getEmail();
        user.photoUrl = profile.getImageUrl();
        user.firstName = profile.getGivenName();
        user.lastName = profile.getFamilyName();
        user.accessToken = token;
        user.idToken = backendToken;

        if (response && response.code) {
            user.authorizationCode = response.code;
        }

        return user;
    }

    isCriticalError(err: { error?: string }): boolean {
        // Non critical: popup_closed_by_user and popup_closed_by_browser
        return !err || !err.error || !err.error.startsWith('popup_closed_by_');
    }

}
