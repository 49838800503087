import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { WINDOW } from '@novo/platform-common/util/ssr/ssr-utils.module';
import { Request } from 'express';

/* SVG Fixer
    *
       * Fixes references to inline SVG elements when the < base > tag is in use.
       * Firefox won't display SVG icons referenced with
    * `<svg><use xlink:href="#id-of-icon-def"></use></svg>` when the < base > tag is on the page.
       *
       * More info:
       * - http://stackoverflow.com/a/18265336/796152
       * - http://www.w3.org/TR/SVG/linking.html
       *
       * One would think that setting the`xml:base` attribute fixes things,
       * but that is being removed from the platform: https://code.google.com/p/chromium/issues/detail?id=341854
       * https://gist.github.com/leonderijke/c5cf7c5b2e424c0061d2
       * /
*/
@Injectable()
export class SvgUtil {

    constructor(
        @Optional() @Inject(WINDOW) private window: Window | undefined,
        @Optional() @Inject(REQUEST) private request: Request | undefined,
    ) { }

    /**
     * Resolves BaseUrl with support for both client and server-sider rendering
     */
    private resolveBaseUrl(): string {
        if (this.window) {
            return this.window.location.pathname
                .replace(this.window.location.hash, '')
                .replace(/\/$/gi, '');
        }
        if (this.request) {
            const matches = this.request.url.match(/([^\?\#]+)(\?.*)?(\#.*)?/);
            return (matches && matches[1]) ? matches[1].replace(/\/$/gi, '') : '';
        }

        throw new Error('[SvgUtil] No window or request provider');
    }

    /**
     * Fix SVG
     *
     * @param root
     */
    fix(root: Element) {
        const baseUrl = this.resolveBaseUrl();
        /**
         *  Find all `use` elements
         */
        [].slice
            .call(root.querySelectorAll('use'))

            /**
             * Filter out all elements whose namespaced `href` attribute doesn't
             * start with `#` (i.e. all non-relative IRI's)
             *
             * Note: we're assuming the `xlink` prefix for the XLink namespace!
             */
            .filter(function (element) {
                return (
                    element
                        .getAttributeNS('http://www.w3.org/1999/xlink', 'href')
                        .indexOf('#') !== -1
                );
            })

            /**
             * Prepend `window.location` to the namespaced `href` attribute value,
             * in order to make it an absolute IRI
             *
             * Note: we're assuming the `xlink` prefix for the XLink namespace!
             */
            .forEach(function (element: HTMLElement) {
                const oldHref = element.getAttributeNS(
                    'http://www.w3.org/1999/xlink',
                    'href'
                ) as string;
                const idPart = oldHref.slice(oldHref.indexOf('#'));
                element.setAttributeNS(
                    'http://www.w3.org/1999/xlink',
                    'xlink:href',
                    baseUrl + idPart
                );
            });

        [].slice
            .call(root.querySelectorAll('svg'))
            .filter(function (element: SVGElement) {
                return (
                    element.style.fill && element.style.fill.indexOf('url(') !== -1
                );
            })
            .forEach(function (element: SVGElement) {
                const attrVal = element.style.fill || '';
                element.style.fill = `url(${baseUrl}${attrVal.slice(
                    attrVal.indexOf('#')
                )}`;
            });

        this.changeSvgAttributes('fill', baseUrl, root);
        this.changeSvgAttributes('mask', baseUrl, root);
        this.changeSvgAttributes('clip-path', baseUrl, root);
    }

    private changeSvgAttributes(attr, baseUrl, root) {
        [].slice
            .call(root.querySelectorAll(`[${attr}]`))
            .filter(function (element) {
                return element.getAttribute(attr).indexOf('url(') !== -1;
            })
            .forEach(function (element) {
                const attrVal = element.getAttribute(attr);
                element.setAttribute(
                    attr,
                    `url(${baseUrl}${attrVal.slice(attrVal.indexOf('#'))}`
                );
            });
    }
}
