import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxMdComponent } from './ngx-md.component';
import { NgxMdConfig } from './ngx-md.config';
import { NgxMdService } from './ngx-md.service';

@NgModule({
  imports: [CommonModule],
  declarations: [NgxMdComponent],
  providers: [NgxMdService],
  exports: [NgxMdComponent],
})
export class NgxMdModule {
  public static forRoot(): ModuleWithProviders<NgxMdModule> {
    return {
      ngModule: NgxMdModule,
      providers: [NgxMdConfig],
    };
  }
}
