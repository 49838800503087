import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Environment as IEnvironment } from '../../../environments/environment.interface';

@Injectable()
export class Environment {

    /**
     * Returns the requested key from environment
     *
     * @param key
     */
    get<K extends keyof IEnvironment>(key: K): IEnvironment[K] {
        return environment[key];
    }
}
