<button [disabled]="disabled" *ngIf="type == 'google'" mat-raised-button type="button">
    <img src="assets/img/google-btn-icon.png" />
    <span *ngIf="state !== 'busy'">{{ label | translate:{provider:'Google'} }}</span>
    <mat-spinner *ngIf="state === 'busy'" [diameter]="24" strokeWidth="3"></mat-spinner>
    <i *ngIf="state === 'linked'" class="material-icons linked">check</i>
</button>

<button [disabled]="disabled" class="facebook-btn" *ngIf="type == 'facebook'" mat-button type="button">
    <img src="assets/img/facebook-btn-icon.png" />
    <span *ngIf="state !== 'busy'">{{ label | translate:{provider:'Facebook'} }}</span>
    <mat-spinner *ngIf="state === 'busy'" class="light-spinner" [diameter]="24" strokeWidth="3"></mat-spinner>
    <i *ngIf="state === 'linked'" class="material-icons linked">check</i>
</button>

<button [disabled]="disabled" class="apple-btn" *ngIf="type == 'apple'" mat-raised-button type="button">
    <img src="assets/img/apple-btn-icon.png" />
    <span *ngIf="state !== 'busy'">{{ label | translate:{provider:'Apple'} }}</span>
    <mat-spinner *ngIf="state === 'busy'" [diameter]="24" strokeWidth="3"></mat-spinner>
    <i *ngIf="state === 'linked'" class="material-icons linked">check</i>
</button>

<button [disabled]="disabled" *ngIf="type == 'email'" mat-raised-button type="button">
    <fa-icon [icon]="emailIcon"></fa-icon>
    <span *ngIf="state !== 'busy'">{{ label | translate:{provider:'Email'} }}</span>
    <mat-spinner *ngIf="state === 'busy'" [diameter]="24" strokeWidth="3"></mat-spinner>
    <i *ngIf="state === 'linked'" class="material-icons linked">check</i>
</button>