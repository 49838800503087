import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { provideSocialLoginConfig } from '@novo/platform-common/factories/social-login/social-login.factory';
import { ParseAPIService } from '@novo/platform-common/services/api/parse-api.service';
import { ConsoleErrorLoggerService, ErrorLoggingModule, SentryErrorLoggerService, SENTRY_CONFIG } from '@novo/platform-common/services/error-logger';
import { SsrUtilsModule, TransferHttpCacheModule, WINDOW } from '@novo/platform-common/util/ssr';
import { NovoTranslationApi, NovoTranslationModule, TRANSLATION_PLATFORM } from '@novo/platform-translations';
import { SocialLoginModule, SocialLoginServiceConfig } from '@novo/social-login';
import { CookieService } from 'ngx-cookie-service';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxWebstorageConfiguration } from 'ngx-webstorage/lib/config';
import * as packageJson from '../../package.json';
import { environment } from '../environments/environment';
import { AppRoutesModule } from './app-routes.module';
import { AppComponent } from './app.component';
import { SetMediaBaseUrl } from './app.fns';
import { MaterialModule } from './material.module';
import { PagesModule } from './pages/pages.module';
import { Environment, ServicesModule } from './services';
import { RedirectModule } from './shared/components/redirect/redirect.module';
import { SharedModule } from './shared/shared.module';

const WEBSTORAGE_CONFIG: NgxWebstorageConfiguration = {
    prefix: 'novolearning',
    separator: '.'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        ErrorLoggingModule.forRoot({ logger: environment.remoteLoggingEnabled ? SentryErrorLoggerService : ConsoleErrorLoggerService }),
        NgxWebstorageModule.forRoot(WEBSTORAGE_CONFIG),
        BrowserModule.withServerTransition({
            appId: `novo-learning-com`
        }),
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgxPageScrollCoreModule.forRoot({ /* custom settings here */ }),
        NgxPageScrollModule,
        SharedModule,
        ServicesModule,
        PagesModule,
        TranslateModule.forRoot(NovoTranslationModule.ngxSettings()),
        NovoTranslationModule.forRoot({
            api: {
                provide: NovoTranslationApi,
                useClass: ParseAPIService
            }
        }),
        SsrUtilsModule,
        SocialLoginModule.forRoot(),
        AppRoutesModule,
        RedirectModule,
        TransferHttpCacheModule // Make sure this module is loaded as last
    ],
    providers: [
        Title,
        Meta,
        Environment,
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: SetMediaBaseUrl,
            deps: [Environment],
            multi: true
        },
        {
            provide: SocialLoginServiceConfig,
            useFactory: provideSocialLoginConfig,
            deps: [WINDOW]
        },
        { provide: TRANSLATION_PLATFORM, useValue: 'novo-landing-page' },
        { provide: SENTRY_CONFIG, useValue: { dsn: 'https://9f5bd38da5fa45288f4c4452b2079df3@sentry.io/1785667', release: `${packageJson.version}-${environment.env}` } },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
