import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SocialLoginService } from './social-login.service';



@NgModule({
    imports: [
        CommonModule
    ]
})
export class SocialLoginModule {

    /**
     * Configure the SocialLoginModule with a custom configProvider
     * to enable/disable/setup the social services.
     *
     * configProvider can either be a ValueProvider or FactoryProvider, with
     * the provide omitted.
     *
     * @param configProvider
     */
    static forRoot(): ModuleWithProviders<SocialLoginModule> {
        return {
            ngModule: SocialLoginModule,
            providers: [
                SocialLoginService
            ]
        };
    }

}
