import { Injectable } from '@angular/core';
import { CertificateTemplate, CertificateTemplateModel } from '@novo/platform-common/models/certificate-template';
import { classToPlain } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LearningTrackTemplateSalesInfoResponse } from '../../interfaces/api-response.interfaces';
import { Currency } from '../../interfaces/exchange-rates.interfaces';
import { InAppPurchaseProvider, PaymentSessionIdType, TrackPaymentSession } from '../../interfaces/payment.interfaces';
import { Class, ContentTokenDetails, FormField, Group, LearningTrackTemplate, UserRoleType } from '../../models';
import { isPasswordLogin, isSocialLogin, LoginType, PasswordLoginCredentials, SocialLoginCredentials } from '../auth/auth.interfaces';
import { AsrAuthenticationPayload, AssignContentByTokenParameters, AssignContentByTokenResponse, ChangePasswordParameters, ChangePasswordResponse, CreateSignUpTokenParameters, CreateSignUpTokenResponse, GetDetailsByContentTokenParameters, GetDetailsByContentTokenResponse, GetIssuedCertificatesReportFilter, GetIssuedCertificatesReportRequest, GetMyInfoParameters, GetMyInfoResponse, GetRolesParameters, GetRolesResponse, GetSignUpTokenParameters, GetSignUpTokenResponse, GetTranslationParameters, GetTranslationResponse, GetUserCertificatesResponse, GetUserRolesParameters, GetUserRolesResponse, GrantPermissionParameters, GrantPermissionResponse, IssuedCertificatesReportRequest, IssuedCertificatesReportResponse, LearningTrackTemplateResponse, LoginParameters, LoginResponse, LogoutParameters, LogoutResponse, MarkMissingTranslationsParameters, ParseUser, RemoveSignUpTokenParameters, RemoveSignUpTokenResponse, ResetPasswordParameters, ResetPasswordResponse, RevokePermissionParameters, RevokePermissionResponse, SetPasswordParameters, SetPasswordResponse, SetupUserAccountParams, SignupExternalUserParams, TrackPaymentSessionDetails, TransactionStatus, UpdateTranslationParameters, UserSignupInfo } from './parse-api.interfaces';
import { ParseRequest } from './parse-request';


@Injectable()
export class ParseAPIService {
    constructor(private request: ParseRequest) {
    }

    login(type: LoginType, credentials: PasswordLoginCredentials | SocialLoginCredentials, installationId: string): Observable<LoginResponse> {
        let params: LoginParameters | undefined;
        if (isPasswordLogin(type, credentials)) {
            params = {
                installationId,
                type,
                username: credentials.username,
                password: credentials.password
            };
        } else if (isSocialLogin(type, credentials)) {
            params = {
                installationId,
                type,
                userId: credentials.userId,
                idToken: credentials.idToken,
                accessToken: credentials.accessToken,
                provider: credentials.provider,
                email: credentials.email
            };
        }
        return this.request.runCloudFunction$<LoginParameters, LoginResponse>('login', params, { nonCancelable: true });
    }

    logout(): Observable<void> {
        return this.request.runCloudFunction$<LogoutParameters, LogoutResponse>('logout', {}, { nonCancelable: true });
    }

    linkWithIdProvider(credentials: SocialLoginCredentials, toLink: boolean): Observable<ParseUser> {
        const params = { ...credentials, toLink };
        return this.request.runCloudFunction$<SocialLoginCredentials & { toLink: boolean }, ParseUser>('linkWithIdProvider', params);
    }

    getMyInfo(): Observable<GetMyInfoResponse> {
        return this.request.runCloudFunction$<GetMyInfoParameters, GetMyInfoResponse>('getMyInfo');
    }

    resetPassword(username: string): Observable<ResetPasswordResponse> {
        return this.request.runCloudFunction$<ResetPasswordParameters, ResetPasswordResponse>('resetPassword', { username });
    }

    changePassword(username: string, password: string): Observable<void> {
        return this.request.runCloudFunction$<ChangePasswordParameters, ChangePasswordResponse>('changePassword', { username, password });
    }

    setPassword(username: string, password: string, token: string): Observable<void> {
        return this.request.runCloudFunction$<SetPasswordParameters, SetPasswordResponse>('resetPassword', { username, password, token });
    }

    getUserRoles(userId: string, type: UserRoleType): Observable<GetUserRolesResponse> {
        return this.request.runCloudFunction$<GetUserRolesParameters, GetUserRolesResponse>('getUserRoles', { userId, type });
    }

    getRoles(): Observable<GetRolesResponse> {
        return this.request.runCloudFunction$<GetRolesParameters, GetRolesResponse>('getRoles');
    }

    grantPermission(data: GrantPermissionParameters): Observable<GrantPermissionResponse> {
        return this.request.runCloudFunction$<GrantPermissionParameters, GrantPermissionResponse>('grantPermission', data);
    }

    revokePermission(userRoleId: string): Observable<void> {
        return this.request.runCloudFunction$<RevokePermissionParameters, RevokePermissionResponse>('revokePermission', { userRoleId });
    }

    /**
     * Sign up an external user, i.e. a website visitor
     **/
    signup(loginType: LoginType, loginCredentials: SocialLoginCredentials | PasswordLoginCredentials, userInfo: UserSignupInfo, approvedDocumentUrls: string[] = []): Observable<any> {
        return this.request.runCloudFunction$<SignupExternalUserParams, void>('signupExternalUser', {
            ...userInfo,
            ...loginCredentials,
            approvedDocumentUrls,
            loginType
        });
    }

    getSignUpToken(signUpObject: GetSignUpTokenParameters): Observable<GetSignUpTokenResponse> {
        return this.request.runCloudFunction$<GetSignUpTokenParameters, GetSignUpTokenResponse>('getSignUpToken', signUpObject);
    }

    createSignUpToken$(signUpObject: { groupId: string, classId?: string, trackId?: string }): Observable<string> {
        return this.request.runCloudFunction$<CreateSignUpTokenParameters, CreateSignUpTokenResponse>('createSignUpToken', signUpObject);
    }

    removeSignUpToken$(signUpObject: { groupId?: string, classId?: string, trackId?: string }): Observable<void> {
        return this.request.runCloudFunction$<RemoveSignUpTokenParameters, RemoveSignUpTokenResponse>('removeSignUpToken', signUpObject);
    }

    /**
     * Sign up a user based on a token referring to a content access link
     **/
    signupByToken(token: string, loginType: LoginType, loginCredentials: SocialLoginCredentials | PasswordLoginCredentials, userInfo: UserSignupInfo, approvedDocumentUrls: string[] = []): Observable<any> {
        return this.request.runCloudFunction$<SetupUserAccountParams, void>('setupUserAccount', {
            user: {
                ...userInfo,
                ...loginCredentials
            },
            loginType,
            token,
            approvedDocumentUrls,
            metaData: userInfo.meta
        });
    }

    submitFormData(email: string, name: string, organisation: string, role: string): Observable<any> {
        return this.request.runCloudFunction$('submitFormData', {
            formId: '1RTNqQRHguLPnorO8yHFUNZn3U9Pj2V6rBApeu5BB-X8',
            email, name, organisation, role
        });
    }

    trialRequestEmail(fields: FormField[]): Observable<void> {
        return this.request.runCloudFunction$('sendTrialRequestEmail', { fields });
    }

    quotationRequest(fields: FormField[]): Observable<void> {
        return this.request.runCloudFunction$('sendCompanyRequestInfoEmail', { fields });
    }

    isUniqueUsernameOrEmail(username: string, email: string): Observable<boolean> {
        return this.request.runCloudFunction$('checkUniqueUsernameOrEmail', { username, email });
    }

    getTranslations(language: string, platform: string = 'novo-landing-page'): Observable<{ [key: string]: string }> {
        return this.request.runCloudFunction$<GetTranslationParameters, GetTranslationResponse[]>('getTranslations', {
            language,
            platform
        }).pipe(
            map(res => res.reduce((acc, trans) => ({
                ...acc,
                [trans.key]: trans.value
            }), {})),
        );
    }

    markMissingTranslations(missingKeys: any[], platform: string = 'novo-landing-page'): Observable<any> { // any[] => Translation[]
        return this.request.runCloudFunction$<MarkMissingTranslationsParameters, GetTranslationResponse[]>('addMissingTranslationKeys', {
            keys: missingKeys,
            platform
        });
    }

    getMissingTranslations(language: string, platform: string = 'novo-landing-page'): Observable<string[]> {
        return this.request.runCloudFunction$<GetTranslationParameters, GetTranslationResponse[]>('getMissingTranslations', {
            language,
            platform
        }).pipe(
            map(res => res.map(trans => trans.key))
        );
    }

    updateTranslation(language: string, key: string, value: string, platform: string = 'novo-landing-page'): Observable<GetTranslationResponse> {
        return this.request.runCloudFunction$<UpdateTranslationParameters, GetTranslationResponse>('updateTranslation', {
            language,
            key,
            value,
            platform
        });
    }

    getDetailsByContentToken(token: string, currency?: Currency): Observable<ContentTokenDetails> {
        return this.request.runCloudFunction$<GetDetailsByContentTokenParameters, GetDetailsByContentTokenResponse>('getDetailsByContentToken', {
            token, currency
        }).pipe(
            map((json) => {
                return {
                    group: json.group && Group.initialize(json.group),
                    class: json.class && Class.initialize(json.class),
                    track: json.track && LearningTrackTemplate.initialize(json.track),
                    limitReachedFor: json.seatLimitReached,
                    shouldPay: json.shouldPay,
                    price: json.price
                };
            })
        );
    }

    assignContentByToken(token: string): Observable<void> {
        return this.request.runCloudFunction$<AssignContentByTokenParameters, AssignContentByTokenResponse>('assignContentByToken', {
            token
        });
    }

    getCertificateTemplate$(certId: string): Observable<CertificateTemplate> {
        return this.request.runCloudFunction$<{ certId: string }, CertificateTemplateModel>('getCertificateTemplate', { certId }).pipe(map(c => CertificateTemplate.initialize(c)));
    }

    getCertificateTemplates$(groupId: string): Observable<CertificateTemplate[]> {
        return this.request.runCloudFunction$<{ groupId: string }, CertificateTemplateModel[]>('getCertificateTemplates', { groupId }).pipe(map(cs => cs.map(CertificateTemplate.initialize)));
    }

    saveCertificateTemplate$(certificate: CertificateTemplate): Observable<CertificateTemplate> {
        return this.request.runCloudFunction$<{ certificate: CertificateTemplateModel }, CertificateTemplateModel>(
            'saveCertificateTemplate',
            { certificate: classToPlain(certificate) as CertificateTemplateModel }
        ).pipe(map(model => new CertificateTemplate(model)));
    }

    deleteCertificateTemplate$(certId: string): Observable<void> {
        return this.request.runCloudFunction$<{ certId: string }, void>('deleteCertificateTemplate', { certId });
    }

    getUserCertificates$(userId?: string): Observable<GetUserCertificatesResponse> {
        return this.request.runCloudFunction$<{ userId?: string }, GetUserCertificatesResponse>('getUserCertificates', { userId });
    }

    issuedCertificatesReport$(groupId: string, filter: GetIssuedCertificatesReportFilter, request: GetIssuedCertificatesReportRequest): Observable<IssuedCertificatesReportResponse> {
        return this.request.runCloudFunction$<IssuedCertificatesReportRequest, IssuedCertificatesReportResponse>('issuedCertificatesReport', { groupId, filter, request });
    }

    createAsrToken(userId: string): Observable<AsrAuthenticationPayload> {
        return this.request.runCloudFunction$<{ userId }, AsrAuthenticationPayload>('asrLogin', { userId });
    }
}
