import { Injectable } from '@angular/core';

/**
 * Cookie key
 */
export const SESSION_TOKEN_KEY = 'sessionToken';

/**
 * Responsible for storing the session token
 */
@Injectable()
export abstract class SessionTokenService {
    abstract get(): string | undefined | null;
    abstract set(sessionToken: string | undefined | null): void;
    abstract remove(): void;
}
