import { Type } from 'class-transformer';
import { Background } from './background';
import { CustomUserField } from './custom-user-field';
import { Media } from './media';
import { PaymentModel } from './model-types';
import { initConstructor, LocaleCompare, serializeType } from './utilities';

export interface GroupFeatures {
    chats?: boolean;
    notifications?: boolean;
    disableAnalytics?: boolean;
    activity_player?: boolean;
    account_delete?: boolean;
    readspeaker?: boolean
}


export interface GroupModel {
    identifier: string;
    name: string;
    background: Background;
    logo?: Media;
    wistiaLimit?: number;
    wistiaUploads?: number;
    seatLimit?: number;
    activeSeats?: number;
    subgroupIds: string[];
    features: GroupFeatures;
    paymentModel?: PaymentModel | null;
    customUserFields: CustomUserField[];
}

export class Group implements GroupModel {
    public readonly identifier: string;
    public name: string;
    @Type(serializeType(Background))
    background: Background = new Background();
    @Type(serializeType(Media))
    logo?: Media;
    public wistiaLimit?: number;
    public wistiaUploads = 0;
    public seatLimit?: number;
    public activeSeats = 0;
    public subgroupIds: string[] = [];
    public features: GroupFeatures = {};
    public paymentModel?: PaymentModel;
    public customUserFields: CustomUserField[] = [];

    static initialize(json: any): Group {
        let background: Background;
        if (json.background) {
            background = Background.initialize(json.background);
        } else {
            background = new Background();
        }

        const logo = Media.initialize(json.logo);;
        return new Group({
            identifier: json.identifier,
            name: json.name,
            background,
            logo,
            wistiaLimit: json.wistiaLimit,
            wistiaUploads: json.wistiaUploads,
            seatLimit: json.seatLimit,
            activeSeats: json.activeSeats,
            subgroupIds: json.subgroups,
            features: json.features,
            paymentModel: json.paymentModel,
            customUserFields: json.customUserFields
        });
    }
    static sort(groupA: Group, groupB: Group): number {
        const a = groupA.name;
        const b = groupB.name;
        return LocaleCompare.compare(a, b);
    }

    constructor(data: GroupModel) {
        initConstructor<GroupModel>(this, data);
    }
}


export class GroupTree extends Group {
    public subgroups?: GroupTree[];

    constructor(group: Group) {
        super({ ...group });
    }
}
