import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationStorage } from '@novo/platform-common/services/auth/auth-storage/auth.storage.service';
import { AuthService } from '@novo/platform-common/services/auth/auth.service';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
    templateUrl: './password-reset-page.component.html',
    styles: [`
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    `]
})
export class PasswordResetComponent {

    form: FormGroup;
    loading$ = new BehaviorSubject(false);

    /**
     * Url to return user to after signup or login
     */
    readonly returnUrl: string | null = this.route.snapshot?.queryParamMap.get('return');

    constructor(private router: Router,
        private route: ActivatedRoute,
        private auth: AuthService,
        private authStorage: AuthenticationStorage,
        private snackBar: MatSnackBar,
        private fb: FormBuilder,
        public translate: TranslateService) {

        const userName = this.authStorage.user && this.authStorage.user.username;
        this.form = this.fb.group({
            username: [userName, Validators.required]
        });
    }

    async resetPassword(form: FormGroup): Promise<void> {
        const username: string = form.get('username').value;
        this.loading$.next(true);
        this.auth.resetPassword(username).pipe(
            tap(() => {
                this.snackBar.open(
                    this.translate.instant('PAGES.PASSWORD_RESET.RESET_PASSWORD_SENT'),
                    undefined,
                    { duration: 2000 }
                );
                const returnUrl = this.returnUrl || '/signup';
                void this.router.navigateByUrl(returnUrl);
            }),
            catchError(err => {
                this.snackBar.open(err.message, this.translate.instant('OK'));
                return EMPTY;
            }),
            finalize(() => {
                this.loading$.next(false);
            })
        ).subscribe();
    }
}
