import { Type } from 'class-transformer';
import { Media } from './media';
import { Json, serializeType } from './utilities';


export type BackgroundType = 'color' | 'image';
export type BackgroundSize = 'header' | 'full';

export interface BackgroundStyle {
    'background-image': string;
    'background-repeat'?: 'repeat' | 'no-repeat';
    'background-size'?: 'contain' | 'cover';
    'background-position'?: 'center center';
    'background-color'?: string;
}

export class Background {
    type?: BackgroundType = undefined;
    @Type(serializeType(Media)) media?: Media = undefined;
    color?: string | undefined = '#ffffff';
    size: BackgroundSize = 'full';
    repeat = false;

    static initialize(json: Json<Background>): Background {
        const bg = new Background();
        for (const key of Object.keys(bg)) {
            if (key === 'media') {
                bg.media = Media.initialize(json.media);
            } else if (key !== 'identifier') {
                if (json[key] !== undefined && json[key] !== null) {
                    bg[key] = json[key];
                }
            }
        }
        return bg;
    }

    static defaultBackground(): Background {
        const bg: Background = new Background();
        bg.color = '#ffffff';
        bg.size = 'full';
        bg.type = 'color';
        return bg;
    }

    getStyle(opts?: { thumbnail?: boolean }): BackgroundStyle {
        let useThumbnail = false;
        if (opts && opts.thumbnail) {
            useThumbnail = true;
        }
        if (this.type === 'image' && this.media && this.media.files.length > 0) {

            let url = this.media.files[0].getURL();
            if (useThumbnail) {
                url = this.media.files[0].getThumbnailURL() || url;
            }
            return {
                'background-image': `url('${url}')`,
                'background-repeat': this.repeat ? 'repeat' : 'no-repeat',
                'background-size': this.repeat ? 'contain' : 'cover',
                'background-position': 'center center'
            };
        } else if (this.type === 'color' && this.color) {
            return {
                'background-color': this.color,
                'background-image': 'none'
            };
        } else {
            return {
                'background-image': 'url(\'assets/img/bokeh-bg.jpg\')',
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-position': 'center center'
            };
        }
    }
}
