import { Inject, Injectable, Optional } from '@angular/core';
import { Resolve } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

/**
 * Sets the express response status to 404
 */
@Injectable({
    providedIn: 'root'
})
export class PageNotFoundResponseResolver implements Resolve<any> {
    constructor(@Optional() @Inject(RESPONSE) readonly response: Response | undefined) { }

    resolve() {
        // Response is only available when rendering on the server.
        if (this.response) {
            this.response.status(404);
        }
    }
}
