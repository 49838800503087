import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@novo/platform-common/util/ssr';
import { take, tap } from 'rxjs/operators';

@Component({
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectComponent implements OnInit {

    constructor(private route: ActivatedRoute, @Optional() @Inject(WINDOW) private window: Window | undefined) {
    }

    ngOnInit(): void {
        if (this.window) {
            this.route.data.pipe(
                tap(data => {
                    if (data && data.url && window) {
                        window.location.replace(data.url);
                    }
                }),
                take(1)
            ).subscribe();
        }
    }
}
