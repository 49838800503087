import { Component, Input } from '@angular/core';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { TranslationKey } from '@novo/platform-common/models';

@Component({
    selector: 'app-login-button',
    templateUrl: './login-button.component.html',
    styleUrls: ['./login-button.component.scss']
})
export class LoginButtonComponent {
    @Input() type: 'facebook' | 'google' | 'apple' | 'email' = 'facebook';
    @Input() label: TranslationKey = 'LOGIN_PROVIDER.SIGN_IN_WITH';
    @Input() disabled: boolean;
    @Input() state: 'default' | 'busy' | 'linked' = 'default';

    emailIcon = faEnvelope;
}
