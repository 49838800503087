import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {

    @Input() type: 'success' | 'warning' | 'danger' | 'info';
    @Input() showIcon = true;

    icon: string[];

    ngOnInit() {
        switch (this.type) {
            case 'danger':
            case 'warning':
                this.icon = ['fal', 'exclamation-triangle'];
                break;
            case 'info':
                this.icon = ['fal', 'info-circle'];
                break;
            case 'success':
                this.icon = ['fal', 'check'];
                break;
        }
    }

}
