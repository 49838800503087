import { BaseLoginProvider } from '../entities/base-login-provider';
import { LoginProviderId } from '../entities/login-provider';
import { SocialUser } from '../entities/user';

declare let AppleID: any;

interface AppleIDSignInOnSuccess {
    authorization: {
        state: string,
        code: string,
        id_token: string
    };
    user?: {
        email: string,
        name: {
            firstName: string,
            lastName: string
        }
    };
}

export class AppleLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: LoginProviderId = 'apple';

    constructor(private clientId: string, private locale: string = 'en_US') {
        super();
    }

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.loadScript(
                AppleLoginProvider.PROVIDER_ID,
                `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${this.locale}/appleid.auth.js`,
                () => {
                    AppleID.auth.init({
                        clientId: this.clientId,
                        scope: 'name email',
                        redirectURI: location.href.split('?')[0], // URL without query params
                        usePopup: true
                    });
                    this._readyState.next(true);
                    resolve();
                });
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        // Not implemented
        return Promise.resolve(undefined);
    }

    async signIn(): Promise<SocialUser> {
        await this.onReady();
        const data = await AppleID.auth.signIn();
        const user = this._getUserFromResponse(data);
        return user;
    }

    signOut(): Promise<any> {
        // Not implemented
        return Promise.resolve();
    }

    _getUserFromResponse(data: AppleIDSignInOnSuccess): SocialUser {
        const user: SocialUser = new SocialUser();
        user.idToken = data.authorization.id_token;
        const { id, email } = this._getUserDetailsFromJWT(user.idToken);
        user.id = id;
        user.email = data.user?.email || email;
        user.firstName = data.user?.name.firstName;
        user.lastName = data.user?.name.lastName;
        if (user.firstName && user.lastName) {
            user.name = `${user.firstName} ${user.lastName}`;
        }
        return user;
    }

    _getUserDetailsFromJWT(jwt: string): { id: string | undefined, email: string | undefined } {
        const base64Payload = jwt.split('.')[1];
        const payload = JSON.parse(atob(base64Payload));
        return {
            id: payload.sub,
            email: payload.email
        };
    }
}
