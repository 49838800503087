import { BaseLoginProvider } from '../entities/base-login-provider';
import { LoginProviderId } from '../entities/login-provider';
import { SocialUser } from '../entities/user';
import { LoginOpt } from '../social-login.service';

declare let FB: any;

export class FacebookLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: LoginProviderId = 'facebook';

    constructor(
        private clientId: string,
        private opt: LoginOpt = { scope: 'email,public_profile' },
        private locale: string = 'en_US',
        private fields: string = 'name,email,picture,first_name,last_name',
        private version: string = 'v4.0'
    ) { super(); }


    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.loadScript(FacebookLoginProvider.PROVIDER_ID,
                `//connect.facebook.net/${this.locale}/sdk.js`,
                () => {
                    FB.init({
                        appId: this.clientId,
                        autoLogAppEvents: true,
                        cookie: true,
                        xfbml: true,
                        version: this.version
                    });
                    this._readyState.next(true);
                    resolve();
                });
        });
    }



    getLoginStatus(): Promise<SocialUser> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();
            FB.getLoginStatus((response: any) => {
                if (response.status === 'connected') {
                    const authResponse = response.authResponse;
                    const graphApiCallback = (fbUser: any) => {
                        const user = this._getUserFromResponse(fbUser, authResponse.accessToken);
                        resolve(user);
                    };
                    FB.api(`/me?fields=${this.fields}`, graphApiCallback);
                } else {
                    reject('No user is currently logged in.');
                }
            });
        });
    }

    signIn(): Promise<SocialUser> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();
            const scope = this.opt && this.opt.scope; // as comma delimited string
            const loginCallbackFn = (response: any) => {
                if (response.authResponse) {
                    const authResponse = response.authResponse;
                    const graphApiCallback = (fbUser: any) => {
                        const user = this._getUserFromResponse(fbUser, authResponse.accessToken);
                        resolve(user);
                    };
                    FB.api(`/me?fields=${this.fields}`, graphApiCallback);

                } else {
                    reject(response);
                }
            };
            FB.login(loginCallbackFn, { scope });
        });
    }

    signOut(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();
            FB.logout((response: any) => resolve());
        });
    }

    _getUserFromResponse(fbUser: any, accessToken: string): SocialUser {
        const user: SocialUser = new SocialUser();
        user.id = fbUser.id;
        user.name = fbUser.name;
        user.email = fbUser.email;
        user.photoUrl = 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal';
        user.firstName = fbUser.first_name;
        user.lastName = fbUser.last_name;
        user.accessToken = accessToken;
        return user;
    }

    isCriticalError(err: { status: string }): boolean {
        return err.status !== 'unknown'; // status: 'unknown' => user cancelled dialog / no user logged in
    }
}
