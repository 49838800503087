import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faComments, faEuroSign, faFileCertificate, faMagic, faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import { faBars, faCheck, faChevronLeft, faCoffee, faExclamationTriangle, faInfoCircle, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faComment, faLocationArrow, fas } from '@fortawesome/pro-solid-svg-icons';
import { AnimatedLogoModule } from '@novo/platform-common/components/animated-logo/animated-logo.module';
import { UserIconModule } from '@novo/platform-common/components/user-icon/user-icon.module';
import { NovoTranslationModule } from '@novo/platform-translations';
import { NgxMdModule } from 'ngx-md';
import { MaterialModule } from '../../material.module';
import { PipeModule } from '../pipes/pipes.module';
import { AlertComponent } from './alert/alert.component';
import { ColorBlobComponent } from './color-blob.component';
import { CookieConsentModule } from './cookie-consent/cookie-consent.module';
import { CourseCardComponent } from './course-card/course-card.component';
import { FooterComponent } from './footer/footer.component';
import { HeroComponent } from './hero.component';
import { MarkdownDocumentComponent } from './markdown-document/markdown-document.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PlayerRedirectComponent } from './player-redirect/player-redirect.component';
import { PopupComponent } from './popup-contact-form/popup-contact-form.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { WistiaVideoPopoverComponent } from './wistia-video-popover/wistia-video-popover.component';
import { WrappedPageComponent } from './wrapped-page/wrapped-page.component';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        MaterialModule,
        NgxMdModule,
        NovoTranslationModule,
        UserIconModule,
        FormsModule,
        ReactiveFormsModule,
        PipeModule,
        AnimatedLogoModule,
        CookieConsentModule
    ],
    declarations: [
        AlertComponent,
        ColorBlobComponent,
        CourseCardComponent,
        HeroComponent,
        NavbarComponent,
        WrappedPageComponent,
        MarkdownDocumentComponent,
        UserAvatarComponent,
        FooterComponent,
        PopupComponent,
        WistiaVideoPopoverComponent,
        PlayerRedirectComponent
    ],
    exports: [
        MaterialModule,
        AnimatedLogoModule,
        AlertComponent,
        ColorBlobComponent,
        CourseCardComponent,
        HeroComponent,
        NavbarComponent,
        WrappedPageComponent,
        MarkdownDocumentComponent,
        UserAvatarComponent,
        NgxMdModule,
        FontAwesomeModule,
        FooterComponent,
        PopupComponent,
        WistiaVideoPopoverComponent,
        PlayerRedirectComponent
    ]
})
export class ComponentsModule {
    constructor(readonly library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(
            faCoffee,
            faBars,
            faExclamationTriangle,
            faInfoCircle,
            faLocationArrow,
            faComment,
            faSignOut,
            faCaretDown,
            faChevronLeft,
            faCheck,
            faMagic,
            faComments,
            faFileCertificate,
            faShoppingCart,
            faEuroSign
        );
    }
}
