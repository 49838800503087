import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { initUserLocale, LocalServiceconfig } from './locale.fns';
import { LocalConfig } from './locale.interfaces';
import { LocaleService } from './locale.service';

@NgModule({
    exports: [],
    providers: [
        LocaleService,
        {
            provide: APP_INITIALIZER,
            useFactory: initUserLocale,
            deps: [LocaleService, LocalServiceconfig],
            multi: true,
        }
    ],
})
export class LocaleModule {

    static forRoot(config: LocalConfig): ModuleWithProviders<LocaleModule> {
        return {
            ngModule: LocaleModule,
            providers: [
                { provide: LocalServiceconfig, useValue: config }
            ]
        };
    }

}
