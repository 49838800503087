<app-wrapped-page [header]="false">
    <div class="content">
        <div style="width: 100%; max-width: 400px; padding: 0px 16px; box-sizing: border-box;">
            <form novalidate (ngSubmit)="resetPassword(form)" [formGroup]="form">
                <mat-form-field floatingPlaceholder="true" style="width: 100%">
                    <input matInput formControlName="username"
                        placeholder="{{'PAGES.PASSWORD_RESET.USERNAME_OR_EMAIL' | novoTranslate}}" />
                </mat-form-field>
                <div>
                    <button style="text-align: center;" [disabled]="form.invalid || (loading$ | async)" mat-flat-button
                        type="submit" color="primary" type="submit" style="width: 100%">
                        <span>{{'PAGES.PASSWORD_RESET.RESET_PASSWORD' | novoTranslate}}</span>
                    </button>
                </div>
            </form>
        </div>

    </div>
</app-wrapped-page>