import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ParseAPIService } from '@novo/platform-common/services/api/parse-api.service';
import { AuthService } from '@novo/platform-common/services/auth';
import { isSocialLogin, LoginType, PasswordLoginCredentials, SocialLoginCredentials, SocialLoginProvider } from '@novo/platform-common/services/auth/auth.interfaces';
import { ErrorLoggerService } from '@novo/platform-common/services/error-logger';
import { LoginProvider, LoginProviderId, SocialLoginService } from '@novo/social-login';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, from, NEVER, Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

/**
 * Storage key indicating the user has logged in before
 */
const RETURNING_USER_KEY = 'returningUser';

@Component({
    selector: 'app-signup-page',
    templateUrl: './signup-page.component.html',
    styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent {

    readonly loginForm = new FormGroup({
        email: new FormControl('', [Validators.email]),
        password: new FormControl('', [Validators.required]),
    });

    /**
     * Indicator during API calls
     */
    sending$: BehaviorSubject<{ loginType: LoginType, provider?: SocialLoginProvider } | undefined> = new BehaviorSubject(undefined);

    /**
     * Url to return user to after signup or login
     */
    readonly return: string | null = this.route.snapshot.queryParamMap.get('return');

    /**
     * Group identifier of the selling party. Users are added to this group
     */
    readonly vendorId: string | null = this.route.snapshot.queryParamMap.get('groupId');

    /**
     * Token that signals this account signup is linked to a content access link
     */
    readonly token: string | null = this.route.snapshot.queryParamMap.get('token');

    /**
     * Set active tab based on explitly based on mode query parameter
     * or based on whether we know this is a returning user
     */
    selectedTab$: Observable<string> = this.route.queryParamMap?.pipe(
        map(params => {
            const mode = params.get('mode');
            if (mode == null) {
                return this.cookie.check(RETURNING_USER_KEY) ? 'login' : 'sign-up';
            } else {
                return mode;
            }
        }));

    /**
     * Holds errors returned by API
     */
    error: Error;

    readonly loginProviders: Map<LoginProviderId, LoginProvider> = this.socialLoginService.providers;

    constructor(
        private route: ActivatedRoute,
        private auth: AuthService,
        private socialLoginService: SocialLoginService,
        public api: ParseAPIService,
        private errorLogger: ErrorLoggerService,
        public router: Router,
        private cookie: CookieService
    ) {
    }

    private removeError(control: AbstractControl, error: string) {
        const err = control.errors; // get control errors
        if (err) {
            delete err[error]; // delete your own error
            if (!Object.keys(err).length) { // if no errors left
                control.setErrors(null); // set control errors to null making it VALID
            } else {
                control.setErrors(err); // controls got other errors so set them back
            }
        }
    }

    notIncludeEmail(ac: AbstractControl): ValidationErrors | null {
        const passCtrl = ac.get('password');
        const emailCtrl = ac.get('email');

        if (!passCtrl || !emailCtrl) {
            return null;
        }

        const password = passCtrl.value;
        const email = emailCtrl.value;
        if (email.length > 0 && password.indexOf(email) >= 0) {
            passCtrl.setErrors({ includesEmail: true });
        } else {
            this.removeError(passCtrl, 'includesEmail');
        }
        return null;
    }

    loginWithIdProvider(provider: LoginProviderId) {
        from(this.socialLoginService.signIn(provider)).pipe(
            catchError(err => {
                this.errorLogger.captureException(err, { extra: { provider } });
                return NEVER;
            }),
            filter(result => result != null),
            map(result => this._login('social', {
                provider,
                userId: result.id,
                idToken: result.idToken,
                accessToken: result.accessToken,
                email: result.email
            }))
        ).subscribe();
    }

    loginWithPassword() {
        if (!this.loginForm.valid) {
            return;
        }
        const { email, password } = this.loginForm.value;
        this._login('password', { username: email, password });
    }

    _login(type: LoginType, credentials: PasswordLoginCredentials | SocialLoginCredentials) {

        let provider: LoginProviderId | undefined;
        if (isSocialLogin(type, credentials)) {
            provider = credentials.provider;
        }

        this.sending$.next({ loginType: type, provider });
        this.error = null;

        this.auth.login(type, credentials).pipe(
            catchError(err => {
                this.error = err;
                this.sending$.next(undefined);
                return NEVER;
            }),
        ).subscribe(_ => {
            this.sending$.next(undefined);
            this.cookie.set(RETURNING_USER_KEY, '1', 365);
            if (this.return) {
                this.router.navigateByUrl(this.return);
            }
        });
    }

}
