import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
    name: 'novoTranslate',
    pure: false,
})
export class NovoTranslationPipe extends TranslatePipe implements PipeTransform {
    /**
     * Just here to provide an alias for ngx' TranslatePipe
     */
}
