import { UserRoleType } from './model-types';
import { initConstructor } from './utilities';

export type UserManagementPermission =
    'user_assign_course' |       // Can assign users to a course
    'user_list' |                // Can view the user names in a group or participating in a course
    'user_view' |                // Can view the details of users in a group or participating in a course
    'user_manage' |              // Can edit user details and add or remove users of a group
    'group_manage' |             // Can change the name of the group, add subgroups and enable (non-payment) features (implies user_manage permission)
    'group_assignment_report' |  // Can generate the track assignments / sales report
    'group_set_limits' |         // Set limits
    'group_report' |             // Can generate reports
    'folder_manage' |            // Can change the content folder structure of a group
    'user_permission_manage';    // Can change the permissions of the users in a group (implies group_manage permission)

export type CoursePermission =
    'course_view' |         // Can view a course
    'course_review' |       // Can review a course
    'course_edit' |         // Can edit a course (implies course_review)
    'course_create' |       // Can create a course
    'course_distribute' |   // Can assign a course to a subgroup
    'course_publish' |      // Can publish content
    'course_clone';        // Can copy the content of a course into a new course

export type PaymentPermission =
    'payment_manage' |  // Can change the payment model of a group
    'payment_edit';     // Can show/edit sales information of a track template

export type SystemPermission =
    'translation_edit';       // Can edit UI translations'

export type Permission = UserManagementPermission | CoursePermission | PaymentPermission | SystemPermission;

export interface RoleModel { identifier: string; name: string; permissions: Permission[]; }


export class Role implements RoleModel {
    public readonly identifier: string;
    public name: string;
    public permissions: Permission[] = [];

    public static initialize(json: any): Role {
        return new Role({
            identifier: json.identifier,
            name: json.name,
            permissions: json.permissions
        });
    }

    constructor(data: RoleModel) {
        initConstructor<RoleModel>(this, data);
    }
}

export interface TargetPermissions {
    [key: string]: Permission[];
}

export type PermissionMap = { [key in UserRoleType]?: TargetPermissions };

export class UserRole {

    public static initialize(json: any): UserRole {
        return new UserRole(json.identifier, json.userId, Role.initialize(json.role), json.type, json.targetId);
    }

    constructor(public readonly identifier: string, public readonly userId: string, public readonly role: Role, public readonly type: UserRoleType, public readonly targetId: string) {
    }
}
