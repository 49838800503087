import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { WINDOW } from '@novo/platform-common/util/ssr';
import { Environment } from '@novo/website/services/environment/environment.service';
import Bowser from 'bowser';
import { CookieService } from 'ngx-cookie-service';

const CONSENT_COOKIE_NAME = '_novo_cookie_consent';

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieConsentComponent implements OnInit {

    @Input() message: string;
    @Input() readMore: string;
    @Input() dismiss: string;

    @ViewChild('consentSnackBar', { static: true }) consentTemplate: TemplateRef<unknown>;

    private snackbarRef?: MatSnackBarRef<any>;

    constructor(private cookieService: CookieService, private snackBar: MatSnackBar, private environment: Environment, @Inject(DOCUMENT) private document: Document, @Inject(WINDOW) private window: Window | undefined) {
    }

    ngOnInit() {
        // this.window is undefined when SSR is used
        if (this.window != null && !this.cookieService.check(CONSENT_COOKIE_NAME)) {
            this.snackbarRef = this.snackBar.openFromTemplate(this.consentTemplate);
        }
    }

    giveConsent() {
        const userAgent = this.document.defaultView?.navigator.userAgent || '';
        if (userAgent) {
            const sameSite = Bowser.getParser(userAgent).isBrowser('safari', true) ? undefined : 'Strict';
            this.cookieService.set(CONSENT_COOKIE_NAME, 'given', 365, '/', this._getSessionCookieDomain(), true, sameSite);
        }

        if (this.snackbarRef) {
            this.snackbarRef.dismiss();
        }
    }

    goToPrivacy() {
        window.open(this.environment.get('privacyPolicyUrl'), '_blank', 'noopener');
    }

    _getSessionCookieDomain(): string {
        if (this.document.location && !this.document.location.hostname.startsWith('localhost')) {
            const domainParts = this.document.location.hostname.split('.');
            domainParts[0] = ''; // Strip accounts or www from location.hostname
            return domainParts.join('.');
        }
        return '';
    }
}
