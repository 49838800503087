
import { Inject, Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NovoTranslationApi } from './novo-translation-api.class';
import { TRANSLATION_PLATFORM } from './novo-translation.token';

@Injectable()
export class NovoTranslationLoader extends TranslateLoader {

    constructor(private api: NovoTranslationApi, @Inject(TRANSLATION_PLATFORM) private platform: string) {
        super();
    }

    getTranslation(lang: string): Observable<any> {
        return this.api.getTranslations(lang, this.platform);
    }

}
