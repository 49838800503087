import { Observable } from 'rxjs';
import { Section } from '../../models/section';

export interface PreloadProgress {
    total: number;
    loaded: number;
    label?: string;
}

export interface StorageInfo {
    used: number;
    total: number;
};

export interface CacheGetFileOptions {
    storeIfNotCached: boolean;
}

export abstract class FileCacheService {

    abstract initCache(): Promise<any>;
    abstract clearCache(associationIds?: string[]): Promise<void>;
    abstract getFile(src: string, options?: CacheGetFileOptions): Promise<string>;
    abstract isCached(src: string): Promise<boolean>;
    abstract preloadFiles(activity: Section, associationId?: string, cacheVideos?: boolean): Observable<PreloadProgress>;
    abstract storeFile(src: string, associationId?: string): Observable<any>;
    abstract getSize(): Observable<number>;
    abstract calculateUsedSize(): void;
    abstract removeFromCache(sources: string | string[]): Observable<any>;
    abstract getCachedFiles(associationIds: string[]): Observable<{ [key: string]: string[] | undefined }>;
}
