import { InjectionToken } from '@angular/core';
import { LocalConfig } from './locale.interfaces';
import { LocaleService } from './locale.service';

/**
 * InjectionToken for config
 */
export const LocalServiceconfig = new InjectionToken<LocalConfig>('LocalServiceConfig');

/**
 * Initializes the user's locale
 */
export const initUserLocale = (localeService: LocaleService, config: LocalConfig) => {
    return () => localeService.initialize(config).toPromise();
};
