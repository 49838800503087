import { Exclude, Type } from 'class-transformer';
import { Background } from './background';
import { LearningTrack } from './learning-track';
import { Role } from './roles';
import { User } from './user';
import { initializeWithId, LocaleCompare, serializeType } from './utilities';


export class ClassMember {
    @Type(serializeType(User))
    public readonly user: User;
    @Type(serializeType(Role))
    public roles: Role[] = [];

    static initialize(json: any): ClassMember {
        const result = new ClassMember(User.initialize(json.user));
        if (json.roles) { result.roles = json.roles.map(role => Role.initialize(role)); }
        return result;
    }

    constructor(user: User) {
        this.user = user;
    }
}

export interface ClassSettings {
    hideMembers?: boolean;
}

export class Class {

    readonly identifier?: string;
    name = '';
    @Exclude() @Type(serializeType(ClassMember)) users: ClassMember[] = [];
    @Type(serializeType(LearningTrack)) learningTracks: LearningTrack[] = [];
    groupId?: string = undefined;
    archived = false;
    createdAt = new Date();
    settings: ClassSettings = {};
    @Type(serializeType(Background)) coverImage: Background = new Background();

    static initialize(json: any): Class {
        const result = initializeWithId(Class, json);
        if (json.users) {
            result.users = json.users.map(x => ClassMember.initialize(x)).sort(this.sortOnName);
        }
        if (json.learningTracks) {
            result.learningTracks = json.learningTracks.map(LearningTrack.initialize);
        }
        if (json.createdAt) {
            result.createdAt = new Date(json.createdAt);
        }

        const coverImage = json.coverImage || result.learningTracks.find(track => track.coverImage != null)?.coverImage;
        if (coverImage) {
            result.coverImage = Background.initialize(coverImage as any);
        }
        return result;
    }

    static sortOnName = (a: ClassMember, b: ClassMember) => {
        if (a.user.fullName < b.user.fullName) { return -1; } else { return 1; }
    }

    static sort(a: Class, b: Class): number {
        return LocaleCompare.compare(a.name, b.name);
    }

    constructor(id: string | undefined) {
        this.identifier = id;
    }

    getLearningTrack(trackId: string): LearningTrack | undefined {
        return this.learningTracks.find(track => track.identifier === trackId);
    }
}
