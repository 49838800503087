import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { AuthenticationStorage } from './auth-storage/auth.storage.service';
import { AuthService } from './auth.service';
import { CookieSessionTokenService } from './session-token/session-token-cookie.service';
import { LocalStorageSessionTokenService } from './session-token/session-token-localstorage.service';
import { SessionTokenService } from './session-token/session-token.service';

/**
 * Updates user information from local storage
 */
export const updateUserInformation = (auth: AuthService) => {
    return () => auth.updateUserState().toPromise();
};

export const cancelRequestFactory = (authStorage: AuthenticationStorage): Observable<boolean> => {
    return authStorage.user$.pipe(
        distinctUntilChanged((u1, u2) => {
            if (u1 == null) { return true; } // Anonymous request don't have to be canccelled when a user logs in
            return u2 == null || u1.identifier === u2.identifier;
        }),
        skip(1), // Do not emit on population as the first request will be cancelled otherwise
        map(_ => true)
    );
};

export const sessionTokenServiceFactory = (
    cookieService: CookieService,
    document: Document,
    window: Window | undefined
): SessionTokenService => {
    if (window && 'cordova' in window) {
        // Use local storage as cookies are not persisted on iOS and Android.
        // Furthermore, cookies have no advantage over local storage in a Cordova context
        // as the storage is not shared across apps
        return new LocalStorageSessionTokenService();
    } else {
        return new CookieSessionTokenService(document, cookieService);
    }
};
