import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NovoTranslationModule } from '@novo/platform-translations';
import { CookieConsentComponent } from './cookie-consent.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatSnackBarModule,
        NovoTranslationModule
    ],
    declarations: [
        CookieConsentComponent
    ],
    exports: [
        CookieConsentComponent
    ]
})
export class CookieConsentModule {
}
