import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-partner-card',
    template: `
        <div style="color: rgba(0,0,0,0.87); font-weight: bold; text-align: center; font-size: 13px; letter-spacing: 2px; text-transform: uppercase;" >
            <ng-content  select="[title]"></ng-content>
        </div>
        <div style="display: flex; justify-content: space-around; align-items: center; position: absolute; width: 100%; height: 100%; top: 0; left: 0;">
            <ng-content  select="[logos]"></ng-content>
        </div>
        <app-color-blob class="category-blob" [shape]="0" [color]="color"></app-color-blob>
  `,
    styles: [`

        :host {
            position: relative;
            display: block;
            width: 100%;
            background: white;
            overflow: hidden;
            border-radius: 12px;
            box-shadow: 0px 4px 16px rgba(0,0,0,0.10);
            padding: 16px;
            box-sizing: border-box;
        }

        .category-blob {
            bottom: 0px;
            position: absolute;
            left: 0px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerCardComponent {

    @Input() color;
}



