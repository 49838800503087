import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-animated-logo',
    templateUrl: './animated-logo.component.html',
    styleUrls: ['./animated-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimatedLogoComponent {
    @Input() uploading: boolean = false;
}



