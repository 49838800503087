import { Environment } from './environment.interface';

export const environment: Environment = {
    production: true,
    novoApi: {
        appId: 'novo',
        endpoint: 'https://platform.novo-learning.com/parse'
    },
    lti: {
        url: 'https://platform.novo-learning.com/lti/launch',
        oidcUrl: 'https://platform.novo-learning.com/lti/oidc',
        redirectUri: 'https://platform.novo-learning.com/lti/launch',
        keysetUrl: 'https://platform.novo-learning.com/lti/.well-known/jwks',
        publicKeyUrl: 'https://platform.novo-learning.com/.well-known/pubkey.pem',
    },
    privacyPolicyUrl: 'https://www.novo-learning.com/privacy/1.0.0',
    termsOfServiceUrl: 'https://www.novo-learning.com/terms/1.0.0',
    contactUrl: 'https://www.novo-learning.com/contact',
    supportUrl: 'https://www.novo-learning.com/support',
    mediaBaseUrl: 'https://novo-cdn.azureedge.net/',
    playerUrl: 'https://platform.novo-learning.com/player',
    remoteLoggingEnabled: true,
    env: 'prod',
    accountsUrl: 'https://accounts.novo-learning.com/',
    trialAccountUrl: 'https://portal.novo-learning.com/trial'
};
