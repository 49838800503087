import { PhoneSet } from '@novo-learning/novo-sdk';
import { SupportedBrowser } from './models/browser';
import { Language } from './models/international-text';
import { LanguageCode } from './models/model-types';
import { SectionCategory } from './models/section';

/* The maximum number of participants within a group chat */
export const maxChatParticipants = 32;

// TODO: Move language / locale related stuff to a common locale service
export type LanguageMap = {
    [key in LanguageCode]: Language;
};

export const languageMap: LanguageMap = {

    // Languages for which we support ASR
    'en': new Language('en', 'eng', 'English', 'English', true, true, PhoneSet.cmu69, 'en-US', 'Latn', 'ltr'),
    'nl': new Language('nl', 'dut', 'Dutch', 'Nederlands', true, true, PhoneSet.novo_nl37, 'nl-NL', 'Latn', 'ltr'),
    'zh': new Language('zh', 'chi', 'Mandarin (Simplified)', '中文', true, true, PhoneSet.novo_cmn111, 'zh-CN', 'Hans', 'ltr'),
    'ar-idn': new Language('ar-idn', 'idn', 'Arabic (Bahasa transliteration)', 'Al-‘Arabiyah', true, true, PhoneSet.novo_ar35, 'id-ID', 'Latn', 'ltr'),
    'fr': new Language('fr', 'fre', 'French', 'Français', true, true, PhoneSet.novo_fr39, 'fr-FR', 'Latn', 'ltr'),
    'de': new Language('de', 'ger', 'German', 'Deutsch', true, true, PhoneSet.novo_de45, 'de-DE', 'Latn', 'ltr'),
    'es': new Language('es', 'spa', 'Spanish', 'Español', true, true, PhoneSet.novo_es27, 'es-ES', 'Latn', 'ltr'),

    // Languages for which we have UI translations
    'id': new Language('id', 'idn', 'Indonesian', 'Bahasa Indonesia', true, false, undefined, 'id-ID', 'Latn', 'ltr'),
    'vi': new Language('vi', 'vie', 'Vietnamese', 'Tiếng Việt', true, false, undefined, 'vi-VN', 'Latn', 'ltr'),
    'th': new Language('th', 'tha', 'Thai', 'ภาษาไทย', true, false, undefined, 'th-TH', 'Thai', 'ltr'),
    'zh-yue': new Language('zh-yue', 'yue', 'Cantonese (Traditional)', '粵語', true, false, undefined, 'zh-HK', 'Hant', 'ltr'),
    'hi': new Language('hi', 'hin', 'Hindi', 'हिन्दी', true, false, undefined, 'hi-HI', 'Deva', 'ltr'),
    'ja': new Language('ja', 'jpn', 'Japanese', '日本語', true, false, undefined, 'ja-JA', 'Jpan', 'ltr'),
    'my': new Language('my', 'bur', 'Burmese', 'မြန်မာဘာသာ', true, false, undefined, 'my-MM', 'Mymr', 'ltr'),
    'it': new Language('it', 'ita', 'Italian', 'Italiano', true, false, undefined, 'it-IT', 'Latn', 'ltr'),
    'cs': new Language('cs', 'cze', 'Czech', 'Čeština', true, false, undefined, 'cs-CZ', 'Latn', 'ltr'),
    'pl': new Language('pl', 'pol', 'Polish', 'Polski', true, false, undefined, 'pl-PL', 'Latn', 'ltr'),
    'pt-br': new Language('pt-br', 'por', 'Portuguese (Brazilian)', 'Português do Brasil', true, false, undefined, 'pt-BR', 'Latn', 'ltr'),

    // Languages for which we want to have UI translations in the future
    'sr': new Language('sr', 'srp', 'Serbian', 'Srpski', false, false, undefined, 'sr-RS', 'Latn', 'ltr'),
    'ko': new Language('ko', 'kor', 'Korean', '한국어', false, false, undefined, 'ko-KO', 'Hang', 'ltr'),
    'ms': new Language('ms', 'may', 'Malay', 'Bahasa Melayu', false, false, undefined, 'ms-MY', 'Latn', 'ltr'),
    'km': new Language('km', 'khm', 'Khmer', 'ភាសាខ្មែរ', false, false, undefined, 'km-KH', 'Khmr', 'ltr'),
    'el': new Language('el', 'gre', 'Greek', 'Ελληνικά', false, false, undefined, 'el-GR', 'Grek', 'ltr')

};



export const supportedPlayerBrowsers: SupportedBrowser[] = [
    SupportedBrowser.CHROME,
    SupportedBrowser.FIREFOX,
    SupportedBrowser.OPERA,
    SupportedBrowser.EDGE
];

export const imageFilterMap = {
    'svg::duotone': { name: 'Duotone' },
    '_1977': { name: '1977' },
    'aden': { name: 'Aden' },
    'amaro': { name: 'Amaro' },
    'brannan': { name: 'Brannan' },
    'brooklyn': { name: 'Brooklyn' },
    'clarendon': { name: 'Clarendon' },
    'gingham': { name: 'Gingham' },
    'hudson': { name: 'Hudson' },
    'inkwell': { name: 'Inkwel' },
    'kelvin': { name: 'Kelvin' },
    'lark': { name: 'Lark' },
    'lofi': { name: 'Lofi' },
    'mayfair': { name: 'Mayfair' },
    'moon': { name: 'Moon' },
    'nashville': { name: 'Nashville' },
    'perpetua': { name: 'Perpetua' },
    'reyes': { name: 'Reyes' },
    'rise': { name: 'Rise' },
    'slumber': { name: 'Slumber' },
    'stinson': { name: 'Stinson' },
    'toaster': { name: 'Toaster' },
    'valencia': { name: 'Valancia' },
    'walden': { name: 'Walden' },
    'willow': { name: 'Willow' },
    'xpro2': { name: 'X-Pro II' }
};

export interface SectionCategoryView {
    name: string;
    category: SectionCategory;
    helpText: string;
    icon: string;
    order: number;
}

export const sectionCategoryMap: { [key: string]: SectionCategoryView } = {
    'intro': {
        name: 'Intro',
        category: 'intro',
        helpText: '',
        icon: 'info',
        order: 0
    },
    'online-activity': {
        name: 'Learning Activity',
        category: 'online-activity',
        helpText: '',
        icon: 'slideshow',
        order: 1
    },
    'supportive-information': {
        name: 'Supportive Information',
        category: 'supportive-information',
        helpText: '',
        icon: 'school',
        order: 2
    }
};

export class InteractionEntry {
    enabled: boolean;
    needsASR?: boolean;
    type: string;
    name: string;
    subtype: string;
    icon: string;
    helpText: string;
}

export class InteractionCollection {
    name: string;
    entry: InteractionEntry[];
}

export const blocks: { content: InteractionEntry[], interaction: InteractionCollection[] } = {
    content: [
        {
            enabled: true,
            type: 'content',
            name: 'Text',
            subtype: 'text',
            icon: 'title',
            helpText: 'Description of text block.'
        },
        {
            enabled: true,
            type: 'content',
            name: 'Image',
            subtype: 'image',
            icon: 'image',
            helpText: 'Description of image block.'
        },
        {
            enabled: true,
            type: 'content',
            name: 'Video',
            subtype: 'video',
            icon: 'movie',
            helpText: 'Description of video block.'
        },
        {
            enabled: true,
            type: 'content',
            name: 'Audio',
            subtype: 'audio',
            icon: 'audiotrack',
            helpText: 'Description of audio block.'
        },
        {
            enabled: true,
            type: 'content',
            name: 'Image Avatar',
            subtype: 'image-avatar',
            icon: 'face',
            helpText: 'Description of avatar block.'
        },
        {
            enabled: true,
            type: 'content',
            name: 'Animated Avatar',
            subtype: 'pixi-avatar',
            icon: 'face',
            helpText: 'Description of animated avatar block.'
        },
        {
            enabled: true,
            type: 'content',
            name: 'Navigation',
            subtype: 'navigation',
            icon: 'navigation',
            helpText: 'Description of navigation block.'
        },
        {
            enabled: true,
            type: 'interaction',
            name: 'Hot spot navigation',
            subtype: 'hot-spot-navigation',
            icon: 'map',
            helpText: 'Description of hot spot navigation block.'
        }
    ],
    interaction: [{
        name: 'choice',
        entry: [
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Text Choice',
                subtype: 'choice-text',
                icon: 'title',
                helpText: 'Description of text choice block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Audio Choice',
                subtype: 'choice-audio',
                icon: 'audiotrack',
                helpText: 'Description of audio choice block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Graphic Choice',
                subtype: 'choice-graphic',
                icon: 'image',
                helpText: 'Description of graphic choice block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Inline Text Choice',
                subtype: 'inline-choice-text',
                icon: 'space_bar',
                helpText: 'Description of inline choice block.'
            }
        ]
    }, {
        name: 'order',
        entry: [
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Text Order',
                subtype: 'order-text',
                icon: 'title',
                helpText: 'Description of text order block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Graphic Order',
                subtype: 'order-graphic',
                icon: 'image',
                helpText: 'Description of graphic order block.'
            }
        ]
    }, {
        name: 'branching',
        entry: [
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Branching Text Choice',
                subtype: 'branching-choice-text',
                icon: 'title',
                helpText: 'Description of branching choice block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Branching Audio Choice',
                subtype: 'branching-choice-audio',
                icon: 'audiotrack',
                helpText: 'Description of audio branching choice block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Branching Graphic Choice',
                subtype: 'branching-choice-graphic',
                icon: 'image',
                helpText: 'Description of graphic branching choice block.'
            }
        ]
    }, {
        name: 'text entry',
        entry: [
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Text Entry Set',
                subtype: 'text-entry-set',
                icon: 'view_headline',
                helpText: 'Description of text entry set block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Inline Text Entry',
                subtype: 'inline-text-entry',
                icon: 'space_bar',
                helpText: 'Description of inline text entry block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Open text entry',
                subtype: 'open-text-entry',
                icon: 'short_text',
                helpText: 'Description of open text entry block.'
            }
        ]
    }, {
        name: 'match',
        entry: [
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Inline Text Match',
                subtype: 'inline-match-text',
                icon: 'space_bar',
                helpText: 'Description of inline match block.'
            },
            {
                enabled: true,
                needsASR: false,
                type: 'interaction',
                name: 'Hot-spot Text Match',
                subtype: 'hot-spot-match-text',
                icon: 'pin_drop',
                helpText: 'Description of text hot spot match block'
            }
        ]
    },
    {
        name: 'speech',
        entry: [
            {
                enabled: true,
                needsASR: true,
                type: 'interaction',
                name: 'Pronunciation',
                subtype: 'pronunciation',
                icon: 'mic',
                helpText: 'Description of pronunciation block.'
            },
            {
                enabled: true,
                needsASR: true,
                type: 'interaction',
                name: 'Conversation',
                subtype: 'conversation',
                icon: 'portrait',
                helpText: 'Description of conversation block.'
            }
        ]
    }
    ]
};

export const notificationChannelOptions = {
    email: { name: 'email', icon: 'email' },
    push: { name: 'push', icon: 'notifications' }
};
