<mat-toolbar>
    <button style="position: absolute; right: 8px; top: 8px;" mat-icon-button (click)="sidenav.toggle()">
        <fa-icon [icon]="['fal', 'bars']" style="font-size: 20px;  color: gray;"></fa-icon>
    </button>
    <a (click)="goHome()">
        <img alt="NovoLearning logo" class="logo" [ngClass]="{'small': isScrolledDown}" [src]="logoUrl$ | async" />
    </a>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #sidenav [fixedInViewport]="true">
        <div class="sidemenu">
            <app-user-avatar class="user-avatar"></app-user-avatar>
            <a class="menu-item" [href]="env.get('contactUrl')" novoTranslate="PAGE_LINKS.CONTACT"></a>
            <a class="menu-item" [href]="env.get('termsOfServiceUrl')" novoTranslate="PAGE_LINKS.TERMS"></a>
            <a class="menu-item" [href]="env.get('privacyPolicyUrl')" novoTranslate="PAGE_LINKS.PRIVACY"></a>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="wrapper">
            <div class="desktop-header">
                <a (click)="goHome()">
                    <img alt="NovoLearning logo" class="logo" [src]="logoUrl$ | async" />
                </a>
                <app-user-avatar class="user-avatar"></app-user-avatar>
            </div>
            <app-cookie-consent [dismiss]="'PARTIALS.OK' | novoTranslate"
                [readMore]="'PARTIALS.LEARN_MORE' | novoTranslate" [message]="'COMPONENTS.COOKIE_CONSENT.MESSAGE' |
                novoTranslate">
            </app-cookie-consent>
            <div class="content" [class.no-header]="!header">
                <ng-content></ng-content>
            </div>
            <app-footer></app-footer>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>