import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@novo/platform-common/services/auth';
import { Environment } from '@novo/website/services';
import { map, shareReplay, take } from 'rxjs/operators';

@Component({
    selector: 'app-wrapped-page',
    templateUrl: './wrapped-page.component.html',
    styleUrls: ['./wrapped-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrappedPageComponent implements OnChanges {

    @Input() header = true;
    @Input() hasHomeLink = true;

    @Input() title = 'Novo Learning';
    @Input() meta: { keywords: string, description: string } | undefined;

    isScrolledDown = false;

    readonly homePage$ = this.route.data.pipe(map(data => data.homePage));

    readonly logoUrl$ = this.homePage$.pipe(
        map(page => {
            switch (page) {
                default:
                    return 'assets/logos/novo-logo.png';
            }
        })
    );

    readonly homeLink$ = this.homePage$.pipe(
        map(page => {
            switch (page) {
                default:
                    return '/';
            }
        }),
        shareReplay(1)
    );

    constructor(
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public env: Environment,
        private titleService: Title,
        private metaTagService: Meta) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.title) {
            this.setTitle(changes.title.currentValue);
        }
        if (changes.meta) {
            this.setMeta(changes.meta.currentValue);
        }
    }

    goHome(): void {
        if (this.hasHomeLink) {
            this.homeLink$.pipe(take(1)).subscribe(url => this.router.navigate([url]));
        }
    }

    private setTitle(title?: string): void {
        this.titleService.setTitle(title || 'Novo Learning');
    }

    private setMeta(meta?: { keywords: string, description: string }): void {
        this.metaTagService.updateTag({ name: 'keywords', content: meta?.keywords || 'interactive learning, e-learning' });
        this.metaTagService.updateTag({ name: 'description', content: meta?.description || '' });
    }
}
