
import { Injectable } from '@angular/core';
import { Group, User } from '@novo/platform-common/models';
import { BehaviorSubject } from 'rxjs';
import { SessionTokenService } from '../session-token/session-token.service';
import { AuthenticationStorage } from './auth.storage.service';

@Injectable()
export class AuthenticationMemoryStorage implements AuthenticationStorage {

    redirectUrl: string;

    readonly user$ = new BehaviorSubject<User | undefined | null>(undefined);
    readonly sessionToken$ = new BehaviorSubject<string | undefined | null>(this.sessionTokenService.get());

    constructor(private sessionTokenService: SessionTokenService) {
    }

    userChange(user: User): void {
        if (user == null) {
            this.clear();
        } else {
            this.user$.next(user);
            this.sessionToken$.next(user && user.sessionToken);
            this.sessionTokenService.set(this.sessionToken);
        }
    }

    clear(): void {
        this.user$.next(null);
        this.sessionToken$.next(null);
        this.sessionTokenService.remove();
    }

    get user(): User | undefined | null {
        return this.user$.value;
    }

    get sessionToken(): string | undefined | null {
        return this.sessionToken$.value;
    }

    groupChange(groups: Group[]): void { }
    getAncestorsOf(groupId: string): Group[] { return []; }
}
