import { BaseLoginProvider } from '../entities/base-login-provider';
import { LoginProviderId } from '../entities/login-provider';
import { SocialUser } from '../entities/user';

declare var cordova: any;

export class AppleCordovaLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: LoginProviderId = 'apple';

    constructor() {
        super();
        if (typeof window === 'undefined' || window['cordova'] == null || cordova.plugins == null || cordova.plugins.SignInWithApple == null) {
            throw new Error('Cordova apple sign-in plugin not available');
        }
    }

    initialize(): Promise<void> {
        return new Promise((resolve, _) => {
            this._readyState.next(true);
            resolve();
        });
    }

    signIn(): Promise<SocialUser> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();
            cordova.plugins.SignInWithApple.signin({
                requestedScopes: [0, 1]
            }, (response: any) => {
                const user = this._getUserFromResponse(response);
                resolve(user);
            }, (err: any) => {
                reject(err);
            });
        });
    }

    getLoginStatus(): Promise<SocialUser | undefined> {
        // Not implemented
        return Promise.resolve(undefined);
    }

    signOut(): Promise<any> {
        // Not implemented
        return Promise.resolve();
    }

    _getUserFromResponse(response: any): SocialUser {
        const user: SocialUser = new SocialUser();
        user.id = response.user;
        user.email = response.email ? response.email : undefined;
        user.firstName = (response.fullName && response.fullName.givenName) ? response.fullName.givenName : undefined;
        user.lastName = (response.fullName && response.fullName.familyName) ? response.fullName.familyName : undefined;
        user.idToken = response.identityToken;
        user.authorizationCode = response.authorizationCode;
        return user;
    }

}
