import { createSelector, createSelectorFactory, defaultMemoize, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { AnyFn, MemoizeFn } from '@ngrx/store/src/selector';
import { clone, cloneDeep, memoize } from 'lodash-es';
import { Group, GroupTree } from '../models';
import { Permission, PermissionMap } from '../models/roles';
import * as AuthActions from './auth-actions';
import * as GroupActions from './group-actions';
import { AppState, Authentication, GroupState } from './state';


const initialState: GroupState = {
    selected: undefined,
    root: undefined,
    groups: {}
};

function updateCount(
    groups: { [key: string]: Group },
    groupId: string,
    diff: number,
    countField: string,
    limitField: string,
    includeSelf: boolean)
    : void {

    const parents: { [key: string]: Group } = {};
    Object.keys(groups).forEach(k => {
        const group = groups[k];
        group.subgroupIds.forEach(sgi => { parents[sgi] = group; });
    });

    let currentId: string | undefined = includeSelf ? groupId : (parents[groupId] && parents[groupId].identifier);
    while (currentId != null) {
        groups[currentId][countField] += diff;
        if (groups[currentId][limitField] == null && parents[currentId] != null) {
            currentId = parents[currentId].identifier;
        } else {
            currentId = undefined;
        }
    }
}

export function groupReducer(groupState: GroupState, action: GroupActions.GroupAction | AuthActions.AuthAction): GroupState {

    let result: GroupState = groupState || initialState;
    let groups;

    switch (action.type) {

        case GroupActions.SET_GROUPS:
            result = clone(groupState);
            result.groups = action.groups.reduce((map, group: Group) => {
                map[group.identifier] = group;
                return map;
            }, {});
            break;

        case GroupActions.SELECT_GROUP:
            if (groupState.selected !== action.groupId) {
                result = clone(groupState);
                result.selected = action.groupId;
            }
            break;

        case GroupActions.SET_ROOT_GROUP:
            if (groupState.root !== action.groupId) {
                result = clone(groupState);
                result.root = action.groupId;
            }
            break;

        case GroupActions.ADD_GROUP:
            // Wait for group to be saved; ADDED_GROUP action is then called
            break;

        case GroupActions.ADDED_GROUP:
            result = cloneDeep(groupState);
            result.groups[action.parent.identifier].subgroupIds.push(action.child.identifier);
            result.groups[action.child.identifier] = clone(action.child);
            break;

        case GroupActions.REMOVE_GROUP:
            result = clone(groupState);
            result.groups = clone(result.groups);
            const parentId = Object.keys(result.groups)
                .filter(g => result.groups[g].subgroupIds != null)
                .find(g => result.groups[g].subgroupIds.indexOf(action.identifier) >= 0);
            if (parentId) {
                result.groups[parentId] = clone(result.groups[parentId]);
                result.groups[parentId].subgroupIds = result.groups[parentId].subgroupIds.filter(sid => sid !== action.identifier);
            }
            delete result.groups[action.identifier];
            break;

        case GroupActions.ADJUST_ACTIVE_SEATS:
            result = cloneDeep(groupState);
            updateCount(result.groups, action.groupId, action.diff, 'activeSeats', 'seatLimit', true);
            break;

        case GroupActions.SET_LIMIT_SEATS:
            if (action.limit !== result.groups[action.group.identifier].seatLimit) {
                result = cloneDeep(groupState);
                let diff = 0;
                if (action.limit == null) {
                    diff = -(result.groups[action.group.identifier].seatLimit - result.groups[action.group.identifier].activeSeats);
                } else if (result.groups[action.group.identifier].seatLimit == null) {
                    diff = action.limit - result.groups[action.group.identifier].activeSeats;
                } else {
                    diff = action.limit - result.groups[action.group.identifier].seatLimit;
                }

                result.groups[action.group.identifier].seatLimit = action.limit;
                updateCount(result.groups, action.group.identifier, diff, 'activeSeats', 'seatLimit', false);
            }
            break;

        case GroupActions.ADJUST_WISTIA_UPLOADS:
            result = cloneDeep(groupState);
            updateCount(result.groups, action.groupId, action.diff, 'wistiaUploads', 'wistiaLimit', true);
            break;

        case GroupActions.SET_LIMIT_WISTIA:
            if (action.limit !== result.groups[action.group.identifier].wistiaLimit) {
                result = cloneDeep(groupState);
                let diff = 0;
                if (action.limit == null) {
                    diff = -(result.groups[action.group.identifier].wistiaLimit - result.groups[action.group.identifier].wistiaUploads);
                } else if (result.groups[action.group.identifier].wistiaLimit == null) {
                    diff = action.limit - result.groups[action.group.identifier].wistiaUploads;
                } else {
                    diff = action.limit - result.groups[action.group.identifier].wistiaLimit;
                }

                result.groups[action.group.identifier].wistiaLimit = action.limit;
                updateCount(result.groups, action.group.identifier, diff, 'wistiaUploads', 'wistiaLimit', false);
            }
            break;

        case GroupActions.SET_GROUP_COVER:
            result = cloneDeep(groupState);
            result.groups[action.group.identifier].background = action.background;
            break;

        case GroupActions.SET_PAYMENT_MODEL:
            groups = { ...groupState.groups };
            groups[action.group.identifier] = { ...groups[action.group.identifier], paymentModel: action.paymentModel };
            result = { ...groupState, groups };
            break;

        case GroupActions.SET_GROUP_NAME:
            result = cloneDeep(groupState);
            result.groups[action.group.identifier].name = action.name;
            break;

        case GroupActions.UPDATE_CUSTOM_USER_FIELDS:
            groups = { ...groupState.groups };
            groups[action.group.identifier] = {
                ...groups[action.group.identifier],
                customUserFields: action.fields
            };
            result = { ...groupState, groups };
            break;

        case GroupActions.SET_GROUP_LOGO:
            result = cloneDeep(groupState);
            result.groups[action.group.identifier].logo = action.logo;
            break;

        case GroupActions.SET_GROUP_FEATURES:
            result = cloneDeep(groupState);
            result.groups[action.group.identifier].features = action.features;
            break;

        case AuthActions.CLEAR_AUTHENTICATION:
            result = {
                root: undefined,
                selected: undefined,
                groups: {}
            };
            break;
    }
    return result;
}

export const selectGroups = (state: AppState) => state.group.groups;

export const selectRootGroup = (state: AppState) => state.group.root;
export const selectGroupState = (state: AppState) => state.group;
export const selectAuthState = (state: AppState) => state.auth;
export const selectGroupsWithPermission = createSelector(
    selectGroupState,
    selectAuthState,
    (groupState, authState) => getGroupsWithPermission(groupState, authState, () => true)
);

export const hasGroupPermission = (permissions: PermissionMap, group: Group, permission: Permission): boolean => {
    const groupPermissions = permissions.group && permissions.group[group.identifier];
    return groupPermissions != null && groupPermissions.indexOf(permission) > -1;
};

/**
 * Get the ancestors of a group
 * @param group   The group for which the ancestors will be determined
 * @param groups  An array of all groups
 * @return an array of ancestor groups ordered from root to parent
 */
export const getParentGroups = (group: Group, groups: Group[]): Group[] => {
    const parentMap: { [key: string]: Group } = {};
    groups.forEach(g => {
        g.subgroupIds.forEach(subId => {
            parentMap[subId] = g;
        });
    });
    const parents: Group[] = [];
    let parent: Group = group;
    while (parent != null) {
        parent = parentMap[parent.identifier];
        if (parent) {
            parents.unshift(parent);
        }
    }
    return parents;
};

/**
 * List all groups on which the user has any permissions
 *
 * @type {MemoizedSelector<AppState, Group[]>}
 */
export const listGroupsWithPermissions: MemoizedSelector<AppState, Group[] | undefined> = createSelector(selectGroups, selectAuthState, (groupMap: { [key: string]: Group }, authState: Authentication): Group[] | undefined => {
    if (authState == null || authState.user == null || groupMap == null) { return; }

    const userGroupPermissions = authState.permissions.group;
    if (userGroupPermissions != null) {
        const roots: Group[] = Object.keys(groupMap).map((id: string) => groupMap[id]);
        const work = roots.filter((g: Group) => userGroupPermissions[g.identifier] != null);
        const groups = {};
        while (work.length > 0) {
            const group = work.pop();
            if (group != null && groups[group.identifier] == null) {
                groups[group.identifier] = group;
                group.subgroupIds.forEach(id => work.push(groupMap[id]));
            }
        }
        return Object.keys(groups).map(k => groups[k]).sort(Group.sort);
    }
});

export const getCurrentRootGroup = createSelector(selectGroupState, (groupState: GroupState): Group => {
    if (groupState.root == null || groupState.groups == null || !(groupState.root in groupState.groups)) { return; }
    return groupState.groups[groupState.root];
});

export const getCurrentUsersGroup = createSelector(
    selectGroups,
    selectAuthState,
    (groups: { [key: string]: Group }, auth: Authentication): Group | undefined => {
        if (groups == null || auth == null || auth.user == null || !auth.user.organisation || !(auth.user.organisation in groups)) { return; }
        return groups[auth.user.organisation];
    });

export const getCurrentSelectedGroup = createSelector(selectGroupState, (groupState: GroupState): GroupTree | undefined => {
    if (groupState.selected == null || groupState.groups == null || !(groupState.selected in groupState.groups)) { return; }

    const selectedGroup = groupState.groups[groupState.selected];
    if (!selectedGroup) { return; }

    const treeRoot: GroupTree = new GroupTree(selectedGroup);
    treeRoot.subgroups = selectedGroup.subgroupIds.map((id: string) => {
        const group = groupState.groups[id];
        if (group) { return new GroupTree(group); }
    }).filter((x): x is Group => x != null).sort(Group.sort);

    return treeRoot;
});

/**
 * Determine the path from the root to the selected group.
 * @return an array of ancestor groups ordered from root to parent
 */
export const getPathToRoot: ((targetId: string) => MemoizedSelector<AppState, Group[]>) = memoize((targetId: string) => {
    return createSelector(
        selectGroupState,
        (groupState: GroupState): Group[] => {
            if (targetId == null || groupState.groups == null || !(targetId in groupState.groups)) { return []; }
            return getParentGroups(groupState.groups[targetId], Object.keys(groupState.groups).map(k => groupState.groups[k]));
        });
});

function createGroupTree(groups: { [key: string]: Group }): GroupTree[] {
    if (groups == null) { return; }

    const work: GroupTree[] = Object.keys(groups).map((id: string) => new GroupTree(groups[id]));

    const isDone = (tree: GroupTree[]): boolean => {
        const done: boolean = tree.every((t: GroupTree) => {
            return t.subgroups != null;
        });
        return done;
    };

    // Recursively add subgroups
    while (!isDone(work)) {
        const group: GroupTree = work.shift();
        const parents: GroupTree[] = [group];
        while (parents.length > 0) {
            const parent: GroupTree = parents.shift();
            parent.subgroups = parent.subgroupIds
                .map((id: string) => {
                    if (groups[id]) {
                        const idx: number = work.findIndex(g => g.identifier === id);
                        if (idx > -1) {
                            work.splice(idx, 1);
                        }
                        return new GroupTree(groups[id]);
                    }
                })
                .filter(x => x != null)
                .sort(Group.sort);
            parents.push(...parent.subgroups);
        }
        work.push(group);
    }
    return work;
}

/**
 * Builds a full group hierarchy from all groups.
 * Subgroup references that are not available are skipped
 *
 * @type {MemoizedSelector<AppState, GroupTree[]>}
 */
export const getGroupTree: MemoizedSelector<AppState, GroupTree[]> = createSelector(selectGroups, createGroupTree);

/**
 * Builds a full group hierarchy from all groups with permissions.
 * Subgroup references that are not available are skipped
 *
 * @type {MemoizedSelector<AppState, GroupTree[]>}
 */
export const getPermissionGroupTree: MemoizedSelector<AppState, GroupTree[]> = createSelector(selectGroupsWithPermission, createGroupTree);

/**
 * Builds a full group hierarchy from all groups with permissions.
 * Subgroup references that are not available are skipped
 *
 * @type {MemoizedSelector<AppState, GroupTree[]>}
 */
export const getSpecificPermissionGroupTree: (Permission) => MemoizedSelector<AppState, GroupTree[]> = (permission: Permission) => {
    return createSelector(
        createSelector(
            selectGroupState,
            selectAuthState,
            (groupState, authState) => getGroupsWithPermission(groupState, authState, (m, g) => hasGroupPermission(m, g, permission))
        ),
        createGroupTree
    );
};

function getGroupsWithPermission(group: GroupState, auth: Authentication, filter: (PermissionMap, Group) => boolean): { [key: string]: Group } {
    if (auth.permissions != null && auth.permissions['group'] != null && group.groups != null) {
        const roots: Group[] = Object.keys(group.groups).map((id: string) => group.groups[id]);
        const work = roots.filter((g: Group) => auth.permissions['group'][g.identifier] != null && filter(auth.permissions, g));
        const groups = {};
        while (work.length > 0) {
            const subgroup = work.pop();
            if (subgroup != null && groups[subgroup.identifier] == null) {
                groups[subgroup.identifier] = subgroup;
                subgroup.subgroupIds.forEach(id => work.push(group.groups[id]));
            }
        }
        return groups;
    }
    return {};
}

/**
 * This custom memoizer function only regards the group state as changed
 * when either the root group changed or the subgroups of the selected group changed, as
 * the selected group is the only group for which subgroups can be added or removed
 *
 * This ensures that the getGroupTreeFromRoot selector is not triggered excessively
 */
const GroupTreeFromRootMemoizer: MemoizeFn = (projectionFn: AnyFn) => {
    return defaultMemoize(projectionFn, (a: GroupState, b: GroupState) => {
        if (a.root !== b.root) {
            return false;
        }
        if (a.selected !== b.selected) {
            return false;
        }
        const selectedA = a.selected && a.groups[a.selected] && a.groups[a.selected].subgroupIds;
        const selectedB = b.selected && b.groups[b.selected] && b.groups[b.selected].subgroupIds;
        return selectedA === selectedB;
    });
};

/**
 * Builds the group hierarchy from the current root group
 *
 * @type {MemoizedSelector<AppState, GroupTree[]>}
 */
export const getGroupTreeFromRoot
    = createSelectorFactory(GroupTreeFromRootMemoizer)(selectGroupState, (groupState: GroupState): GroupTree[] => {
        const root = groupState.root;
        const groups = groupState.groups;
        if (root == null || groups == null || !(root in groups)) { return; }

        const treeRoot: GroupTree = new GroupTree(groups[root]!);
        const work: GroupTree[] = [treeRoot];

        // Recursively add subgroups from root
        while (work.length > 0) {
            const group: GroupTree = work.pop()!;
            group.subgroups = group.subgroupIds
                .map((id: string) => {
                    const subgroup = groups[id];
                    if (subgroup) {
                        return new GroupTree(subgroup);
                    }
                })
                .filter((x): x is GroupTree => x != null)
                .sort(Group.sort);
            work.push(...group.subgroups);
        }

        return [treeRoot];
    });

/**
 * Determine the default group which the user gets forwarded to after logging in into the Novo Studio
 *
 * @type {MemoizedSelector<AppState, Group>}
 */
export const getDefaultStudioGroup: MemoizedSelector<AppState, Group>
    = createSelector(selectGroupState, selectAuthState, (gs: GroupState, authState: Authentication): Group => {
        if (authState == null || authState.user == null || gs == null || gs.groups == null) { return; }

        const userGroupPermissions = authState.permissions.group;
        if (userGroupPermissions) {

            if (userGroupPermissions[authState.user.organisation]) {
                return gs.groups[authState.user.organisation];
            } else {
                const groups: Group[] = Object.keys(gs.groups).map((id: string) => gs.groups[id] as Group);
                const group = groups.find((g: Group) => userGroupPermissions[g.identifier] != null);
                if (!group) {
                    throw new Error('No default Studio group');
                }
                return group;
            }
        }
    });


/**
 * Selector which selects a group from the list of groups
 */
export const selectGroup: MemoizedSelectorWithProps<AppState, { id: string }, Group> = createSelector(
    selectGroups,
    (groups: { [id: string]: Group }, props: { id: string }
    ) => groups[props.id]);
