import * as semver from 'semver';
import { InternationalText } from './international-text';

export function initConstructor<T extends Object>(obj: T, data: T) {
    Object.keys(data).forEach(key => {
        // Only properties of data which are defined (so we do not override initial values)
        if (data.hasOwnProperty(key) && (data as any)[key] != null) {
            (obj as any)[key] = (data as any)[key];
        }
    });
}



/**
 * Convert a class model to a "json serialized" type. This removes unserializable
 * properties like methods and getters/setters
 */
export type Json<T> = { [P in keyof T]?:
    T[P] extends Function ? never : // No functions
    (T[P] extends Date ? Date | string :   // Date is sent as ISO string
        (T[P] extends string[] ? string[] : // Jsonify members of an array
            (T[P] extends P[] ? Array<Json<P>> : // Jsonify members of an array
                (T[P] extends Array<P> ? Array<Json<P>> : // Jsonify members of an array
                    (T[P] extends Object ? Json<T[P]> : // Recurse into member objects
                        T[P]))))) };

export type Diff<T extends string | number | symbol, U extends string | number | symbol> = ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];
export type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

// Create a API Model without identifier
export type New<T extends { identifier: string }> = Omit<T, 'identifier'>;

/**
 * Sets the given key of T as mandatory. Allow to go as deep as two levels by
 * providing 'V'.
 */
export type Mandatory<T, K extends keyof T = keyof T, V extends keyof NonNullable<T[K]> = never> =
    Omit<T, K> & { [key in K]-?: V extends never ? T[K] : Omit<NonNullable<T[K]>, V> & { [key in V]-?: NonNullable<T[K]>[V] } };

/**
 * Initialize the given model by going over all keys in json and applying
 * them on the model instance.
 */
function init<T extends { [key: string]: any }>(result: T, json: Json<T>): T {
    if (json == null) {
        return result;
    }
    for (const key in json) {
        if (result.hasOwnProperty(key)) {
            // Check if result is initialized with a model instance with a 'initialize' method
            // If so we use the model to initialize a new instance using the provided data.
            if (result[key] != null && typeof result[key] === 'object' && result[key].constructor && result[key].constructor.initialize) {
                result[key] = (result[key] as any).constructor.initialize(json[key]);
            } else {
                result[key] = json[key] as any;
            }
        }
    }
    return result;
}

/**
 * @deprecated Use <Type>Model approach (i.e as done in Group)
 * @param type
 * @param json
 */
export function initialize<T>(type: new () => T, json: Json<T>): T {
    const result = new type();
    return init(result, json);
}

/**
 * @deprecated
 * @param type
 * @param json
 */
export function initializeWithId<T extends { identifier?: string; }>(type: new (id: string | undefined) => T, json: Json<T> & { identifier?: string; }): T {
    if (json == null) { return new type(undefined); }
    const result = new type(json.identifier);
    return init(result, json);
}

export function serializeType<T>(object: T) {
    return function () { return object; };
}

function removePunctuation(str: string) {
    const punctRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
    return str.replace(punctRE, '');
}

export function str2num(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        hash += charCode;
    }
    return hash;
}

function removeExtraneousWhitespace(str: string) {
    return str.replace(/[\s\x00-\x1F\x7F]+/g, ' ').trim();
}

function normalizeQuotes(str: string): string {
    return str.replace(/[\u2018\u2019]/g, '\'').replace(/[\u201C\u201D]/g, '"');
}

function removeWhitespaceBeforePunctuation(str: string): string {
    return str.replace(/[\s\x00-\x1F\x7F]([\.,!\?"':;])/g, '$1');
}

export interface StringEqualityOptions {
    ignoreCase: boolean;
    ignorePunctuation: boolean;
    ignoreExtraneousWhitespace: boolean;
    ignoreWhitespaceBeforePunctuation: boolean;
}

export function isEqual(s1: string | undefined | null, s2: string | undefined | null, opts: StringEqualityOptions) {
    if (s1 == null && s2 == null) { return s1 === s2; }
    if (s1 == null) { return false; }
    if (s2 == null) { return false; }

    [s1, s2] = [s1, s2].map(s => normalizeQuotes(s));

    if (opts.ignoreCase) {
        [s1, s2] = [s1, s2].map(s => s.toUpperCase());
    }
    if (opts.ignorePunctuation) {
        [s1, s2] = [s1, s2].map(s => removePunctuation(s));
    }

    if (opts.ignoreExtraneousWhitespace) {
        [s1, s2] = [s1, s2].map(s => removeExtraneousWhitespace(s));
    }

    if (opts.ignoreWhitespaceBeforePunctuation) {
        [s1, s2] = [s1, s2].map(s => removeWhitespaceBeforePunctuation(s));
    }

    return s1 === s2;
}

export function getRandomId(): string {
    return Math.random().toString(36).substr(2, 8);
}

export function getTempId(id?: string): string {
    if (id == null) { id = getRandomId(); }
    return `tmp@${id}`;
}

export function isTempId(id: string): boolean {
    return id != null && id.startsWith('tmp@');
}

function parseSemVerUrl(s: string): { prefix: string, version: string } {
    const parts = s.split('/');
    const prefix = parts.slice(0, parts.length - 1).join('/');
    const version = parts[parts.length - 1];
    return { prefix, version };
}

export function semVerUrlEquals(urlA: string, urlB: string, matchLevel: 'major' | 'minor' | 'patch'): boolean {
    try {
        const { prefix: prefixA, version: versionA } = parseSemVerUrl(urlA);
        const { prefix: prefixB, version: versionB } = parseSemVerUrl(urlB);

        if (prefixA !== prefixB) {
            // Prefix don't match, so we don't check the version number
            return false;
        }

        if (!semver.valid(versionA) || !semver.valid(versionB)) {
            return versionA === versionB;
        }

        // Check version number
        switch (matchLevel) {
            case 'patch':
                return versionA === versionB;
            case 'minor':
                return semver.major(versionA) === semver.major(versionB) &&
                    semver.minor(versionA) === semver.minor(versionB);
            case 'major':
            default:
                return semver.major(versionA) === semver.major(versionB);
        }
    } catch (err) {
        return false;
    }
}

export class LocaleCompare {
    private static collator = new Intl.Collator(undefined, { numeric: true });

    static compare(a: string | undefined, b: string | undefined): number {
        if (a == null) { return 1; }
        if (b == null) { return -1; }
        return LocaleCompare.collator.compare(a, b);
    }
}

export function launchExternalLink(url: string): void {
    // Prepend http if no protocol (either http or custom) is defined
    if (!url.match(/^.*:\/\//i)) {
        url = `http://${url}`;
    }
    // Open link in device browser (on mobile) or open new tab
    if ((window as any)['cordova'] != null) {
        (window as any)['cordova'].InAppBrowser.open(url, '_system');
    } else {
        window.open(url, '_blank', 'noopener');
    }
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

/**
 * Note 25-09-2019:
 * This function determines the activity image to use based on the title
 * This will probably be removed in the year future when activity images
 * can be selected in the Studio
 *
 * @param title
 */
export function getActivityImage(title: InternationalText): string {
    let normalized = title.defaultText.toLowerCase(); // Lowercase
    normalized = normalized.replace('-', ' '); // Remove dashes
    normalized = normalized.replace(/\s+/g, ' '); // Normalize white space

    let img = 'default.svg';

    const title2img: { [key: string]: string } = {
        'learn new words b': 'learn-new-words-2.svg',
        'learn new words': 'learn-new-words-1.svg',
        'english in action': 'english-in-action.svg',
        'english in use': 'english-in-use.svg',
        'english in use b': 'english-in-use-2.svg',
        'listening': 'listening.svg',
        'role play': 'role-play.svg',
        'say it clearly': 'say-it-clearly.svg',
        'vocabulary bank': 'vocabulary-bank.svg',
        'assessment': 'assessment.svg',
        'progress test': 'progress-test.svg',
    };

    for (const prefix of Object.keys(title2img)) {
        if (normalized.includes(prefix)) {
            img = title2img[prefix];
            break;
        }
    }

    return `assets/img/activity-icons/${img}`;
}

export function arraysEqual<T>(a: T[], b: T[]) {
    if (a === b) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}

/**
 * Regex to test URLs
 *
 * https://gist.github.com/dperini/729294
 */
export const webUrlRegex = new RegExp(
    '^' +
    // protocol identifier (optional)
    // short syntax // still required
    '(?:(?:(?:https?|ftp):)?\\/\\/)' +
    // user:pass BasicAuth (optional)
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broadcast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    '(?:' +
    '(?:' +
    '[a-z0-9\\u00a1-\\uffff]' +
    '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
    ')?' +
    '[a-z0-9\\u00a1-\\uffff]\\.' +
    ')+' +
    // TLD identifier name, may end with dot
    '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
    ')' +
    // port number (optional)
    '(?::\\d{2,5})?' +
    // resource path (optional)
    '(?:[/?#]\\S*)?' +
    '$', 'i'
);

/**
 * Return unique elements from array
 */
export function uniq<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}

