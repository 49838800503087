/**
 * Used to check if the current user-agent matches
 * the requested iOS version. If no version is provided
 * it will check if the current device us running iOS.
 *
 * By setting @param {isMinimum} to true you'll also allow
 * the device to have a greather version than the one provided.
 */
export const deviceIsiOS = (version?: number, isMinimum = false): boolean => {
    const agent = navigator.userAgent;
    const match = agent.match(/CPU\s(?:\w+\s)?OS\s(\d+).+Mac OS X/i);
    const deviceVersion: number | null = match ? parseInt(match[1], 10) : null;
    return (
        // No match returns false
        match != null &&
        // No version return false
        deviceVersion != null &&
        // Version and match
        (version == null ||
            // Device equals version return true
            (deviceVersion === version) ||
            // Device is greather than version and minimum is true
            (isMinimum === true && deviceVersion >= version)
        )
    );
};

/**
 * On iPad iOS 13 (and later?) the user agent /platform is the same as for Safari on an MacBook
 * The correct iOS version is therefore not available in the user agent string. The platform
 * of a MacBook / iPad is 'MacIntel'.
 * A distinction between iPad and MacBook can be made by looking at touch screen support.
 * A MacBook does not support touch screens where iPad does. When navigator.maxTouchPoints is at least 1 it is an iPad
 * https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
 */
export const isIpadAtLeastIOS13 = (): boolean => {
    return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); /* iPad OS 13 */
};
