<div class="header">
    <mat-toolbar class="header--toolbar">
        <button mat-button [matMenuTriggerFor]="menu">Language: {{ foreignLanguage$ | async }}</button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let lang of languages$ | async" (click)="selectLanguage(lang)">
                {{lang}}
            </button>
        </mat-menu>
        <mat-slide-toggle [formControl]="showIndicatorsControl">Show indicators</mat-slide-toggle>
        <span class="bar-spacer"></span>
        <div class="filters" [ngClass]="{'filters--active': (selectedFilter$ | async) !== null}">
            <div class="filter" (click)="selectFilter(null)"
                [ngClass]="{'filter--selected': (selectedFilter$ | async) == null}">
                All translations ({{ translationsCount$ | async }})</div>
            <div class="filter filter--warn" (click)="selectFilter('missing')"
                [ngClass]="{'filter--selected': (selectedFilter$ | async) == 'missing'}">
                Missing ({{ missingTranslationsCount$ | async }})
            </div>
            <div class="filter filter--primary" (click)="selectFilter('translated')"
                [ngClass]="{'filter--selected': (selectedFilter$ | async) == 'translated'}">
                Translated ({{ translatedTranslationsCount$ | async }})
            </div>
        </div>
        <span class="bar-spacer"></span>
        <button mat-icon-button aria-label="Minimize" (click)="minimizeToggle()"
            *ngIf="!(minimized$ | async); else maximized">
            <mat-icon>minimize</mat-icon>
        </button>
        <ng-template #maximized>
            <button mat-icon-button aria-label="Maximize" (click)="minimizeToggle()">
                <mat-icon>maximize</mat-icon>
            </button>
        </ng-template>
        <button mat-icon-button aria-label="Close" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>
</div>
<div class="editor" *ngIf="!(minimized$ | async)">
    <div class="editor-sidemenu">
        <mat-form-field class="editor-searchbox" floatLabel="never">
            <mat-label>Search translations</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input [formControl]="searchTerm" matInput>
        </mat-form-field>
        <mat-action-list dense class="editor-sidemenu-list">
            <mat-list-item class="list-item" [ngClass]="{'list-item--warn': translation.value === null}"
                *ngFor="let translation of filteredTranslations$ | async" (click)="selectTranslation(translation.key)">
                <h3 matLine>{{translation.key}}</h3>
                <p matLine>{{translation.value}}</p>
            </mat-list-item>
        </mat-action-list>
    </div>
    <div class="editor-forms" [formGroup]="form">
        <div class="editor-forms-row">
            <div class="editor-form-item editor-form-item--left">
                <div class="editor-toolbar">
                    <div class="lang-badge button" role="button">
                        {{ localLanguage$ | async }}
                    </div>
                </div>
                <div class="editor-input editor-input--left">
                    <textarea class="input-field input-field--undercover input-field--full-size input-field--disabled"
                        readonly formControlName="local">
                    </textarea>
                </div>
            </div>
            <div class="editor-form-item editor-form-item--right">
                <div class="editor-toolbar">
                    <div class="lang-badge button" role="button">
                        {{ foreignLanguage$ | async }}
                    </div>
                </div>
                <div class="editor-input editor-input--right">
                    <textarea class="input-field input-field--undercover input-field--full-size"
                        formControlName="foreign"></textarea>

                    <!--<div class="input-controls">
                        <button mat-flat-button color="primary" class="control-btn" (click)="save()">Save</button>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
