import { createSelector } from '@ngrx/store';
import { Settings } from '../models';
import { settingsState } from './settings-selectors';
import { AppState, AppStatus } from './state';

/**
 * Returns the app status state
 */
export const statusState = (state: AppState): AppStatus => state.status;

/**
 * Returns the current media playing status
 */
export const selectMediaPlayerId = createSelector(
    statusState,
    (status) => status.mediaPlayerId
);

/**
 * Returns the current api version
 */
export const selectApiVersion = createSelector(
    statusState,
    (status) => status.apiVersion
);

/**
 * Current recording state
 */
export const selectRecordingState = createSelector(
    statusState,
    status => status.recording
);

/**
 * Currently playing media items
 */
export const isMediaPlaying = createSelector(
    selectMediaPlayerId,
    (id): boolean => id !== undefined
);

/**
 * Alias for current recording state
 */
export const isAudioRecording = selectRecordingState;

/**
 * True when the application is considered to be online
 */
export const isOnline = createSelector(
    statusState,
    status => (status.online === true)
);

/**
 * True when application is online and the ASR websocket is connected
 */
export const isASROnline = createSelector(
    statusState,
    status => (status.online && status.asrConnected)
);

/**
 * Returns if the current connection allows to download items
 */
export const connectionAllowsDownloading = createSelector(
    settingsState,
    statusState,
    (settings: Settings, status: AppStatus) => (
        (settings.downloadWifiOnly && status.connection === 'WIFI')
        || (settings.downloadWifiOnly === false)
    )
);

/**
 * Returns if the activity / slide is locked for editing (while update to activity / slides is active)
 */
export const activityEditLocked = createSelector(statusState, status => typeof status.activityEditLock === 'string');
export const activityEditLockReason = createSelector(statusState, status => {
    if (typeof status.activityEditLock === 'string') {
        return status.activityEditLock;
    }
});
