import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateModuleConfig } from '@ngx-translate/core';
import { NovoEditableButtonComponent } from './editable-button/editable-button.component';
import { TranslationEditorInjector } from './editor/translation-editor.injector';
import { TranslationEditorModule } from './editor/translation-editor.module';
import { NovoMissingTranslationHandler } from './novo-missing-translation.handler.class';
import { NovoTranslationApi } from './novo-translation-api.class';
import { NovoTranslateDirective } from './novo-translation.directive';
import { NovoTranslationLoaderFactory } from './novo-translation.fns';
import { NovoTranslationPipe } from './novo-translation.pipe';
import { NovoTranslationService } from './novo-translation.service';
import { TRANSLATION_PLATFORM } from './novo-translation.token';

export interface NovoTranslationModuleOptions {
    api: Provider;
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        TranslationEditorModule
    ],
    declarations: [
        NovoTranslateDirective,
        NovoTranslationPipe,
        NovoEditableButtonComponent,
    ],
    exports: [
        NovoTranslateDirective,
        NovoTranslationPipe
    ]
})
export class NovoTranslationModule {

    static forRoot(config: NovoTranslationModuleOptions): ModuleWithProviders<NovoTranslationModule> {
        return {
            ngModule: NovoTranslationModule,
            providers: [
                NovoTranslationService,
                TranslationEditorInjector,
                config.api
            ]
        };
    }

    /**
     * Settings used to setup the forRoot instance of ngx's TranslateModule.
     */
    static ngxSettings(): TranslateModuleConfig {
        return {
            loader: {
                provide: TranslateLoader,
                useFactory: NovoTranslationLoaderFactory,
                deps: [NovoTranslationApi, TRANSLATION_PLATFORM]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: NovoMissingTranslationHandler,
                deps: [NovoTranslationApi]
            },
            useDefaultLang: false
        };
    }
}
