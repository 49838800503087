
import { PhoneSet } from '@novo-learning/novo-sdk';
import { LanguageCode, LanguageCode3, Locale, ScriptCode, ScriptDirection } from './model-types';
import { initialize, Json } from './utilities';

export class Language {
    constructor(
        public code: LanguageCode,
        public code3: LanguageCode3,
        public name: string,
        public nativeName: string,
        public supportedUserLanguage: boolean,
        public asrEnabled: boolean,
        public phoneSet: PhoneSet | undefined,
        public defaultLocale: Locale,
        public scriptCode: ScriptCode,
        public scriptDirection: ScriptDirection
    ) { }
}

export class InternationalText {
    default: LanguageCode = 'en';
    translation: { [language: string]: string } = {};

    static initialize(json: Json<InternationalText>): InternationalText {
        return initialize(InternationalText, json);
    }

    public removeTranslationsBesides(languageCodes: string[]) {
        Object.keys(this.translation).forEach(language => {
            if (!languageCodes.includes(language)) {
                delete this.translation[language];
            }
        });
    }

    public removeEmptyTranslations() {
        Object.keys(this.translation).forEach((language) => {
            const text = this.translation[language];
            if (text.length === 0) {
                delete this.translation[language];
            }
        });
    }

    get defaultText(): string {
        if (this.default in this.translation) {
            return this.translation[this.default];
        } else {
            // Workaround for incorrect default language after copying and pasting slides between activities with a different primary language
            // Ensure a text is always shown, sort for consistency
            const langs = Object.keys(this.translation).sort();
            if (langs.length > 0) {
                return this.translation[langs[0]];
            }
        }
        return '';
    }

    hasNonEmptyTranslation(lang: LanguageCode): boolean {
        if (this.translation == null || this.translation[lang] == null) {
            return false;
        }
        const div: HTMLDivElement = document.createElement('div');
        div.innerHTML = this.translation[lang];
        const text: string = div.textContent || div.innerText;
        return text.trim().length > 0;
    }
}
