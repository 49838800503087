import { isPlatformBrowser } from '@angular/common';
import { InjectionToken, NgModule, PLATFORM_ID } from '@angular/core';

export const WINDOW: InjectionToken<Window | undefined> = new InjectionToken<Window | undefined>('[SsrUtilsModule] Window Token');

/**
 * Return window when available, otherwise return undefined.
 * @param platformId
 */
export const windowTokenFactoryFn = (platformId: Object) => {
    return (isPlatformBrowser(platformId)) ? window : undefined;
}

@NgModule({
    providers: [
        {
            provide: WINDOW,
            useFactory: windowTokenFactoryFn,
            deps: [PLATFORM_ID]
        }
    ]
})
export class SsrUtilsModule { }
