import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { languageMap } from '@novo/platform-common/data';
import { LocaleService } from '@novo/platform-common/services/locale';
import { WINDOW } from '@novo/platform-common/util/ssr';
import * as Bowser from 'bowser';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {


    languages$ = this.route.data.pipe(map(data => {
        if (!data.languages) {
            return this.localeService.languages;
        }
        return data.languages.map(lang => languageMap[lang]);
    }));
    showLanguageSelector$ = this.languages$.pipe(map(langs => langs != null && langs.length > 1));

    readonly currentYear = (new Date()).getFullYear();
    os: string;

    constructor(
        public localeService: LocaleService,
        @Optional() @Inject(WINDOW) private window: Window | undefined,
        @Optional() @Inject(REQUEST) private request: Request | undefined,
        private route: ActivatedRoute
    ) {
        const userAgent = this.window?.navigator.userAgent || this.request?.headers['user-agent'];
        this.os = Bowser.getParser(userAgent).getOSName().toLowerCase();
    }

    // Circumvent Angulars inability to correctly infer the event.target type in the template
    // when strictDomEventTypes is enabled
    setCountry(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            this.localeService.setCountry(event.target.value);
        }
    }

    setLanguage(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            this.localeService.setLanguage(event.target.value);
        }
    }
}
