import { Observable } from 'rxjs';
import { Translation } from './novo-translation.interface';

/**
 * The NovoTranslationApi can be used to implement your own version of the
 * NovoApi returning the requested enpoints.
 */
export abstract class NovoTranslationApi {
    abstract getTranslations(language: string, platform: string): Observable<{ [key: string]: string }>;

    abstract updateTranslation(language: string, key: string, value: string): Observable<any>;

    abstract markMissingTranslations(missingKeys: Translation[]): Observable<any>;

    abstract getMissingTranslations(language: string): Observable<string[]>;
}
