import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ErrorLoggerService } from '@novo/platform-common/services/error-logger';
import { EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiRequestError } from './parse-api-request-error';
import { CANCEL_REQUEST_OBSERVABLE } from './parse-api.fns';
import { ParseResponseBody } from './parse-api.interfaces';


export class ParseConfig {
    constructor(public appId: string, public serverUrl: string, public pollDelay: number) { }
}

interface BackgroundWorkerStatus {
    bgWorkerId?: string;
    progress: number;
}

interface BackgroundWorkerRequestData {
    bgWorkerId: string;
}

/**
 * Option object for runCloudFunction$
 */
interface RunCloudFunctionOptions {
    /**
     * Subject which will be used to emit progress values to.
     * Only used for background jobs.
     */
    progress$: Subject<number | undefined>;

    /**
     * By default requests will be cancelled when CANCEL_REQUEST_OBSERVABLE
     * emits a value. To prevent this from happening `nonCancelable` can
     * be set to `true`.
     */
    nonCancelable: boolean;
}

@Injectable()
export class ParseRequest {

    constructor(private config: ParseConfig,
        private http: HttpClient,
        private errorLogger: ErrorLoggerService,
        @Inject(CANCEL_REQUEST_OBSERVABLE) private cancelRequest$: Observable<boolean>) {
    }

    /**
     * Make the call to the server. DO NOT CALL from outside ParseRequest.
     * This function is not private in order to mock this function in tests.
     * @param name The name of the function
     * @param data The parameters
     */
    _makeParseRequest$<K extends {}>(name: string, data: {}): Observable<ParseResponseBody<K>> {
        return this.http.post<ParseResponseBody<K>>(`${this.config.serverUrl}/functions/${name}`, data);
    }

    /**
     * Returns the status of a background function
     */
    private getBackgroundJobStatus$<K extends {}>(data: {}): Observable<BackgroundWorkerStatus & K> {
        return this._makeParseRequest$<BackgroundWorkerStatus & K>('getBackgroundFunctionResult', data).pipe(
            map(res => res.result)
        );
    }

    /**
     * Starts polling the status of a background function and completes when the
     * background functions has completed.
     *
     * An optional 'progress$' subject can be provided as second argument. This
     * subject will be used to emit progress status to.
     *
     * @param data
     * @param progress$
     */
    private pollBackgroundJob$<K = {}>(data: BackgroundWorkerRequestData, progress$?: Subject<number | undefined>): Observable<K> {
        if (progress$ == null) { progress$ = new Subject(); }
        return progress$.pipe(
            startWith({}),  // Start polling
            delay(this.config.pollDelay),    // Wait to poll again
            switchMap(_ => this.getBackgroundJobStatus$<K>(data)), // Poll the server
            tap(res => {
                // If background function is still running, feed progress to start a new poll
                if (res != null && res.bgWorkerId != null) {
                    progress$!.next(res.progress);
                }
            }),
            first(res => res == null || res.bgWorkerId == null), // Complete at first result
            takeUntil(this.cancelRequest$) // Stop when user changes
        );
    }

    /**
     * Parse and normalize HTTP response errors into an ApiRequestError which is
     * logged and thrown.
     *
     * @param err
     * @param name
     * @param data
     */
    private handleError(err: HttpErrorResponse, name: string, data: {}): Observable<never> {
        let errMsg: { code: number, message: string };
        const errJson = err.error || err;
        if (err.status === 0) {
            // An error status zero is never returned by a server and therefore usually
            // indicates an unreachable server or offline device.
            errMsg = { code: 0, message: 'Server or network unreachable, no response was received.' };
        } else {
            // Format error message
            errMsg = { code: 999, message: 'Unknown error: ' + JSON.stringify(errJson || err) };
            if (errJson.message || errJson.error) {
                errMsg = { code: errJson.code, message: errJson.message || errJson.error };
            }
        }

        // 701: user inactive
        if (errJson.code === 701 && name === 'login') {
            // Throw exception early so we don't log it to Sentry
            return throwError(new ApiRequestError(errMsg.message, errMsg.code));
        } else if (errJson.code === 701 || errJson.code === 401) {
            // Return nothing, since we aren't really interested in invalid session or inactive
            // user errors.
            return EMPTY;
        }

        // Mask sensitive   data, log to Sentry and throw API request error
        this.maskFields(data, ['password']);
        this.errorLogger.captureMessage(JSON.stringify(errMsg.message), { extra: { name, data } });
        return throwError(new ApiRequestError(errMsg.message, errMsg.code));
    }

    /**
     * Recursively remove sensitive data from the given object.
     * Note: this modifies the provided object.
     *
     * @param object
     * @param maskedFieldNames
     */
    private maskFields(object: {}, maskedFieldNames: string[]): void {
        for (const field in object) {
            if (!Object.prototype.hasOwnProperty.call(object, field)) {
                continue;
            }
            const value = object[field];
            if (typeof value === 'object') {
                this.maskFields(value, maskedFieldNames);
            } else if (maskedFieldNames.includes(field)) {
                object[field] = '*******';
            }
        }
    }

    /**
     * Execute a function on the server and return the result.
     *
     *
     * @param fnName   The name of the function to call
     * @param data     (Optional) Parameters for the function
     * @param options$ (Optional) options object, see RunCloudFunctionOptions
     */
    runCloudFunction$<T extends {}, K extends {} | void>(fnName: string, data?: T, options: Partial<RunCloudFunctionOptions> = {}): Observable<K> {
        return this._makeParseRequest$<BackgroundWorkerStatus & K>(fnName, data || {}).pipe(
            switchMap(value => {
                if (value.result && value.result.bgWorkerId != null) {
                    // Server function is running in the background; Poll for the results
                    const bgData = { bgWorkerId: value.result.bgWorkerId };
                    return this.pollBackgroundJob$<K>(bgData, options.progress$);
                }
                return of(value.result);
            }),
            catchError(err => this.handleError(err, fnName, data || {})),
            takeUntil((options.nonCancelable === true) ? EMPTY : this.cancelRequest$)
        );
    }
}
