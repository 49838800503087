import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import Bowser from 'bowser';
import { CookieService } from 'ngx-cookie-service';
import { SessionTokenService, SESSION_TOKEN_KEY } from './session-token.service';

/**
 * Responsible for storing the session token in a cookie
 */
@Injectable()
export class CookieSessionTokenService implements SessionTokenService {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieService
    ) { }

    get(): string | undefined {
        // Retrieve token from cookie
        let sessionToken = this.cookieService.get(SESSION_TOKEN_KEY);

        // If a token is passed through query parameters, prefer that one
        const urlParams = new URLSearchParams(this.document.location.search);
        const encodedQueryToken = urlParams.get('sessionToken');
        if (encodedQueryToken && sessionToken !== encodedQueryToken) {
            const decodedQueryToken = decodeURIComponent(encodedQueryToken);
            this.set(decodedQueryToken);
            sessionToken = encodedQueryToken;
        }

        return sessionToken !== '' ? sessionToken : undefined;
    }

    set(sessionToken: string) {
        if (!sessionToken) {
            this.remove();
            return;
        }
        const isSecure = this.document.location != null && this.document.location.protocol === 'https:';
        const userAgent = this.document.defaultView?.navigator.userAgent || '';
        if (userAgent) {
            const sameSite = Bowser.getParser(userAgent).isBrowser('safari', true) ? undefined : 'Strict';
            this.cookieService.set(SESSION_TOKEN_KEY, sessionToken, 10 * 365, '/', this._getSessionCookieDomain(), isSecure, sameSite);
        }

    }

    remove() {
        this.cookieService.delete(SESSION_TOKEN_KEY, '/', this._getSessionCookieDomain());
    }

    _getSessionCookieDomain(): string {
        return this.document.location && this.document.location.hostname === 'localhost' ? '' : '.novo-learning.com';
    }

}
