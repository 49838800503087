import { BaseLoginProvider } from '../entities/base-login-provider';
import { LoginProviderId } from '../entities/login-provider';
import { SocialUser } from '../entities/user';
import { LoginOpt } from '../social-login.service';

declare let facebookConnectPlugin: any;

export class FacebookCordovaLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: LoginProviderId = 'facebook';

    constructor(private opt: LoginOpt = { scope: 'email,public_profile' }
    ) {
        super();

        if (facebookConnectPlugin == null) {
            throw new Error('Cordova facebook plugin not available');
        }
    }

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._readyState.next(true);
            resolve();
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();
            facebookConnectPlugin.getLoginStatus((response: any) => {
                if (response.status === 'connected') {
                    const authResponse = response.authResponse;
                    const scope = this.opt.scope; // as comma delimited string
                    const permissions = scope.split(',');
                    const graphApiCallback = (fbUser: any) => {
                        const user = this._getUserFromResponse(fbUser, authResponse.accessToken);
                        resolve(user);
                    };
                    facebookConnectPlugin.api('/me?fields=email,id,name', permissions, graphApiCallback);
                } else {
                    reject('No user is currently logged in.');
                }
            });
        });
    }

    signIn(): Promise<SocialUser> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();

            const scope = this.opt && this.opt.scope; // as comma delimited string
            const permissions = scope.split(',');

            const loginCallbackFn = (response: any) => {
                if (response.authResponse) {
                    const authResponse = response.authResponse;
                    const graphApiCallback = (fbUser: any) => {
                        const user = this._getUserFromResponse(fbUser, authResponse.accessToken);
                        resolve(user);
                    };
                    facebookConnectPlugin.api('/me?fields=email,id,name', permissions, graphApiCallback);
                } else {
                    reject(response);
                }
            };
            facebookConnectPlugin.login(permissions, loginCallbackFn, loginCallbackFn);
        });
    }

    signOut(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.onReady();
            facebookConnectPlugin.logout((response: any) => resolve());
        });
    }

    _getUserFromResponse(fbUser: any, accessToken: string): SocialUser {
        const user: SocialUser = new SocialUser();
        user.id = fbUser.id;
        user.name = fbUser.name;
        user.email = fbUser.email;
        user.photoUrl = 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal';
        user.firstName = fbUser.first_name;
        user.lastName = fbUser.last_name;
        user.accessToken = accessToken;
        return user;
    }

    isCriticalError(err: { errorCode: number }): boolean {
        return err.errorCode !== 4201; // user cancelled dialog
    }
}
