import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@novo/platform-common/models';
import { AuthService } from '@novo/platform-common/services/auth';
import { AuthenticationStorage } from '@novo/platform-common/services/auth/auth-storage/auth.storage.service';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {

    readonly user$: Observable<User> = this.authStorage.user$;

    constructor(private authService: AuthService, private authStorage: AuthenticationStorage) {
    }

    logout() {
        this.authService.logout().subscribe(() => {
            //  @todo redirect to somewhere or show toast?
        });
    }
}
