<app-wrapped-page>
    <app-hero bgImageUrl="assets/content-store/img/placement-test.jpg"></app-hero>

    <div style="display: flex; justify-content: center;">
        <div style="max-width: 500px; width: 100%; margin: 0 24px 42px 24px;">
            <div class="mat-headline" novoTranslate="PAGES.CONTACT.TITLE"></div>
            <form novalidate [formGroup]="form" (submit)="submitForm()"
                style="display: flex; flex-direction: column; width: 100%;">
                <mat-form-field appearance="outline">
                    <mat-label novoTranslate="FORMS.LABELS.NAME"></mat-label>
                    <input matInput placeholder="Name" formControlName="name" type="text">
                    <mat-error *ngIf="form.get('name').invalid" novoTranslate="FORMS.VALIDATIONS.REQUIRED"></mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label novoTranslate="FORMS.LABELS.EMAIL"></mat-label>
                    <input matInput placeholder="Email address" formControlName="email" type="text">
                    <mat-error *ngIf="form.get('email').invalid" novoTranslate="FORMS.VALIDATIONS.REQUIRED"></mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label novoTranslate="FORMS.LABELS.MESSAGE"></mat-label>
                    <textarea matInput placeholder="Message" formControlName="message" type="text"></textarea>
                    <mat-error *ngIf="form.get('message').invalid" novoTranslate="FORMS.VALIDATIONS.REQUIRED">
                    </mat-error>
                </mat-form-field>

                <button class="button--xl" mat-raised-button color="primary" type="submit" style="margin-top: 16px;"
                    [disabled]="sending">
                    <span novoTranslate="FORMS.LABELS.SEND_MESSAGE"></span>
                </button>
            </form>
        </div>
    </div>

    <section>

        <div class="header">
            <div class="mat-display-2" novoTranslate="PAGES.CONTACT.OUR_OFFICES.TITLE"></div>
        </div>

        <div class="grid-row learner-ubrs">
            <div class="ubr">
                <div class="ubr__image" style="background-image: url('assets/office-nijmegen.jpg')"></div>
                <div class="ubr__text">
                    <h3 novoTranslate="COUNTRIES.NETHERLANDS"></h3>
                    <div>Toernooiveld 300</div>
                    <div>6525EC Nijmegen</div>
                    <div>
                        <a href="tel:+31243652400">+31 24 365 2400</a>
                    </div>
                </div>
            </div>
        </div>

    </section>

</app-wrapped-page>