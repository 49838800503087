import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FileCacheService } from '@novo/platform-common/services/file-cache';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User } from '../../models';

@Component({
    selector: 'user-icon',
    template: `
        <div class="container" [ngStyle]="style" *ngIf="(user$ | async) as user">
            <div *ngIf="(backgroundImageStyle$ | async) as bgImage; else alternative"
                [style.backgroundImage]="bgImage">
            </div>
            <ng-template #alternative>
                <div *ngIf="mode == 'acronym'"
                        [style.background]="user?.color">
                    {{user?.acronym}}
                </div>
                <div *ngIf="mode == 'placeholder'"
                    [style.backgroundImage]="'url(assets/img/user-placeholder.png)'">
                </div>
            </ng-template>
        </div>
    `,
    styles: [`
        :host {
            display: block;
        }

        .container {
            border-radius: 100%;
            user-select: none;
            color: rgba(255, 255, 255, 0.95);
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.2);
            font-family: var(--header-font-family);
            font-weight: bold;
            text-shadow: none;
        }

        .container > div {
            height: 100%;
            width: 100%;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserIconComponent implements OnChanges, OnInit {
    @Input() user: User;
    @Input() mode: 'acronym' | 'placeholder' = 'acronym';
    @Input() size = 36;

    public style: { fontSize: string; width: string; height: string; };
    readonly user$: ReplaySubject<User> = new ReplaySubject<User>(1);
    readonly backgroundImageStyle$: Observable<SafeStyle | undefined> = this.user$.pipe(
        map(user => user.profilePicture),
        switchMap(pp => {
            if (pp == null) { return of(pp); }
            return this.fileCache ? this.fileCache.getFile(pp.getURL()) : of(pp.getURL());
        }),
        map(url => url && this.sanitizer.bypassSecurityTrustStyle(`url(${url})`))
    );

    constructor(
        private sanitizer: DomSanitizer,
        @Optional() private fileCache: FileCacheService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user && changes.user.currentValue) {
            this.user$.next(changes.user.currentValue);
        }
        if (changes.size && changes.size.currentValue) {
            this.setSize();
        }
    }

    ngOnInit() {
        this.setSize();
    }

    /**
     * Sets the component's size
     */
    private setSize() {
        const fontSize = .45 * this.size;
        this.style = {
            fontSize: `${fontSize}px`,
            width: `${this.size}px`,
            height: `${this.size}px`,
        };
    }

}
