
import { Injectable } from '@angular/core';
import { Group, User } from '@novo/platform-common/models';
import { Observable } from 'rxjs';

@Injectable()
export abstract class AuthenticationStorage {

    redirectUrl?: string;

    /**
     * The user that is logged in
     * - User not determined yet: undefined
     * - User not logged in (anymore): null
     */
    abstract readonly user$: Observable<User | undefined | null>;
    abstract readonly sessionToken$: Observable<string | undefined | null>;
    abstract userChange(user: User): void;
    abstract clear(): void;

    abstract get user(): User | undefined | null;

    abstract get sessionToken(): string | undefined | null;

    abstract groupChange(groups: Group[]): void;
    abstract getAncestorsOf(groupId: string): Group[];
}
