import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ParseConfig } from '../api/parse-request';
import { AuthenticationStorage } from './auth-storage/auth.storage.service';

export class ParseAuthenticationInterceptor implements HttpInterceptor {
    constructor(private config: ParseConfig, private authStorage: AuthenticationStorage) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let doRequest = request;
        if (request.url.indexOf('/parse/') >= 0) {
            const headers = {
                'X-Parse-Application-Id': this.config.appId
            };
            const sessionToken = this.authStorage.sessionToken;
            if (sessionToken) {
                headers['X-Parse-Session-Token'] = sessionToken;
            }
            doRequest = request.clone({ setHeaders: headers });
        }
        return next.handle(doRequest)
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse && request.url.indexOf('/parse/') > 0) {
                        const errJson = err.error;
                        // Invalid session; retry without session token
                        if (errJson.code === 209) {
                            // Clear session token
                            this.authStorage.clear();

                            doRequest = request.clone({
                                setHeaders: { 'X-Parse-Application-Id': this.config.appId }
                            });
                            return next.handle(doRequest);
                        }
                    }
                    return throwError(err);
                })
            );
    }
}

