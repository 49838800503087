import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PageNotFoundResponseResolver } from './pages/page-not-found/page-not-found-resolver.service';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PasswordResetComponent } from './pages/password-reset-page/password-reset-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { MarkdownDocumentComponent } from './shared/components/markdown-document/markdown-document.component';

const appRoutes: Routes = [
    {
        path: 'forgot-password',
        component: PasswordResetComponent
    },
    {
        path: 'language',
        component: LandingPageComponent,
        data: {
            homePage: 'language',
            languages: ['en', 'id']
        },
    },
    {
        path: 'signup',
        component: SignupPageComponent
    },
    {
        path: 'contact',
        component: ContactPageComponent
    },
    {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
    },
    {
        path: 'privacy/:version',
        component: MarkdownDocumentComponent,
        data: { document: 'privacy' }
    },
    {
        path: 'privacy',
        redirectTo: 'privacy/'
    },
    {
        path: 'terms/:version',
        component: MarkdownDocumentComponent,
        data: { document: 'terms' }
    },
    {
        path: 'terms',
        redirectTo: 'terms/'
    },
    {
        path: '',
        redirectTo: 'language',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        resolve: {
            _: PageNotFoundResponseResolver
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 24],
            initialNavigation: 'enabledNonBlocking'
        }),
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutesModule {

}
