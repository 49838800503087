import { Exclude, Type } from 'class-transformer';
import { Dictionary } from '.';
import { SocialLoginProvider } from '../services/auth/auth.interfaces';
import { Group } from './group';
import { MediaFile } from './media';
import { NotificationChannelType } from './message';
import { LanguageCode } from './model-types';
import { PermissionMap } from './roles';
import { initializeWithId, LocaleCompare, serializeType } from './utilities';

export interface Features {
    chats?: boolean;
    notifications?: boolean;
}

/**
 * A group has a set of groups.
 * Users are member of one group.
 * Groups are used to structure the hierarchical nature of organizations
 */

export interface OrganizationData {
    group: Group;
}

export class User {

    get color(): string {
        const colors = [
            '#508ff1', // blue
            '#636FD0', // purple
            '#98e06d', // green
            '#f1b049', // pink
            '#fc5275' // orange
        ];
        const hash = this.hashStr(this.identifier);
        return colors[hash % colors.length];
    }

    get acronym(): string {
        if (this.firstName) {
            return this.firstName.slice(0, 2);
        }
        if (this.username) {
            return this.username.slice(0, 2);
        }
        return this.identifier.slice(0, 2);
    }

    get fullName(): string {
        // TODO: Localize?
        return `${this.firstName || ''} ${this.lastName || ''}`;
    }

    get coverBackgroundURL(): string {
        if (this.coverImage) {
            return this.coverImage.getURL();
        } else {
            return 'assets/img/bokeh-bg.jpg';
        }
    }

    get coverBackgroundStyle(): { backgroundImage: string, backgroundSize: 'cover', backgroundPosition: 'center' } {
        const imgStyle = `url("${this.coverBackgroundURL}")`;
        if (this.coverImage) {
            return {
                backgroundImage: imgStyle,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            };
        } else {
            return {
                backgroundImage: imgStyle,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            };
        }
    }

    readonly identifier: string;
    username?: string = undefined;
    firstName?: string = undefined;
    lastName?: string = undefined;
    email?: string = undefined;
    language: LanguageCode = 'en';
    lastLoginAt?: Date = undefined;
    organisation?: string = undefined;
    active = true;
    password?: string = undefined; // Usually not used
    readonly approvedDocuments: string[] = [];
    flags: { [key: string]: boolean } = {};
    @Type(serializeType(MediaFile)) profilePicture?: MediaFile = undefined;
    @Type(serializeType(MediaFile)) coverImage?: MediaFile = undefined;
    wantsNotifications = true;
    preferredNotificationChannel: NotificationChannelType = undefined;
    features: Features = {};
    metaData: Dictionary<string> = {};
    authData: { [key in SocialLoginProvider]?: any } = {};

    @Exclude() permissions: PermissionMap = { group: {}, class: {}, course: {}, system: {}, activity: {} };
    @Exclude() sessionToken?: string = undefined;

    static initialize(json: any): User {
        const user = initializeWithId(User, json);
        if (json.lastLoginAt) {
            user.lastLoginAt = new Date(json.lastLoginAt);
        }
        if (json.profilePicture) {
            user.profilePicture = MediaFile.initialize(json.profilePicture);
        }
        if (json.coverImage) {
            user.coverImage = MediaFile.initialize(json.coverImage);
        }
        return user;
    }

    static sort(userA: User, userB: User): number {
        const a = userA.fullName;
        const b = userB.fullName;
        return LocaleCompare.compare(a, b);
    }

    constructor(id: string) {
        this.identifier = id;
    }

    private hashStr(str: string): number {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const charCode = str.charCodeAt(i);
            hash += charCode;
        }
        return hash;
    }

}

export interface UserMap {
    [userId: string]: User;
}
