import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMdService } from 'ngx-md';
import { defaultPageScrollConfig, PageScrollService } from 'ngx-page-scroll-core';
import { merge } from 'rxjs';

const defaultLang = 'en';

const documentInfo = {
    'privacy': {
        title: 'Privacy Policy',
        assetPrefix: 'privacy-policy',
        latestVersion: '1.0.0'
    },
    'terms': {
        title: 'Terms of Service',
        assetPrefix: 'terms-of-service',
        latestVersion: '1.0.0'
    }
};

export interface Section {
    title: string;
    label: string;
    subsections: {
        label: string;
        title: string;
    }[];
}

/**
 * @note [28-05-2019] this component is not working when AppComponent uses
 * the OnPush ChangeDetectionStrategy!
 */

@Component({
    selector: 'app-markdown-document-component',
    templateUrl: './markdown-document.component.html',
    styleUrls: ['./markdown-document.component.css']
})
export class MarkdownDocumentComponent implements AfterViewInit {
    public sections: Section[] = [];
    mdData: string | null;

    constructor(
        private elRef: ElementRef,
        private pageScrollService: PageScrollService,
        private route: ActivatedRoute,
        private http: HttpClient,
        private router: Router,
        private ref: ChangeDetectorRef,
        private _markdown: NgxMdService,
        @Inject(DOCUMENT) private document: any
    ) {
        defaultPageScrollConfig.duration = 300;
        defaultPageScrollConfig.scrollOffset = 32;

        this._markdown.setMarkedOptions({ sanitize: false });
        this._markdown.renderer.blockquote = (text: string) => {
            if (text.indexOf(':warn:') !== -1) {
                text = text.replace(':warn:', '');
                return `<blockquote class="warn">${text}</blockquote>`;
            }
            return `<blockquote>${text}</blockquote>`;
        };
    }

    generateTOC(): void {
        const headers: HTMLElement[] = Array.from(this.elRef.nativeElement.querySelectorAll('h1,h2'));
        const sections: Section[] = [];
        let currentSection: Section | null = null;
        headers.forEach((el: HTMLElement) => {
            if (el.tagName === 'H1') {
                if (currentSection != null) {
                    sections.push(currentSection);
                    currentSection = null;
                }
                currentSection = {
                    label: el.id,
                    title: el.innerText,
                    subsections: []
                };
            } else if (currentSection !== null && el.tagName === 'H2') {
                currentSection.subsections.push({
                    label: el.id,
                    title: el.innerText
                });
            }
        });
        if (currentSection != null) {
            sections.push(currentSection);
            currentSection = null;
        }
        this.sections = sections;
    }

    ngAfterViewInit(): void {
        merge(this.route.params, this.route.queryParams).subscribe(_ => {
            this.loadPage();
        });
    }

    async loadPage() {
        if (this.document) {
            this.document.body.scrollTop = 0; // For Safari
            this.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }

        const document: string = this.route.snapshot.data.document;
        const latestVersion: string = documentInfo[document].latestVersion;
        const assetPrefix: string = documentInfo[document].assetPrefix;
        const documentTitle: string = documentInfo[document].title;
        const version: string = this.route.snapshot.paramMap.get('version') || latestVersion;
        const lang: string = this.route.snapshot.queryParamMap.get('lang') || defaultLang;

        try {
            const response: HttpResponse<string> = await this.http.get(`/assets/documents/${assetPrefix}-${version}-${lang}.md`,
                { responseType: 'text', observe: 'response' }).toPromise();
            if (response.body && response.body.startsWith('<!doctype html>')) {
                throw new Error('No .md document');
            }
            this.mdData = response.body;
            setTimeout(() => {
                this.generateTOC();
            });
        } catch (err) {
            if (lang !== defaultLang) {
                this.router.navigate(['.'], {
                    relativeTo: this.route
                });
            } else if (version !== latestVersion) {
                this.router.navigate(['..'], { relativeTo: this.route });
            } else {
                // this should only happen if the default version/lang is not in the assets
                this.mdData = `This version of the ${documentTitle} cannot be found`;
            }
        }
    }

}
