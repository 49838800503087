import { Injectable } from '@angular/core';
import { ErrorLoggerService } from './error-logger.service';

@Injectable()
export class ConsoleErrorLoggerService extends ErrorLoggerService {

    captureException(error: string | Error | ErrorEvent, data: { extra?: {} } = {}): void {
        if (data.extra) {
            console.error(error, data);
        } else {
            console.error(error);
        }
    }

    captureMessage(message: string, data: { extra?: {} } = {}): void {
        this.captureException(message, data);
    }

    setUserContext(id: string): void { }

    clearUserContext() { }
}
